import React, {useEffect} from 'react'
import { Button } from 'semantic-ui-react';
import './cmp-success.css'

export default function CMP_SUCCESS({ set_mdl_open, var_modaltitle, var_message, success_function }) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        
	}, [])
    
    
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    
    

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="modal-content">
                <div className="modal-success-title">
                    <img src="/icon-success.svg" alt="" />
                    {var_modaltitle}
                </div>
                <div className="modal-success-message">{var_message}</div>
                <div className="modal-goback-button">
                    <Button color="red" onClick={()=> success_function()}>Go Back</Button>
                </div>
            </div>
        </>
    )
}

