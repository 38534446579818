import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Button, Label, Modal, Search } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import MDL_CONFIRMATION from "../../../components/cmp-confirmation/cmp-confirmation";
import MDL_BULK_BUILDING from "./mdl-upload-bulk-building/mdl-upload-bulk-building";
import MDL_BULK_BUILDING_RESULT from "./mdl-upload-bulk-building-result/mdl-upload-bulk-building-result";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import {
  getAllBuildings,
  deleteBuilding,
  getAllUsers,
  postBulkBuildings,
} from "../../../constants";
import AppPagination from "../../../components/cmp-pagination/Pagination";
import { getJsDateFromExcel } from "excel-date-to-js";
import * as XLSX from "xlsx";
import moment from "moment";

import "./BuildingManagment.css";
import { AppContext } from "../../../Context/AppContext";
import { CSVDownload, CSVLink } from "react-csv";


function BuildingManagment() {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { usersData, schedulersData, userInfo } = useContext(AppContext);
  const [buildingsAdmin, setBuildingsAdmin] = useState([]);
  const [buildings_data, setBuildingsData] = useState([]);
  const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState();
  const [WarrantyIndex, setWarrantyIndex] = useState();
  const [, setUsers] = usersData;
  const [, setSchedulers] = schedulersData;
  const [user, setUser] = userInfo;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [, setTotalBuildings] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [var_mdl_bulk_open, set_mdl_bulk_open] = useState(false);
  const [var_mdl_bulk_open_result, set_mdl_bulk_open_result] = useState(false);
  
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [final_response, setFinalResponse] = useState({
    buildings: 0,
    units: 0,
    error_units: 0,
    building_error: 0,
    valid_buildings: 0,
    valid_units: 0,
    front_error_logs: [],
  });
  const { addToast } = useToasts();

  useEffect(() => {
    // getListOfUsers();
  }, []);

  // useEffect(() => {
  //   get_all_buildings();
  // }, [page, limit]);

  useEffect(() => {
    // getListofScheduler();
  }, []);

  useEffect(() => {
    //Get comnpl;ete buildings to export.
    exportBuilding();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const delayDebounceFn = setTimeout(async () => {
        get_all_buildings(0, limit, searchQuery);
      }, 1200);

      return () => clearTimeout(delayDebounceFn);
    } else {
      get_all_buildings();
    }
  }, [searchQuery, page, limit]);

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/
  async function delete_building() {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    var apiUrl = deleteBuilding + `/${deletedIndex}`;
    await fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        const { success, code } = res;
        if (code === 200) {
          addToast(success, {
            appearance: "success",
            autoDismiss: true,
          });
          get_all_buildings();
        } else {
          addToast(success, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function getHeaders(sheet) {
    var header = 0;
    var hdr = [];
    var o = {};
    if (sheet == null || sheet["!ref"] == null) return [];
    var range = o.range !== undefined ? o.range : sheet["!ref"];
    var r;
    if (o.header === 1) header = 1;
    else if (o.header === "A") header = 2;
    else if (Array.isArray(o.header)) header = 3;
    switch (typeof range) {
      case "string":
        r = safe_decode_range(range);
        break;
      case "number":
        r = safe_decode_range(sheet["!ref"]);
        r.s.r = range;
        break;
      default:
        r = range;
    }
    //if (header > 0) offset = 0;
    var rr = XLSX.utils.encode_row(r.s.r);
    var cols = new Array(r.e.c - r.s.c + 1);
    for (var C = r.s.c; C <= r.e.c; ++C) {
      cols[C] = XLSX.utils.encode_col(C);
      var val = sheet[cols[C] + rr];
      switch (header) {
        case 1:
          hdr.push(C);
          break;
        case 2:
          hdr.push(cols[C]);
          break;
        case 3:
          hdr.push(o.header[C - r.s.c]);
          break;
        default:
          if (val === undefined) continue;
          hdr.push(XLSX.utils.format_cell(val));
      }
    }
    return hdr;
  }

  function safe_decode_range(range) {
    var o = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
    var idx = 0,
      i = 0,
      cc = 0;
    var len = range.length;
    for (idx = 0; i < len; ++i) {
      if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break;
      idx = 26 * idx + cc;
    }
    o.s.c = --idx;

    for (idx = 0; i < len; ++i) {
      if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break;
      idx = 10 * idx + cc;
    }
    o.s.r = --idx;

    if (i === len || range.charCodeAt(++i) === 58) {
      o.e.c = o.s.c;
      o.e.r = o.s.r;
      return o;
    }

    for (idx = 0; i !== len; ++i) {
      if ((cc = range.charCodeAt(i) - 64) < 1 || cc > 26) break;
      idx = 26 * idx + cc;
    }
    o.e.c = --idx;

    for (idx = 0; i !== len; ++i) {
      if ((cc = range.charCodeAt(i) - 48) < 0 || cc > 9) break;
      idx = 10 * idx + cc;
    }
    o.e.r = --idx;
    return o;
  }

  const resultBack = () => {
    console.log("Error");
  };

  const readExcel = (file) => {
    set_mdl_progress(true);
    const promise = new Promise((resolve, reject) => {
      final_response.valid_buildings = 0;
      final_response.valid_units = 0;
      final_response.front_error_logs = [];
      final_response.error_units = 0;
      final_response.building_error = 0;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const temp_wb = XLSX.read(bufferArray, {
          type: "buffer",
          sheetRows: 1,
        });
        if (temp_wb.SheetNames.length > 1) {
          const index_location = temp_wb.SheetNames.indexOf("Location Summary");
          const index_bedroom = temp_wb.SheetNames.indexOf(
            "Bedroom Information"
          );
          if (index_bedroom > -1 && index_location > -1) {
            const headers = getHeaders(
              temp_wb.Sheets[temp_wb.SheetNames[index_location]]
            );
            const headers_sheet_two = getHeaders(
              temp_wb.Sheets[temp_wb.SheetNames[index_bedroom]]
            );
            if (
              headers.includes("Location Code") &&
              headers.includes("Address") &&
              headers.includes("Customer Name") &&
              headers.includes("PO/MO/WO Flag") &&
              headers.includes("Location Email") &&
              headers.includes("Scheduler Email") &&
              headers.includes("Time Window 1") &&
              headers.includes("Time Window 2") &&
              headers_sheet_two.includes("Location") &&
              headers_sheet_two.includes("Unit") &&
              headers_sheet_two.includes("Bedroom Count") &&
              headers_sheet_two.includes("TH Flag") &&
              headers_sheet_two.includes("Warranty Pest 1") &&
              headers_sheet_two.includes("Warranty Service 1") &&
              headers_sheet_two.includes("Warranty Pest 1 Date") &&
              headers_sheet_two.includes("Warranty Service 1 Date")
            ) {
              const wb = XLSX.read(bufferArray, { type: "buffer" });
              const ws_location = wb.Sheets[wb.SheetNames[index_location]];
              const ws_bedroom = wb.Sheets[wb.SheetNames[index_bedroom]];
              const data = [];

              var validBedroom = [];
              var validBuildings = [];

              const removeSpaceLocation = JSON.parse(
                JSON.stringify(XLSX.utils.sheet_to_json(ws_location)).replace(
                  /\s(?=[^"]*":)/g,
                  ""
                )
              );
              var removeSpaceBedroom = JSON.parse(
                JSON.stringify(XLSX.utils.sheet_to_json(ws_bedroom)).replace(
                  /\s(?=[^"]*":)/g,
                  ""
                )
              );

              // console.log("ws_location::",removeSpaceLocation,removeSpaceBedroom)
              // return;
              final_response.buildings = removeSpaceLocation.length;
              final_response.units = removeSpaceBedroom.length;
              var temp_units = [];
              removeSpaceLocation.map((val) => {
                temp_units = [
                  ...temp_units,
                  ...removeSpaceBedroom.filter(
                    (val_units) => val_units.Location === val.LocationCode
                  ),
                ];
              });
              removeSpaceBedroom = [...temp_units];
              removeSpaceBedroom.map((val, index) => {
                console.log(
                  "Data in sheet:::",
                  val.Location,
                  ":::",
                  val.Unit,
                  ":::",
                  val.BedroomCount,
                  ":::"
                );
                if (val.Location && val.Unit && val.BedroomCount) {
                  if (val.WarrantyDate) {
                    var date_fetch = "";
                    try {
                      date_fetch = getJsDateFromExcel(val.WarrantyDate);
                    } catch (error) {
                      console.log("error", error);
                      date_fetch = "";
                    }
                    if (moment(date_fetch).isValid()) {
                      if (val.WarrantyPest || val.WarrantyService) {
                        validBedroom = [...validBedroom, val];
                      } else {
                        var temp_count = final_response.error_units;
                        final_response.error_units = temp_count + 1;
                        final_response.front_error_logs = [
                          ...final_response.front_error_logs,
                          {
                            sheet: wb.SheetNames[index_bedroom],
                            row: index + 2,
                            column_name: "Warranty Pest / Warranty Service",
                            reason: "Warranty pest or service missing.",
                          },
                        ];
                      }
                    } else {
                      var temp_count2 = final_response.error_units;
                      final_response.error_units = temp_count2 + 1;
                      final_response.front_error_logs = [
                        ...final_response.front_error_logs,
                        {
                          sheet: wb.SheetNames[index_bedroom],
                          row: index + 2,
                          column_name: "Warranty Date",
                          reason: "Wrong date format.",
                        },
                      ];
                    }
                  } else {
                    validBedroom = [...validBedroom, val];
                  }
                } else if (!val.Location && val.Unit && val.BedroomCount) {
                  var temp_count3 = final_response.error_units;
                  final_response.error_units = temp_count3 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_bedroom],
                      row: index + 2,
                      column_name: "Location",
                      reason: "Missing Location code.",
                    },
                  ];
                } else if (val.Location && !val.Unit && val.BedroomCount) {
                  var temp_count4 = final_response.error_units;
                  final_response.error_units = temp_count4 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_bedroom],
                      row: index + 2,
                      column_name: "Unit #",
                      reason: "Missing Unit #.",
                    },
                  ];
                } else if (
                  val.Location &&
                  val.Unit &&
                  (!val.BedroomCount || val.BedroomCount === "")
                ) {
                  var temp_count4 = final_response.error_units;
                  final_response.error_units = temp_count4 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_bedroom],
                      row: index + 2,
                      column_name: "Unit #",
                      reason: "Missing Bedroom count.",
                    },
                  ];
                } else if ((!val.Location || !val.Unit) && val.BedroomCount) {
                  var temp_count5 = final_response.error_units;
                  final_response.error_units = temp_count5 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_bedroom],
                      row: index + 2,
                      column_name: "Bedroom Count",
                      reason:
                        "There is not unit or location against bedroom count.",
                    },
                  ];
                }
              });

              removeSpaceLocation.map((val_build, index) => {
                if (
                  val_build.LocationCode &&
                  val_build.Address &&
                  val_build.CustomerName &&
                  val_build.LocationEmail &&
                  // && val_build.SchedulerEmail
                  val_build["TimeWindow1"] &&
                  val_build["TimeWindow2"]
                ) {
                  validBuildings = [...validBuildings, val_build];
                }
                if (!val_build.LocationCode) {
                  var temp_count = final_response.building_error;
                  final_response.building_error = temp_count + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_location],
                      row: index + 2,
                      column_name: "Location Code",
                      reason: "Missing Location code.",
                    },
                  ];
                }
                if (!val_build.Address) {
                  var temp_count2 = final_response.building_error;
                  final_response.building_error = temp_count2 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_location],
                      row: index + 2,
                      column_name: "Address",
                      reason: "Missing Address.",
                    },
                  ];
                }
                if (!val_build.CustomerName) {
                  var temp_count3 = final_response.building_error;
                  final_response.building_error = temp_count3 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_location],
                      row: index + 2,
                      column_name: "Customer Name",
                      reason: "Missing Customer name.",
                    },
                  ];
                }
                if (!val_build.LocationEmail) {
                  var temp_count4 = final_response.building_error;
                  final_response.building_error = temp_count4 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_location],
                      row: index + 2,
                      column_name: "Location Email",
                      reason: "Missing Location email.",
                    },
                  ];
                }
                if (!val_build.SchedulerEmail) {
                  var temp_count5 = final_response.building_error;
                  final_response.building_error = temp_count5 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_location],
                      row: index + 2,
                      column_name: "Scheduler Email",
                      reason: "Missing Scheduler email.",
                    },
                  ];
                }
                if (!val_build.TimeWindow1) {
                  var temp_count5 = final_response.building_error;
                  final_response.building_error = temp_count5 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_location],
                      row: index + 2,
                      column_name: "Time Window 1",
                      reason: "Missing Time Window 1.",
                    },
                  ];
                }
                if (!val_build.TimeWindow2) {
                  var temp_count5 = final_response.building_error;
                  final_response.building_error = temp_count5 + 1;
                  final_response.front_error_logs = [
                    ...final_response.front_error_logs,
                    {
                      sheet: wb.SheetNames[index_location],
                      row: index + 2,
                      column_name: "Time Window 2",
                      reason: "Missing Time Window 2.",
                    },
                  ];
                }
              });
              final_response.valid_buildings = validBuildings.length;
              final_response.valid_units = temp_units.length;
              data.push(validBuildings);
              data.push(validBedroom);
              resolve(data);
            } else {
              set_mdl_progress(false);
              resolve(null);
              console.log("Please upload a valid sheet.1");
            }
          } else {
            set_mdl_progress(false);
            resolve(null);
            console.log("Please upload a valid sheet.2");
          }
        } else {
          set_mdl_progress(false);
          resolve(null);
          console.log("Please upload a valid sheet.3");
        }
      };

      fileReader.onerror = (error) => {
        reject(error);
        set_mdl_progress(false);
        console.log("Error:", error);
      };
    });

    promise.then(async (d) => {
      var temp_erro = { ...final_response };
      setFinalResponse(temp_erro);
      if (d && d.length > 1) {
        const requestOptionsBuildings = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + user.token,
          },
          body: JSON.stringify({
            buildings: d[0],
            units: d[1],
            user_id: user.user_id,
          }),
        };
        await fetch(postBulkBuildings, requestOptionsBuildings)
          .then((response) => response.json())
          .then((res) => {
            set_mdl_bulk_open(false);
            set_mdl_progress(false);
            if (res.code && res.code === 401) {
              addToast("Session expired. Please Login again.", {
                appearance: "error",
                autoDismiss: true,
              });
              //SessionExpire();
              localStorage.removeItem("user");
              setUser(null);
            } else {
              if (res.code === 200) {
                set_mdl_bulk_open_result(true);
                get_all_buildings(0, limit);
                getListofScheduler();
              } else {
                addToast(res.success, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            }
          })
          .catch((error) => {
            console.log("Errors:", error);
            set_mdl_progress(false);
            addToast("There are some error.", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      } else {
        set_mdl_progress(false);
        addToast("Please upload valid file.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  async function get_all_buildings(page_no, limit_range, q) {
    var query_params = "";
    if (q) {
      query_params = `?q=${q}&page=${page_no ? page_no : page}&limit=${
        limit_range ? limit_range : limit
      }`;
    } else {
      query_params = `?page=${page_no ? page_no : page}&limit=${
        limit_range ? limit_range : limit
      }`;
    }
    set_mdl_progress(true);
    await fetch(getAllBuildings + query_params)
      .then((response) => response.json())
      .then((res) => {
        var buildingFetch = [];
        const { data } = res;
        setTotalBuildings(res.total_building);
        if (res.code === 200) {
          buildingFetch = data;
          if (data.length === 0) {
            //setPage(page-1);
          }
        } else {
          buildingFetch = [];
        }

        if ((page && page > 1) || (page_no && page_no > 1))
          buildingFetch = q
            ? [...buildingFetch]
            : [...buildingsAdmin, ...buildingFetch];
        setBuildingsAdmin(buildingFetch);
        set_mdl_progress(false);
      })
      .catch((error) => {
        console.error("Error building get:", error);
        set_mdl_progress(false);
      });
  }

  const getListOfUsers = async () => {
    await fetch(getAllUsers + "?page=1&limit=100&is_role=customer")
      .then((response) => response.json())
      .then((res) => {
        var usersFetch = [];
        const { data } = res;
        data.forEach((element, pos) => {
          usersFetch = [
            ...usersFetch,
            {
              uid: element.uid,
              first_name: element.first_name,
              last_name: element.last_name,
              address: element.adds,
              email: element.email,
              contact_number: element.phone,
              role: element.role ? element.role : "",
              key: element.uid,
              text: element.first_name + " " + element.last_name,
              value: element.uid,
              company: element.company,
              buildings: element.building_count,
            },
          ];
        });
        setUsers(usersFetch);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getListofScheduler = async () => {
    await fetch(getAllUsers + "?page=1&limit=100&is_role=scheduler")
      .then((response) => response.json())
      .then((res) => {
        var usersFetch = [];
        const { data } = res;
        data.forEach((element, pos) => {
          usersFetch = [
            ...usersFetch,
            {
              uid: element.uid,
              first_name: element.first_name,
              last_name: element.last_name ? element.last_name : "",
              address: element.adds,
              email: element.email,
              contact_number: element.phone,
              role: element.role ? element.role : "",
              key: element.uid,
              text: element.first_name,
              value: element.uid,
              company: element.company,
              buildings: element.building_count,
            },
          ];
        });
        setSchedulers(usersFetch);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const goToAddBuilding = () => {
    history.push("/building-management/add-building");
  };

  //Remove duplicate units from bulk building upload.
  function onlyUnique(value, index, self) {
    //return self.indexOf(value) === index;
  }

  const goToBulkAdd = () => {
    set_mdl_bulk_open(true);
    //set_mdl_bulk_open_result(true);
  };

  async function exportBuilding() {
    set_mdl_progress(true);
    let res = await fetch(getAllBuildings + `?export=true`)
      .then((response) => response.json())
      .then(async (res) => {
        set_mdl_progress(false);
        if (res.code === 200 && res.data.length > 0) {
          await setBuildingsData(res.data);
          //console.log("Data fetched:",res.data);
          return true;
        }
      })
      .catch((error) => {
        set_mdl_progress(false);
        setBuildingsData([]);
        console.log("Error:", error);
        return false;
      });
  }

  const goToUpdateBuilding = (uuid) => {
    history.push({
      pathname: "/building-management/update-building",
      state: { building_id: uuid },
    });
  };

  const goToViewBuilding = (building) => {
    history.push({
      pathname: "/building-management/view-building",
      state: { data: building.uuid },
    });
  };

  const onOpen_mdl_confirmation = (index) => {
    setDeletedIndex(index);
    set_mdl_confirmation(true);
  };

 

  async function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
    setPage(1);
    setSearchQuery(value);
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <div className="app-content">
        <div className="app-building-container">
          <div className="app-content-action">
            <div className="app-content-action-right">
              {buildingsAdmin.length > 0 && (
                <CSVLink
                  className="app-csv-link"
                  filename={"homepro-buildings.csv"}
                  asyncOnClick={true}
                  data={buildings_data}
                  target="_blank"
                >
                  Export Buildings
                </CSVLink>
              )}

              <Button color="orange" onClick={() => goToBulkAdd()}>
                <img src="/bulk-upload.svg" alt="icon" />
                Bulk Building
              </Button>
            </div>
          </div>

          <div className="app-card">
            <div className="app-card-header building-managment-header-card">
              <div className="app-card-title">
                Building Management (
                {searchResult && searchResult.length > 0
                  ? searchResult.length
                  : buildingsAdmin.length}
                )
              </div>
              <div className="app-content-search">
                <Search
                  placeholder="Search Building"
                  onSearchChange={doSearch}
                  // minCharacters={20000}
                  showNoResults={false}
                  value={searchQuery}
                />
              </div>
            </div>

            <div className="app-table-wrapper">
              <Table unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Building</Table.HeaderCell>
                    <Table.HeaderCell>Pin</Table.HeaderCell>
                    <Table.HeaderCell>Master Pin</Table.HeaderCell>
                    <Table.HeaderCell>Customer</Table.HeaderCell>
                    <Table.HeaderCell>Scheduler</Table.HeaderCell>
                    <Table.HeaderCell>Warranty</Table.HeaderCell>
                    <Table.HeaderCell>Warranty Used</Table.HeaderCell>
                    <Table.HeaderCell>Units</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Actions
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {buildingsAdmin.map((building, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{building.building_name}</Table.Cell>
                      <Table.Cell>{building.building_pin}</Table.Cell>
                      <Table.Cell>{building.master_pin}</Table.Cell>
                      <Table.Cell>{building.customer_name}</Table.Cell>
                      <Table.Cell>{building.scheduler_email}</Table.Cell>
                      <Table.Cell>{building.Warranty_Count}</Table.Cell>
                      <Table.Cell>{building.Warranty_Used}</Table.Cell>
                      <Table.Cell>
                        {building.units ? building.units.length : 0}
                      </Table.Cell>
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        <Label
                          color="blue"
                          onClick={() => goToViewBuilding(building)}
                        >
                          View
                        </Label>
                        <Label
                          color="green"
                          onClick={() => goToUpdateBuilding(building.uuid)}
                        >
                          Edit
                        </Label>
                        <Label
                          color="red"
                          onClick={() => onOpen_mdl_confirmation(building.uuid)}
                        >
                          Delete
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  {/* {searchResult.length > 0 &&
                    searchResult.map((building, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{building.building_name}</Table.Cell>
                        <Table.Cell>{building.building_pin}</Table.Cell>
                        <Table.Cell>{building.master_pin}</Table.Cell>
                        <Table.Cell>{building.customer_name}</Table.Cell>
                        <Table.Cell>{building.scheduler_email}</Table.Cell>
                        <Table.Cell>
                          {building.units ? building.units.length : 0}
                        </Table.Cell>
                        <Table.Cell
                          className="td-action"
                          collapsing
                          textAlign="right"
                        >
                          <Label
                            color="blue"
                            onClick={() => goToViewBuilding(building)}
                          >
                            View
                          </Label>
                          <Label
                            color="green"
                            onClick={() => goToUpdateBuilding(building.uuid)}
                          >
                            Edit
                          </Label>
                          <Label
                            color="red"
                            onClick={() =>
                              onOpen_mdl_confirmation(building.uuid)
                            }
                          >
                            Delete
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    ))} */}
                </Table.Body>
              </Table>
              {buildingsAdmin.length === 0 && (
                <div className="nodata-placeholder">
                  There is no building to display
                </div>
              )}
            </div>
          </div>
          <AppPagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            rowsTotal={buildingsAdmin.length}
          />
        </div>
      </div>

      {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
      <Modal
        id="mdl-confirmation"delete_building
        dimmer={"inverted"}
        open={var_mdl_confirmation}
        onClose={() => set_mdl_confirmation(false)}
      >
        <MDL_CONFIRMATION
          set_mdl_open={set_mdl_confirmation}
          var_modaltitle="Delete Building"
          var_message="Are you sure want to delete this building?"
          confirmation_function={delete_building}
        ></MDL_CONFIRMATION>
      </Modal>

      {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}

      {/***** MODAL: UPLOAD BULK Buildings **************************************************************************/}
      <Modal
        id="mdl-upload-bulkunit"
        dimmer={"inverted"}
        open={var_mdl_bulk_open}
        onClose={() => set_mdl_bulk_open(false)}
      >
        <MDL_BULK_BUILDING
          set_mdl_open={set_mdl_bulk_open}
          onChangeEvent={readExcel}
          set_mdl_bulk_result={set_mdl_bulk_open_result}
        />
      </Modal>

      {/***** END MODAL: UPLOAD BULK Buildings **************************************************************************/}
      
      {/***** MODAL: UPLOAD BULK Buildings result **************************************************************************/}
      <Modal
        id="mdl-upload-bulk-result"
        dimmer={"inverted"}
        open={var_mdl_bulk_open_result}
        onClose={() => set_mdl_bulk_open_result(false)}
      >
        <MDL_BULK_BUILDING_RESULT
          set_mdl_open={set_mdl_bulk_open_result}
          set_mdl_upload_bulk={set_mdl_bulk_open}
          data={final_response}
          onChangeEvent={resultBack}
        />
      </Modal>

      {/***** END MODAL: UPLOAD BULK Buildings result **************************************************************************/}
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Stay on this page until this process finish."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
  );
}

export default BuildingManagment;
