import React, { useState, useEffect, useContext } from "react";
import { Icon, Button, Form, Input } from "semantic-ui-react";
import { createUser } from "../../../../constants";
import { useToasts } from "react-toast-notifications";
import "./mdl-add-scheduler.css";
import { AppContext } from "../../../../Context/AppContext";
const { passwordStrength } = require("check-password-strength");

export default function MDL_ADD_SCHEDULER({
  setPage,
  limit,
  set_mdl_open,
  update_users_list,
}) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo, validateTextField } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [var_add_scheduler, set_add_scheduler] = useState();
  const [isLoading, setLoading] = useState();
  const [var_disabled, set_disabled] = useState(false);
  const [errors] = useState([]);
  const { addToast } = useToasts();

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {}, []);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "email") {
      var pattern_email = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern_email.test(value)) {
        errors["email"] = "Please enter valid email address.";
      } else {
        errors["email"] = null;
      }
    } else if (name === "password") {
      if (!value || value?.length === 0) {
        errors["password"] = "Please write a valid password.";
      } else if (value.length < 8) {
        errors["password"] = "Password length must be 8 or more.";
      } else {
        errors["password"] = null;
      }
    } else if (name === "first_name") {
      if (validateTextField(value) === false) {
        errors["first_name"] = "Please enter valid first name.";
      } else {
        errors["first_name"] = null;
      }
    } else if (name === "last_name") {
      if (validateTextField(value) === false) {
        errors["last_name"] = "Please enter valid last name.";
      } else {
        errors["last_name"] = null;
      }
    }
    if(errors[name] === null) {
      set_disabled(false);
    }else{
      set_disabled(true);
    }
    set_add_scheduler({ ...var_add_scheduler, [name]: value });
  };

  const onSubmit_updated_data = async (e) => {
    e.preventDefault();
    if (errors["first_name"]) {
      errors["first_name"] = "Please add valid first name.";
    } else if (errors["last_name"]) {
      errors["last_name"] = "Please add valid last name.";
    } else if (errors["password"]) {
      errors["password"] = "Please add valid password.";
    } else if (errors["email"]) {
      errors["email"] = "Please add valid email.";
    } else {
      setLoading(true);
      const { first_name, last_name, address, password, email } =
        var_add_scheduler;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + user.token,
        },
        body: JSON.stringify({
          first_name,
          last_name,
          address,
          contact_number: "",
          email,
          password,
          is_role: 1,
          company: "HomePro",
          user_id: user.user_id,
        }),
      };

      await fetch(createUser, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          set_mdl_open(false);
          if (res.code && res.code === 401) {
            addToast("Session expired. Please Login again.", {
              appearance: "error",
              autoDismiss: true,
            });
            //SessionExpire();
            localStorage.removeItem("user");
            setUser(null);
          } else {
            if (res.statusCode === 200) {
              addToast(res.body.success, {
                appearance: "success",
                autoDismiss: true,
              });
              update_users_list(1, limit);
              
            } else {
              addToast(res.body.success, {
                appearance: "error",
                autoDismiss: true,
              });
            }
            setPage(1);
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    }
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <Form method="POST" onSubmit={onSubmit_updated_data} loading={isLoading}>
        <div className="modal-header">
          <div className="modal-header-title">Add Scheduler</div>
          <div
            className="modal-header-close"
            onClick={() => set_mdl_open(false)}
          >
            <Icon name="times circle outline" />
          </div>
        </div>
        <div className="modal-content">
          <Form.Group>
            <Form.Field
              id="first_name"
              required={true}
              name="first_name"
              label="First Name"
              control={Input}
              onChange={onChangeInput}
              error={errors["first_name"]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="last_name"
              required={true}
              name="last_name"
              label="Last Name"
              control={Input}
              onChange={onChangeInput}
              error={errors["last_name"]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="email"
              required={true}
              name="email"
              label="Email"
              control={Input}
              type="email"
              error={errors["email"]}
              onChange={onChangeInput}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="password"
              name="password"
              required={true}
              label="Password"
              type="password"
              error={errors["password"]}
              control={Input}
              onChange={onChangeInput}
            />
          </Form.Group>
          {/*<Form.Group>
              <Form.Field
                id="contact_number"
                required={true}
                name="contact_number"
                label="Contact Number"
                type="phone"
                control={Input}
                error={errors["contact_number"]}
                onChange={onChangeInput}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                id="address"
                required={true}
                name="address"
                label="Address"
                control={Input}
                onChange={onChangeInput}
              />
            </Form.Group>
            */}
        </div>

        <div className="modal-footer" >
          <Button type="submit" color="red" disabled={var_disabled}>
            Add Scheduler
          </Button>
        </div>
      </Form>
    </>
  );
}
