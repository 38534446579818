import React, {useState, useEffect, useContext} from 'react'
import { Icon, Button, Form, Input, Message } from 'semantic-ui-react';
import { portalDocuments } from '../../../../constants';
import { AppContext } from "../../../../Context/AppContext";
import { useToasts } from "react-toast-notifications";
import './mdl-update-folder.css';



export default function MDL_UPDATE_FOLDER({ folder, set_mdl_open, update_folder_list}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/

    const {  userInfo } = useContext(AppContext);
    const [user,] = userInfo;
    const [ var_update_folder, set_update_folder ] = useState({})
    const { addToast } = useToasts();


    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

    useEffect(()=>{
        set_update_folder({title: folder.title});
    },[])


  //async function ------------------------------------------------------------------------------------------------------

  const submitUpdateFolder = async () => {

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify({
        title: var_update_folder.title.trim(),
        description: var_update_folder.description
      }),
    };
    await fetch(`${portalDocuments}/folder/${folder.uuid}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
          if (res.code === 200) {
            set_mdl_open(false);
            update_folder_list();
            addToast(res.success, {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(res.success, {
              appearance: "error",
              autoDismiss: true,
            });
          }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  //  API calls ------------------------------------------------------------------------------------------------------


    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        const {name, value}  = event.target;
        set_update_folder({...var_update_folder, [name]: value});
    }

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form  method="PUT" onSubmit={submitUpdateFolder}>
            <div className="modal-header">
                <div className="modal-header-title">
                <span>
              <img
                src="/folder-icon.png"
                alt="pdf"
                style={{ width: "32px", height: "32px" }}
              />
              <span> {`${folder.title}`}</span>
            </span>
                    </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">

                <Form.Group>
                    <Form.Field
                        id='title'
                        name='title'
                        label='Folder Title'
                        type='text'
                        value={var_update_folder.title}
                        required
                        control={Input}
                        onChange={onChangeInput}
                    />
                </Form.Group>
            </div>
            <Message
                error
                header='Error'
                // content={form_validation_error.error}
            />
            <div className="modal-footer">
                <Button type="submit" color="red">
                    Submit
                </Button>
            </div>
        </Form>
        </>
    )
}

