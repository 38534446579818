import React, {useState, useEffect,useContext} from 'react'
import { Icon, Button,Form,Input, Dropdown,Modal } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import {useToasts } from 'react-toast-notifications';
import { AppContext } from '../../../../Context/AppContext';
import moment from "moment";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";  
import { getCriticalUnitServiceDetails,getBuildingLookup,createCriticalUnit } from "../../../../constants";


const MDL_ADD_CRITICAL_UNIT = ({set_mdl_open,setPage}) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const{userInfo}=useContext(AppContext);
  const[user]=userInfo;
  const [building_units,setBuildingUnits] = useState([]);
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [selected_building, setSelectedBuilding] = useState([]);
  const [currentDate, setNewDate] = useState(moment().toDate());
  const [ServiceDate, setNewServiceDate] = useState(moment().toDate());
  const [errors, set_errors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [ var_critical_info, set_critical_info ] = useState()

   /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    // Fetch initial building data (optional, based on your use case)
    fetchBuildingInfo('');
  }, []); // Empty dependency array for initial fetch


  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/
  async function fetchBuildingInfo(searchQuery) {
    setIsLoading(true);
    try {
      const response = await fetch(getBuildingLookup + `?q=${searchQuery}`);
      if (!response.ok) {
        throw new Error("Failed to fetch building info");
      }
      const data = await response.json();
      if (data.code === 200) {
        const formattedBuilding = data.data.map(building => ({
          value: building.value,
          text: `${building.text} (${building.location_number})`,
          customer_name:`${building.customer_name}`,
          units: building.units,
        }));
        setBuildingInfo(formattedBuilding);
      } else {
        console.log("Else data:::", data.message);
      }
    } catch (error) {
      console.error("Error fetching building info:", error);
    } finally {
      setIsLoading(false); // Ensure loading state is updated even on errors
    }
  }

  async function getUnitdata(unit) {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
      }
      const response = await fetch(
        getCriticalUnitServiceDetails +
          `?unitNumber=${
            selected_building?.units.find((item) => item.value === unit.toString())?.text
          }&locationID=${selected_building?.value}`,requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch unit info");
      }
      const data = await response.json();
      if (data.code === 200) {
        // Update var_critical_info with the new data, ensuring state merges correctly
        setNewServiceDate(data.data.last_service_date
          ? moment(data.data.last_service_date).toDate()
          : null)
        set_critical_info((prevState) => ({
          ...prevState,
          tech_reported: data.data.tech_reported || "",
          last_tech_comment: data.data.last_tech_comment || "",
        }));
      } else {
        set_critical_info((prevState) => ({
          ...prevState,
          tech_reported:  "",
          last_service_date: null,
          last_tech_comment:  "",
        }));
        console.log("Error:", data.message);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }
  

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/


function isValidated() {
    var temp_error = { ...errors };
    var isValid = true;
    if (!currentDate) {
      temp_error.dateReportedDate = "Please select date.";
      isValid = false;
    }
    if (selected_building.length === 0) {
      temp_error.selected_building = "Please select building.";
      isValid = false;
    }
    if (building_units.length === 0 ) {
      temp_error.buildingunits = "Please select units.";
      isValid = false;
    }

    if (!var_critical_info || !var_critical_info.hasOwnProperty('tech_reported') || var_critical_info.tech_reported === "") {
      temp_error.tech_reported = "Please add tech reported.";
      isValid = false;
    }

    if (!var_critical_info || !var_critical_info.hasOwnProperty('summary') || var_critical_info.summary === "") {
      temp_error.summary = "Please add summary.";
      isValid = false;
    }

    if(var_critical_info.summary?.length > 500){
      temp_error.summary = "Summary Character limit exceeded.";
      isValid = false;
    }

    if (!var_critical_info || !var_critical_info.hasOwnProperty('csr_actions') || var_critical_info.csr_actions === "") {
      temp_error.csr_actions = "Please add csr's actions.";
      isValid = false;
    }
  
    if(var_critical_info.csr_actions?.length > 500){
      temp_error.csr_actions = "CSR's action Character limit exceeded.";
      isValid = false;
    }

    if(!ServiceDate){
      temp_error.lastScheduledDate = "Please select service date.";
      isValid = false;
    }

    // if (!var_critical_info || !var_critical_info.hasOwnProperty('ss_rm_steps') || var_critical_info.ss_rm_steps === "") {
    //   temp_error.ss_rm_steps = "Please add next steps.";
    //   isValid = false;
    // }

    if(var_critical_info?.ss_rm_steps?.length > 500){
      temp_error.ss_rm_steps = "SS/RM Next Step Character limit exceeded.";
      isValid = false;
    }

    // if (!var_critical_info || !var_critical_info.hasOwnProperty('note') || var_critical_info.note === "") {
    //   temp_error.note = "Please add note.";
    //   isValid = false;
    // }

    if(var_critical_info?.note?.length > 500){
      temp_error.note = "Note Character limit exceeded.";
      isValid = false;
    }

    if (!var_critical_info || !var_critical_info.hasOwnProperty('last_tech_comment') || var_critical_info.last_tech_comment === "") {
      temp_error.last_tech_comment = "Please add last tech comment.";
      isValid = false;
    }

    if(var_critical_info.last_tech_comment?.length > 500){
      temp_error.last_tech_comment = "Last Tech Comment Character limit exceeded.";
      isValid = false;
    }

    if (isValid) {
      set_errors({});
    } else {
      set_errors(temp_error);
    }
    return isValid;
  }

function onChangeUnits(event, { value }) {
    // var temp_error = { ...errors };
    // temp_error.buildingunits = null;
    // set_errors(temp_error);
    // const filteredArray = selected_building?.units?.filter(unit => value.includes(unit.value));
    getUnitdata(value)
    setBuildingUnits(value);
}

const convertDateFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString();
};

async function handleCriticalUnitDetails(){
    if(isValidated()){
      set_mdl_progress(true)
      var_critical_info.buildingID = selected_building?.value;
      var_critical_info.unit_number = selected_building?.units.find(item => item.value === building_units.toString())?.text;
      var_critical_info.date_reported = convertDateFormat(currentDate).replace('Z','');
      var_critical_info.last_service_date = convertDateFormat(ServiceDate).replace('Z','');
      var_critical_info.status = 'ongoing';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
            body: JSON.stringify(var_critical_info)
        };
        await fetch(createCriticalUnit, requestOptions)
        .then(response => response.json())
        .then(res=>{
            set_mdl_open(false)
            setPage(1)
            if(res.code && res.code === 401){
                addToast("Session expired. Please Login again.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            }
            else{
                if(res.code===200){
                    addToast(res.success, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                }
                else{
                    addToast(res.success, {
                        appearance: 'error',
                        autoDismiss: true,
                    })  
                }

            }
            
        }).catch(err=>{
            console.error("Error:",err);
        });
    }
    set_mdl_progress(false)
}

const onChange = (event, data) => {
if (data?.value) {
  try {
    // Parse the date using moment and convert it to a Date object
    const parsedDate = moment(data.value).toDate();
    
    // If the date is valid, update the state and reset the error
    if (!isNaN(parsedDate.getTime())) {
      var temp_error = { ...errors };
      temp_error.currentDate = null; // Reset the specific error
      set_errors(temp_error);
      setNewDate(parsedDate); // Set the date directly as a Date object
    } else {
      throw new Error("Invalid date");
    }
  } catch (error) {
    console.error("Date parsing error:", error.message);
  }
}
};

const onChangeLastServiceDate = (event, data) => {
  if (data?.value) {
    try {
      // Parse the date using moment and convert it to a Date object
      const parsedDate = moment(data.value).toDate();
      
      // If the date is valid, update the state and reset the error
      if (!isNaN(parsedDate.getTime())) {
        var temp_error = { ...errors };
        temp_error.lastScheduledDate = null; // Reset the specific error
        set_errors(temp_error);
        setNewServiceDate(parsedDate); // Set the date directly as a Date object
      } else {
        throw new Error("Invalid date");
      }
    } catch (error) {
      console.error("Date parsing error:", error.message);
    }
  }
};

// const onChangeInput = (event) => {
//   let name = event.target.name;
//   let value = event.target.value;
// }

const handleSearchChange = (event) => {
  const searchQuery = event.target.value;
  fetchBuildingInfo(searchQuery);
};

const onChangeBuilding = (event, { value }) => {
  var tempError = { ...errors };
  tempError.selected_building = null;
  set_errors(tempError);
  setSelectedBuilding(buildingInfo.find(building => building.value === value));
};

const onChangeInput = (event) => {
  let name = event.target.name;
  let value = event.target.value;

  // Check if the value exceeds 500 characters
  if (value.length > 500) {
    // Set error for exceeding character limit
    set_errors((prevErrors) => ({
      ...prevErrors,
      [name]: 'Character limit exceeded',
    }));
  } else {
    // Clear the error if within limit
    set_errors({});
  }

    set_critical_info({ ...var_critical_info, [name]: value });
};

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
        <div className="modal-header">
            <div className="modal-header-title">Add Critical Unit Details</div>
            <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                <Icon name="times circle outline" />
            </div>
        </div>
        <div className="modal-content  fields">
            <label htmlFor="sortby_options">Location ID or Building Address: </label>
              <Dropdown
                search
                selection
                className="sortby_options"
                id="sortby_options"
                name="sortby_options"
                placeholder="Select Building"
                label="Building Address: "
                onSearchChange={handleSearchChange}
                onChange={onChangeBuilding}
                value={selected_building?.value}
                options={buildingInfo}
                error={errors.selected_building}
              />
              
            <label htmlFor="sortby_options">Client: </label>
            <Form.Field
                id='client'
                required={true}
                name='client'
                placeholder='Client'
                style={{width:'100%'}}
                disabled={true}
                control={Input}
                value={selected_building?.customer_name}
            />
            <label htmlFor="sortby_options">Unit: </label>
             <Dropdown
                search
                style={{width:'100%'}}
                selection
                className="sortby_units"
                id="sortby_units"
                name="sortby_units"
                placeholder="Select Units"
                label="Units: "
                onChange={onChangeUnits}
                value={building_units}
                error={errors.buildingunits}
                options={selected_building?.units}
                disabled={selected_building?.value ? false : true}
                />
                <label htmlFor="sortby_options">Date Reported: </label>
                <SemanticDatepicker
                style={{width:'100%'}}
                id="dateReportedDate"
                name="dateReportedDate"
                placeholder={'Date Reported'}
                fluid
                value={currentDate}
                error={errors?.dateReportedDate}
                onChange={onChange}
                />
                <label htmlFor="sortby_options">Tech Reported: </label>
                <Form.Field
                  id='tech_reported'
                  name='tech_reported'
                  placeholder='Tech Reported'
                  required={true}
                  style={{width:'100%', borderColor: errors["tech_reported"] ? 'red' : 'black',backgroundColor: errors["tech_reported"] ? '#fff6f6' : 'white'}}
                  control={Input}
                  error={errors["tech_reported"]}
                  value={var_critical_info?.tech_reported}
                  onChange={onChangeInput}
                />
                <label htmlFor="sortby_options">Summary of Issue: </label>
                <Form.Field>
                <Form.TextArea
                    id='summary'
                    style={{width:'100%',height: '40px', borderColor: errors["summary"] ? 'red' : 'black',backgroundColor: errors["summary"] ? '#fff6f6' : 'white'}}
                    name='summary'
                    required={true}
                    value={var_critical_info?.summary}
                    error={errors["summary"]}
                    onChange={onChangeInput}
                />
                <div style={{ textAlign: 'right', color: var_critical_info?.summary?.length > 500 ? 'red' : 'gray' }}>
                  {errors["summary"] ? 0 : 500 - (var_critical_info?.summary?.length || 0)} characters left
                </div>
                </Form.Field>
                <label htmlFor="sortby_options">Last Scheduled Service Date: </label>
                <SemanticDatepicker
                    style={{width:'100%'}}
                    id="lastScheduledDate"
                    name="lastScheduledDate"
                    placeholder={'Date'}
                    required={true}
                    fluid
                    value={ServiceDate}
                    error={errors?.lastScheduledDate}
                    onChange={onChangeLastServiceDate}
                />
                <label htmlFor="sortby_options">CSR's Action: </label>
                <Form.Field>
                <Form.TextArea
                    id='csr_actions'
                    style={{width:'100%',height: '40px', borderColor: errors["csr_actions"] ? 'red' : 'black',backgroundColor: errors["csr_actions"] ? '#fff6f6' : 'white'}}
                    name='csr_actions'
                    required={true}
                    value={var_critical_info?.csr_actions}
                    error={errors["csr_actions"]}
                    onChange={onChangeInput}
                />
                <div style={{ textAlign: 'right', color: var_critical_info?.csr_actions?.length > 500 ? 'red' : 'gray' }}>
                  {errors["csr_actions"] ? 0 : 500 - (var_critical_info?.csr_actions?.length || 0)} characters left
                </div>
                </Form.Field>
                <label htmlFor="sortby_options">SS/RM Next Step: </label>
                <Form.Field>
                <Form.TextArea
                    id='ss_rm_steps'
                    style={{width:'100%',height: '40px'}}
                    name='ss_rm_steps'
                    required={true}
                    value={var_critical_info?.ss_rm_steps}
                    error={errors["ss_rm_steps"]}
                    onChange={onChangeInput}
                />
                <div style={{ textAlign: 'right', color: var_critical_info?.ss_rm_steps?.length > 500 ? 'red' : 'gray' }}>
                  {errors["ss_rm_steps"] ? 0 : 500 - (var_critical_info?.ss_rm_steps?.length || 0)} characters left
                </div>
                </Form.Field>
                <label htmlFor="sortby_options">Note: </label>
                <Form.Field>
                <Form.TextArea
                    id='note'
                    style={{width:'100%',height: '40px'}}
                    name='note'
                    value={var_critical_info?.note}
                    onChange={onChangeInput}
                    error={errors["note"]}
                />
                <div
                  style={{
                    textAlign: 'right',
                    color: var_critical_info?.note?.length > 500 ? 'red' : 'gray',
                  }}
                >
                  {errors["note"] ? 0 : 500 - (var_critical_info?.note?.length || 0 )} characters left
                </div>
                </Form.Field>
                <label htmlFor="sortby_options">Last Tech Comment: </label>
                <Form.Field>
                <Form.TextArea
                    id='last_tech_comment'
                    width={20}
                    style={{width:'100%',height: '110px', borderColor: errors["last_tech_comment"] ? 'red' : 'black',backgroundColor: errors["last_tech_comment"] ? '#fff6f6' : 'white'}}
                    name='last_tech_comment'
                    value={var_critical_info?.last_tech_comment}
                    error={errors["last_tech_comment"]}
                    onChange={onChangeInput}
                />
                <div style={{ textAlign: 'right', color: var_critical_info?.last_tech_comment?.length > 500 ? 'red' : 'gray' }}>
                  {errors["last_tech_comment"] ? 0 : 500 - (var_critical_info?.last_tech_comment?.length || 0)} characters left
                </div>
              </Form.Field>
        </div>
        <div className="modal-footer">
            <Button type="submit" color="red" onClick={handleCriticalUnitDetails} disabled={var_mdl_progress} loading={var_mdl_progress}>
             Add
            </Button>
        </div>
        {/***** MODAL: PROGRESS **************************************************************************/}
        <Modal
            id="mdl-progress"
            dimmer={"inverted"}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}
          >
            <MDL_PROGRESS
              set_mdl_open={set_mdl_progress}
              var_modaltitle="Please wait"
              var_message={"Stay on this page until this process finish."}
            ></MDL_PROGRESS>
          </Modal>

        {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
)
};
export default MDL_ADD_CRITICAL_UNIT;
