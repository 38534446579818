import React, { useEffect,useContext, useState,useRef } from "react";
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Modal,
  Icon,
  Select,
  Sidebar,
  Form,
  Input,
  Popup,
} from "semantic-ui-react";
import "./cmp-side-slider-cart.css";
import MDL_CONFIRMATION from "../../components/cmp-confirmation/cmp-confirmation";
import MDL_NOTE from "../../containers/customer/customer-home/mdl-note/mdl-note";
import { getAllPests, getAllPestsTreatment } from "../../constants";
import { AppContext } from "../../Context/AppContext";
import { useHistory } from "react-router-dom";
export default function SideSliderCart({ visible }) {

  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const {
    sliderCart,
    cartItems,
    selectedBuilding,
    selected_units,
    checkedUnitsData,
    UncheckedUnitsData,
    building_order_details,
  } = useContext(AppContext);
  const previousCartItemsLengthRef = useRef();
  const [selected_building] = selectedBuilding;
  const [var_add_unit, set_add_unit] = selected_units;
  const [building_order, setBuildingOrder] = building_order_details;
  const history = useHistory();
  const [, setSideSliderCart] = sliderCart;
  const [cart_items, setCartItems] = cartItems;
  const [, setCheckedUnits] = checkedUnitsData;
  const [, setUnCheckedUnits] = UncheckedUnitsData;
  const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);
  const [pestTreated, setPestTreated] = useState();
  const [treatmentOption, setTreatmentOptions] = useState();
  const [var_mdl_note, set_mdl_note] = useState(false);
  const [treatmentsFetched, setTreatmentsFetched] = useState(true);
  const [var_cart_note, set_cart_note] = useState();
  const [var_cart_item, set_cart_item] = useState();
  const [var_cart_index, set_cart_index] = useState();
  const order_types =[{
    key: "neworder",
    text: "New",
    value: "neworder",
  },
  {
    key: "followup",
    text: "Follow Up (Service In 2 Weeks)",
    value: "followup",
  },
  {
    key: "followup_month",
    text: "Follow Up (Service In Month)",
    value: "followup_month",
  },
  {
    key: "followup_immediately",
    text: "Follow Up (Service Immediately)",
    value: "followup_immediately",
  },
  {
    key: "reschedule",
    text: "Reschedule",
    value: "reschedule",
  }]

  const [sidebarWidth, setSidebarWidth] = useState(350); // Initial width in pixels
  const [isResizing, setIsResizing] = useState(false);
  

 /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    if (visible && cart_items && treatmentsFetched) {
      Object.keys(cart_items).forEach(item_key => {
        cart_items[item_key].forEach((item, item_index) => {
          if (!item.treatmentsFetched) { // Add condition to avoid fetching again
            getPestTreatments(item.pest_id, item_key, item_index);
            // Mark that the treatments have been fetched
            item.treatmentsFetched = true;
          }
        });
      });
    }
  
    if (selected_building && (pestTreated?.length === 0 || pestTreated === undefined)) {
      getPests(); // Assuming getPests fetches the list of all pests
    }
  }, [visible, treatmentsFetched, cart_items]);


  useEffect(() => {
    const currentLength = Object.keys(cart_items || {}).reduce(
      (total, key) => total + cart_items[key].length,
      0
    );
    if (
      previousCartItemsLengthRef.current !== undefined &&
      previousCartItemsLengthRef.current !== currentLength
    ) {
      
      // The length of cart_items has changed
      setTreatmentsFetched(true);
    }else{
      setTreatmentsFetched(false);
    }
  
    // Update the ref to the current length of cart_items after each render
    previousCartItemsLengthRef.current = currentLength;
  }, [cart_items]);

  useEffect(() => {
    // Add event listeners for mouse movements when resizing
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    // Clean up event listeners when not resizing
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);
  
 /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/
  // async function getPestTreatments(pestId) {
  //   await fetch(`${getAllPestsTreatment}${pestId}`)
  //     .then((response) => response.json())
  //     .then((res) => {
  //       const allTempPest = [];
  //       if (res.code === 200) {
  //         res.data.forEach((value) => {
  //           allTempPest.push({
  //             key: value.treatmentName || value.treatment_name,
  //             text: value.treatmentName || value.treatment_name,
  //             value: value.treatmentId || value.treatment_id,
  //           });
  //         });
  //         setTreatmentOptions(prevOptions => ({
  //           ...prevOptions,
  //           [pestId]: allTempPest,
  //         }));
  //       }
  //     });
  // }

 async function getPestTreatments(selected_pest, item_key, item_index) {
  await fetch(getAllPestsTreatment + (selected_pest ? selected_pest : ''))
    .then((response) => response.json())
    .then((res) => {
      var allTempPest = [];
      if (res.code === 200) {
        res.data.forEach((value) => {
          allTempPest.push({
            key: value.treatmentName || value.treatment_name,
            text: value.treatmentName || value.treatment_name,
            value: value.treatmentId || value.treatment_id,
          });
        });

        if (selected_pest) {
          const updatedCartItems = { ...cart_items };
          updatedCartItems[item_key][item_index].treatmentOptions = allTempPest;
          setCartItems(updatedCartItems); // Update the cart_items state
        } else {
          setTreatmentOptions(allTempPest); // Update general treatment options
        }
      }
      setTreatmentsFetched(false);
    });
}

  async function getPests() {
    await fetch(getAllPests)
      .then((response) => response.json())
      .then((res) => {
        var allTempPest = [];
        if (res.code === 200) {
          res.data.map((value, i) => {
            allTempPest = [
              ...allTempPest,
              {
                key: value.pestName,
                text: value.pestName,
                value: value.pestId,
              },
            ];
          });
          setPestTreated(allTempPest);
        } else {
        }
      });
  }

  async function handleSubmitUnits() {
    const temp_m_units = [];
  
    Object.keys(cart_items)?.forEach((item_key) => {
      cart_items[item_key].forEach((item) => {
        if (item.unit_number && item.unit_number.trim() !== '') {
          temp_m_units.push({
            unit_number: item.unit_number,
            floor: item.floor,
            followup: item.selectedRequestType ? item.selectedRequestType : item.followup ? item.followup : 'neworder',
            pest: item.selectedPestName ? item.selectedPestName : item.pest,
            treatment: item.selectedTreatmentName ? item.selectedTreatmentName : item.treatment,
            pest_id: item.selectedPest ? item.selectedPest : item.pest_id,
            treatment_id: item.selectedTreatment ? item.selectedTreatment : item.treatment_id,
            order_description: item.order_description,
            exist: true,
            key: item.key,
          });
        }
      });
    });
  
    if (var_add_unit.length) {
      temp_m_units.push(...var_add_unit);
    }
    // Filter out any element with an empty unit_number just to be sure
    const filteredUnits = temp_m_units.filter(unit => unit.unit_number !== '');
    set_add_unit(filteredUnits);
    setSideSliderCart(false);
    history.push(`/order-request/${selected_building?.location_id}`);
  }


/* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

  function onCloseButton() {
    setSideSliderCart(false);
  }

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleDeleteItem  = (e, { name, value },itemKey, index, unit_number) => {
    // Make a copy of cart_items
    const updatedCartItems = { ...cart_items };
    var DeleteUnitData = itemKey + "-" + unit_number;
    // Remove the item at the specified index from the cart_items
    updatedCartItems[itemKey].splice(index, 1);
    // If the array becomes empty after deletion, remove it from cart_items
    setUnCheckedUnits(DeleteUnitData);
    if (updatedCartItems[itemKey].length === 0) {
      delete updatedCartItems[itemKey];
    }
    // Check if updatedCartItems is empty
    if (isEmptyObject(updatedCartItems)) {
      // Handle the case when cart_items becomes empty
      setCartItems(null);
    } else {
      // Update the state with the new cart_items
      setCartItems(updatedCartItems);
    }
  };

  function handleClearAll() {
    setCartItems(null);
    setCheckedUnits({});
    setBuildingOrder({ ...building_order, 'po_mo_wo': '' });
    previousCartItemsLengthRef.current= 0;
    setUnCheckedUnits("All");
  }

  const onChangeInput = (e, { name, value }, item_key, item_index) => {
    let item
    const updatedCartItems = { ...cart_items };
    if(item_key && item_index !== ''){
      item = updatedCartItems[item_key][item_index];
    }
    
    switch (name) {
      case 'po_mo_wo':
        setBuildingOrder({ ...building_order, 'po_mo_wo': value });
        break;
      case 'order_pest':
        const pest = pestTreated.find(pest => pest.value === value);
        item.selectedPest = value;
        item.selectedPestName = pest ? pest.text : '';
        getPestTreatments(value, item_key, item_index); // Fetch treatments for the selected pest
        break;
      case 'order_treatment':
        if(item.selectedPest || item.pest_id){
          const treatment = (item.treatmentOptions? item.treatmentOptions : treatmentOption).find(treatment => treatment.value === value);
          item.selectedTreatment = value;
          item.selectedTreatmentName = treatment ? treatment.text : '';
        }
        break;
      case 'order_type_request':
        item.selectedRequestType = value;
        break;
      default:
        break;
    }

    setCartItems(updatedCartItems);
  };
  const handleMdlNote = (item_key, item_index, note) => {
    set_mdl_note(true)
    set_cart_item(item_key);
    set_cart_index(item_index);
    set_cart_note(note);
  }

    // Handle the start of the resize action
    const handleMouseDown = (e) => {
      e.preventDefault();
      setIsResizing(true);
    };
  
    // Handle the resize action as the mouse moves
  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = Math.max(window.innerWidth - e.clientX, 200); // Adjust based on right edge of the screen
      setSidebarWidth(newWidth);
    }
  };
  
    // Stop the resize when the mouse is released
    const handleMouseUp = () => {
      if (isResizing) {
        setIsResizing(false);
      }
    };

   /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
       <Sidebar
        animation="overlay"
        direction="right"
        style={{
          backgroundColor: "#FFFFFF",
          padding: "0 12px",
          overflowX: "hidden",
          position: "fixed",
          top: 0,
          width: `${sidebarWidth}px`, // Sidebar width based on drag
        }}
        vertical
        visible={visible}
      >
        <Grid>
          <GridRow>
            <Button
              color="red"
              onClick={onCloseButton}
              style={{ marginLeft: "10px", marginTop: "6px" }}
              icon="close"
              basic
              circular
            />
          </GridRow>
        </Grid>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: "5px",
            cursor: "ew-resize",
            backgroundColor: "#ddd",
            zIndex: 9999,
          }}
          onMouseDown={handleMouseDown}
        />
        <Grid textAlign="center" style={{ marginTop: "48px" }}>
          <GridRow columns={1} style={{padding:'0 12px'}}>
            <GridColumn
              style={{
                textAlign: "left",
                padding: "0 12px",
                color: "#000000",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Order Details
            </GridColumn>
            <GridColumn style={{ textAlign: "left", padding: "2px 12px" }}>
              {/* <p style={{ fontSize: "12px" }}>SubText</p> */}
            </GridColumn>
            {cart_items !== null &&
            <GridColumn className="po_number" style={{padding: "2px 12px",marginTop: "15px"}}>
                 <Form.Field
                    label={
                      selected_building?.building_po_mo_wo?.toLowerCase() ===
                      "wo"
                        ? "Work Order Number"
                        : selected_building?.building_po_mo_wo?.toLowerCase() ===
                          "mo"
                        ? "Maintenance Order Number"
                        : "Purchase Order Number"
                    }
                    width={8}
                    control={Input}
                    required={selected_building?.building_po_mo_wo}
                    name="po_mo_wo"
                    id="po_mo_wo"
                    value={building_order?.po_mo_wo}
                    placeholder={`${
                      selected_building?.building_po_mo_wo?.toUpperCase() ||
                      "PO #"
                    }`}
                    onChange={onChangeInput}
                    maxLength={30}
                  />
            </GridColumn>
            }
          </GridRow>
          
            {cart_items === null ? (
              <>
              <GridRow columns={5}  style={{ maxHeight: '730px',overflowY:"auto", height:'50%'}}>
                <div
                  style={{
                    fontSize: "16px",
                    backgroundColor: "white",
                    color: "#000000",
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  <img src="/no-data.png" alt="icon" />
                  {/* <Image src={emptyListings}></Image> The list is Empty! */}
                </div></GridRow>
              </>
            ) : (
              <>
              <GridRow columns={5}  >
                <GridColumn
                  style={{
                    fontSize: "10px",
                    color: "#000000",
                    fontWeight: 700,    paddingLeft: '36px'
                  }}
                >
                  Unit#
                </GridColumn>
                <GridColumn
                  style={{
                    fontSize: "10px",
                    color: "#000000",
                    fontWeight: 700,
                  }}
                >
                  Pest
                </GridColumn>
                <GridColumn
                  style={{
                    fontSize: "10px",
                    color: "#000000",
                    fontWeight: 700,
                  }}
                >
                  Treatment
                </GridColumn>
                <GridColumn
                  style={{
                    fontSize: "10px",
                    color: "#000000",
                    fontWeight: 700,
                  }}
                >
                  Req. Type
                </GridColumn>
                <GridColumn
                  style={{
                    fontSize: "10px",
                    color: "#000000",
                    paddingLeft:0,
                    fontWeight: 700,
                    textAlign:'left'
                  }}
                >
                  Notes
                </GridColumn>
                </GridRow>
                {/* <GridColumn
                  style={{
                    fontSize: "10px",
                    color: "#000000",
                    fontWeight: 700,
                  }}
                >
                </GridColumn> */}
                <Grid container className="customGrid"> 
                {cart_items &&
                  Object.keys(cart_items)?.map((item_key, index) =>
                    cart_items[item_key].map((item, item_index) => (
                      <>
                        {/* <GridColumn
                          style={{
                            fontSize: "12px",
                            padding: "8px 0",
                            textTransform: "capitalize",
                            borderBottom: "1px solid grey",
                          }}
                        >
                          {item.floor}
                        </GridColumn> */}
                        <GridRow columns={5} style={{paddingTop:0,paddingBottom:0,alignItems:'baseline', borderBottom: "1px solid grey",height: '48px',justifyContent:'center' }}>
                        <GridColumn
                          style={{
                            fontSize: "12px",
                            padding: "8px 0",
                            display:'flex',justifyContent:'center',alignItems:'center'
                          }}
                        >
                          <span>{item.unit_number}</span>
                        </GridColumn>
                        <GridColumn
                        style={{
                          fontSize: "12px",
                          padding: "8px 0",
                        }}
                      >
                        <Popup
                          content={pestTreated?.find(i=> i.value === (item.selectedPest ?  item.selectedPest : item.pest_id))?.text || 'Select type of pest'}
                          trigger={<Select
                            name="order_pest"
                            width={1}
                            style={{minWidth:'90%',margin:'0 8px'}}
                            className={'single-line-select'}
                            id="order_pest"
                            placeholder="Select type of pest"
                            options={pestTreated}
                            selectOnBlur={false}
                            value={item.selectedPest ? item.selectedPest : item.pest_id}
                            onChange={(e, data) => onChangeInput(e, data, item_key, item_index)}
                            required
                          />}
                        />
                        
                       
                      </GridColumn>
                      <GridColumn
                        style={{
                          fontSize: "12px",
                          padding: "8px 0",
                        }}
                      >
                        <Popup
                          content={(item?.treatmentOptions ? item?.treatmentOptions : treatmentOption)?.find(i=> i.value === (item.selectedTreatment ? item.selectedTreatment : item.treatment_id))?.text|| 'Select type of treatment'}
                          trigger={<Select
                            name="order_treatment"
                            width={1}
                            className={'single-line-select'}
                            style={{minWidth:'90%',margin:'0 8px'}}
                            id="order_treatment"
                            placeholder="Select type of treatment"
                            options={item.treatmentOptions || treatmentOption}
                            selectOnBlur={false}
                            value={item.selectedTreatment ? item.selectedTreatment : item.treatment_id}
                            onChange={(e, data) => onChangeInput(e, data, item_key, item_index)}
                            required
                          />}
                        />
                        
                      </GridColumn>
                      <GridColumn
                        style={{
                          fontSize: "12px",
                          padding: "8px 0",
                        }}
                      >
                        <Popup
                          content={order_types?.find(i=> i.value === (item?.selectedRequestType ? item?.selectedRequestType : item.followup))?.text || 'Select type of request'}
                          trigger={<Select
                            name="order_type_request"
                            width={1}
                            className={'single-line-select'}
                            style={{minWidth:'90%',margin:'0 8px'}}
                            id="order_type_request"
                            placeholder="Select type of request"
                            options={order_types}
                            selectOnBlur={false}
                            value={item.selectedRequestType ? item.selectedRequestType : item.followup ? item.followup:'neworder'}
                            onChange={(e, data) => onChangeInput(e, data, item_key, item_index)}
                            required
                          />}
                        />
                      </GridColumn>
                        <GridColumn
                          style={{
                            fontSize: "12px",
                            padding: "8px 0",
                            display:'flex',justifyContent:'flex-start',alignItems:'center',gap:'4px'
                          }}
                        >
                          
                          <span 
                          onClick={(e, data) =>
                            handleMdlNote(
                              item_key, item_index,
                              item.order_description
                            )
                          }
                          style={{cursor: "pointer"}}>
                          <Popup
                          content={'Add Notes'}
                          trigger={<Icon
                            style={{ padding: "0px 33px 0 15px", cursor: "pointer",margin:0 }}
                            name="clipboard list"
                            onClick={(e, data) =>
                              handleMdlNote(
                                item_key, item_index,
                                item.order_description
                              )
                            }
                          />}
                        />
                          
                          </span>
                          <span style={{cursor: "pointer"}}>
                          {/* <Popup
                          content={'Delete item'}
                          trigger={<Icon
                            style={{ padding: "0 8px", cursor: "pointer",margin:0 }}
                            name="trash"
                            onClick={(e, data) =>
                              handleDeleteItem(
                                e, data, 
                                item_key,
                                item_index,
                                item.unit_number
                              )
                            }
                          />}
                        /> */}
                        <Icon
                              style={{ padding: "0 8px", cursor: "pointer",margin:0 }}
                              name="trash"
                              onClick={(e, data) =>
                                handleDeleteItem(
                                  e, data, 
                                  item_key,
                                  item_index,
                                  item.unit_number
                                )
                              }
                            />
                          
                          </span>
                        </GridColumn>
                        </GridRow>
                        {/* <GridColumn
                          style={{
                            fontSize: "12px",
                            padding: "8px 0",
                            borderBottom: "1px solid grey",display:'flex',justifyContent:'center',alignItems:'center',gap:'4px'
                          }}
                        >
                          
                          </GridColumn> */}
                      </>
                    ))
                  )}
                  </Grid>
              </>
            )}

            {/* Content */}
        </Grid>
        <Grid>
          <GridRow style={{ position: "absolute", bottom: "0",backgroundColor:'#fff' }} columns={2}>
            <GridColumn style={{ textAlign: "center" }}>
              <Button
                color="red"
                disabled={cart_items === null ? true : false}
                onClick={handleClearAll}
              >
                Clear All
              </Button>
            </GridColumn>
            <GridColumn style={{ textAlign: "center" }}>
              <Button
                color="blue"
                disabled={cart_items === null ? true : false}
                onClick={() => handleSubmitUnits()}
              >
                View Order
              </Button>
            </GridColumn>
          </GridRow>
        </Grid>
      </Sidebar>
        {/***** MODAL: NOTE **************************************************************************/}
        <Modal
        id="mdl-note"
        dimmer={"inverted"}
        open={var_mdl_note}
        size="small"
        onClose={() => set_mdl_note(false)}
      >
        <MDL_NOTE
          set_mdl_open={set_mdl_note}
          set_cart_note={set_cart_note}
          var_cart_note={var_cart_note}
          var_cart_item={var_cart_item}
          var_cart_index={var_cart_index}
        ></MDL_NOTE>
      </Modal>

      {/***** END MODAL: NOTE **************************************************************************/}
      {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
      <Modal
        id="mdl-confirmation"
        dimmer={"inverted"}
        open={var_mdl_confirmation}
        onClose={() => set_mdl_confirmation(false)}
      >
        <MDL_CONFIRMATION
          set_mdl_open={set_mdl_confirmation}
          var_modaltitle="Submit Units"
          var_message="Are you sure want to submit units?"
          confirmation_function={handleSubmitUnits}
        ></MDL_CONFIRMATION>
      </Modal>

      {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}
    </>
  );
}
