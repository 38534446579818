import React, { useContext, useState, useEffect } from "react";
import { Table, Modal, Checkbox, Button } from "semantic-ui-react";
import { AppContext } from "../../../Context/AppContext";
import { useHistory, useParams } from "react-router-dom";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import { getOrderTimeRange, postUpdateTimeWindow } from "../../../constants";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const ScheduleOrders = () => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const {
    userInfo,
    selectedBuilding,
    selected_units,
    building_order_details,
    buildingUnits,
  } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const { order_id } = useParams();
  const [order_units, set_order_units] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [btn_disable, set_btn_disable] = useState(true);
  const { addToast } = useToasts();

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    if (order_id) {
      getSchedulerOrders();
    }
  }, [order_id]);

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    var isDisabled = false;
    order_units.map((val_units) => {
      if (!val_units.time_window || val_units.time_window === undefined) {
        isDisabled = true;
      }
    });
    set_btn_disable(isDisabled);
  }, [order_units]);

  async function getSchedulerOrders() {
    await fetch(getOrderTimeRange + "?id=" + order_id)
      .then((response) => response.json())
      .then((res) => {
        if (res.statusCode === 200) {
          set_order_units(res?.body?.data || []);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

  async function postUpdatedOrder(units_set) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify({ order_id, data: units_set }),
    };
    set_mdl_progress(true);
    await fetch(postUpdateTimeWindow, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        set_mdl_progress(false);
        addToast(res.success, {
          appearance: res.code === 200 ? "success" : "error",
          autoDismiss: true,
        });
        if (res.code === 200) {
          history.replace("/scheduler-home");
        }
      })
      .catch((error) => {
        set_mdl_progress(false);
        addToast("Request timeout. Try again", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log("Error:::", error);
      });
  }

  function changeTimeWindow(index, time_window) {
    const temp = [...order_units];
    temp[index] = { ...temp[index], time_window: time_window };
    set_order_units(temp);
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <>
      <div className="app-content">
        <div className="app-card">
          <div className="app-card-header">
            <div>
              <div className="app-card-title">
                Units count: ({order_units.length})
              </div>
              <div className="app-card-subtitle">
                Align orders units time window
              </div>
            </div>
          </div>

          <div className="app-table-wrapper">
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Units #</Table.HeaderCell>
                  {/* <Table.HeaderCell>Unit #</Table.HeaderCell> */}
                  <Table.HeaderCell>Pest</Table.HeaderCell>
                  <Table.HeaderCell>Treatment</Table.HeaderCell>
                  <Table.HeaderCell>Treatment Code</Table.HeaderCell>
                  <Table.HeaderCell>Order Type</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Morning
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Afternoon
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {order_units &&
                  order_units.map((order, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{order.unit_number}</Table.Cell>
                      {/* <Table.Cell>{order.unit_number}</Table.Cell> */}
                      <Table.Cell>{order.pest_name}</Table.Cell>
                      <Table.Cell>{order.treatment_name} </Table.Cell>
                      <Table.Cell>{order.service_code} </Table.Cell>
                      <Table.Cell>
                        {order.is_immediate
                          ? "Follow Up Immediate"
                          : order.follow_up
                          ? "Follow Up"
                          : order.reschedule
                          ? "Reschedule Order"
                          : "New"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Checkbox
                          key={order.uuid + "window_1"}
                          checked={order.time_window === "time_window_1"}
                          onChange={changeTimeWindow.bind(
                            this,
                            i,
                            "time_window_1"
                          )}
                          toggle
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Checkbox
                          key={order.uuid + "window_2"}
                          checked={order.time_window === "time_window_2"}
                          onChange={changeTimeWindow.bind(
                            this,
                            i,
                            "time_window_2"
                          )}
                          toggle
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>

          {order_units.length === 0 && (
            <div className="nodata-placeholder">
              There is no order units to display.
            </div>
          )}
        </div>
        {order_units.length && (
          <div style={{ textAlign: "right" }}>
            <Button
              disabled={btn_disable}
              color="red"
              onClick={() => {
                postUpdatedOrder(order_units);
              }}
            >
              Submit order
            </Button>
          </div>
        )}
      </div>
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={
            "This process can take one or two minutes. Stay on this page."
          }
          success_function={() => {}}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
  );
};

export default ScheduleOrders;
