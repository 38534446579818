import React, {useState, useEffect} from 'react'
import { Icon, Button, Form, Input } from 'semantic-ui-react';
import './mdl-upload-bulk-units.css'

export default function MDL_UPLOAD_UNIT({set_mdl_open,onChangeEvent}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const [ var_add_unit, set_add_unit ] = useState()



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        
	}, [])
    
    
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        const file = event.target.files[0];
        if(file){
            set_add_unit(file);
        }
    }

    const add_bulk=()=>{
        if(var_add_unit)
        onChangeEvent(var_add_unit)
        set_mdl_open(false);
    }

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form method="POST" onSubmit={add_bulk}>
            <div className="modal-header">
                <div className="modal-header-title">Upload Bulk Unit</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                
                <Form.Group>
                    <Form.Field
                        id='upload_file'
                        name='upload_file'
                        label='You can upload units by choosing excel file from computer.'
                        control={Input}
                        type="file"
                        onChange={onChangeInput}
                    />
                </Form.Group>
            </div>

            <div className="modal-footer">
                <Button type="button" color="grey"
                onClick= {()=>{
                    fetch(process.env.PUBLIC_URL + '/BulkUnitUploadFormat.xlsx', {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/xlsx',
                        },
                      })
                      .then((response) => response.blob())
                      .then((blob) => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                          new Blob([blob]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                          'download',
                          `BulkUnitUploadFormat.xlsx`,
                        );
                    
                        // Append to html link element page
                        document.body.appendChild(link);
                    
                        // Start download
                        link.click();
                    
                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                      }).catch(error=>{
                          console.error("Error:",error);
                      });
                }}
                >Download Format</Button>
                <Button type="submit" color="red">
                    Submit
                </Button>
            </div>
        </Form>
        </>
    )
}

