import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, Button,Form } from 'semantic-ui-react';
import './cmp-alert.css';  // Assuming we have some CSS for styling

export default function CMP_ALERT({var_mdl_alert,set_mdl_alert,alert_message}) {
  // Function to handle popup close
  const closePopup = () => {
    set_mdl_alert(false);
    // Store in localStorage that the popup was dismissed
    localStorage.setItem('popupDismissed', 'true');
  };
  return (
    <>
      {var_mdl_alert && (
        <>
          <div className="modal-header">
            <div className="modal-header-title">Alert</div>
            <div className="modal-header-close" onClick={closePopup}>
              <Icon name="times circle outline" />
            </div>
          </div>
          <div className="modal-content">
            {alert_message?.map((message, index) => (
              <div key={index} className="alert-message">
                {/* Conditional rendering of the message content */}
                {message?.note_type === "ALERT" && (
                 <div style={{ whiteSpace: 'pre-line' }}>{message?.notes}</div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
