import React, { useContext, useEffect, useState } from "react";
import { Form, Icon, Label, Modal, Search, Table } from "semantic-ui-react";
import { AppContext } from "../../../Context/AppContext";
import { useToasts } from "react-toast-notifications";
import { useHistory, useParams } from "react-router-dom";
import {
  acceptOrRejectOrder,
  deleteOrder,
  getAllOrdersOfUser,
  viewPdfURL,
} from "../../../constants";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import CMP_PDF_VIEWER from "../../../components/cmp-pdf-viewer/cmp-pdf-viewer";
import AppPagination from "../../../components/cmp-pagination/Pagination";
import { isValid } from "date-fns";
import moment from "moment";

export default function BuildingOrders() {
  const { bInfo, selectedBuilding, buildingUnits, userInfo, selected_units } =
    useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [selected_building, setSelectedBuilding] = useState(null);
  const [current_sort, set_current_sorted] = useState({
    sort_by: "order_number",
    sort_by_value: "DESC",
  });
  const params = useParams();
  const [building_info, setBuildingInfo] = bInfo;
  const [user, setUser] = userInfo;
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selected_order, set_selected_order] = useState();
  const [pdf_url, set_pdf_url] = useState();
  const [open_pdf_viewer, set_open_pdf_viewer] = useState(false);
  const [sortBy, setSortBy] = useState(3);

  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    if (params) {
      setSelectedBuilding(params.building_id);
    }
  }, [params]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      // Send Axios request here
      // getListOfCustomers();
      if (selected_building) {
        set_mdl_progress(true);
        await getBuildingOrders(1, limit);
        set_mdl_progress(false);
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    if (selected_building && page > 0) {
      getBuildingOrders(page, limit);
    }
  }, [limit, page, selected_building, current_sort]);

  useEffect(() => {
    if (selected_building) {
      setPage(1);
      getBuildingOrders(1, limit);
    }
  }, [sortBy]);

  function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
    setSearchQuery(value);
  }

  async function getBuildingOrders(page_no, limit_range) {
    set_mdl_progress(true);
    await fetch(
      getAllOrdersOfUser +
        `?search=${searchQuery}&order_by=${
          current_sort?.sort_by || ""
        }&order_by_value=${current_sort?.sort_by_value || ""}&page=${
          page_no ? page_no : page
        }&sort_by=${parseInt(sortBy === 4 ? 2 : sortBy)}&limit=${
          limit_range
            ? limit_range === "all"
              ? 99999
              : limit_range
            : limit === "all"
            ? 99999
            : limit
        }&is_admin=false&id=` +
        selected_building
    )
      .then((response) => response.json())
      .then((res) => {
        set_mdl_progress(false);
        var ordersFetch = [];
        if (res.statusCode === 200) {
          ordersFetch = res.body.data;
          setTotalOrders(res.body.total_count);
          // if(res.body && res.body.data && res.body.data.length === 0){
          //     //setPage(page-1)
          // }
        } else {
          ordersFetch = [];
        }
        if (page_no && page_no > 1) ordersFetch = [...orders, ...ordersFetch];
        setOrders(ordersFetch);
      })
      .catch((error) => {
        set_mdl_progress(false);
        console.error("Error:", error);
      });
  }

  async function acceptAndRejectRequest(request, is_accepted) {
    // history.push({pathname:"/complete-order",state:{id}})
    var temp = { ...request };
    temp.building_id = selected_building;
    temp.id = request.order_id;
    temp = { ...temp, is_accepted };
    temp.user_id = user.user_id;
    set_mdl_progress(true);
    const order_options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify(temp),
    };
    await fetch(acceptOrRejectOrder, order_options)
      .then((res) => res.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          localStorage.removeItem("building");
          setUser(null);
          setBuildingInfo(null);
        } else {
          if (res.code === 200) {
            getBuildingOrders(page, limit);
            addToast(res.message, {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(res.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log("error:", error);
      });
    set_mdl_progress(false);
  }

  function deleteOrderApiCall(
    unit_uuid,
    order_number,
    scheduler_email,
    building_name
  ) {
    var params = {};
    params = { ...params, order_unit_uuid: unit_uuid };
    params = { ...params, scheduler_email: scheduler_email };
    params = { ...params, order_number: order_number };
    params = { ...params, building_name: building_name };
    const requestOptionsOrder = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    set_mdl_progress(true);
    fetch(deleteOrder, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          getBuildingOrders(1, 50, sortBy);
        }
        addToast(res.message, {
          appearance: res.status === 200 ? "success" : "error",
          autoDismiss: true,
        });
        set_mdl_progress(false);
      })
      .catch((error) => {
        set_mdl_progress(false);
        console.log("Error:::::", error);
      });
  }

  function onChangeSortBy(event, { value }) {

      setSortBy(value);
  }

  function goForEditDraft(id) {
    history.push(`automatic-followup-order-request/${id}`);
  }

  function goViewDetail(id, unit_number) {
    history.push({ pathname: "/order-detail", state: { id, unit_number } });
  }

  return (
    <div className="app-content">
         <div className="app-backto-prev-page">
        <div
          className="app-goback-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon name="arrow left" /> Go Back
        </div>
      </div>
      <div className="app-card-header">
        <div className="app-page-title">
          <div className="app-card-title">Building Orders</div>
        </div>
        {/* <div className="app-content-action app-content-action-search"> */}
        <div className="app-content-action-right search-orders-list">
          {sortBy != 2 && (
            <Search
              placeholder="Search Unit Number"
              onSearchChange={(value) => doSearch(value)}
              showNoResults={false}
              value={searchQuery}
            />
          )}
        </div>
      </div>

      <div className="app-card">
        <div className="app-card-header">
          <div className="app-card-title">
            {"Orders List (" + orders.length + ")"}
          </div>
          <Form.Select
            className="sortby_options"
            id="sortby_options"
            name="sortby_options"
            placeholder="Sort By"
            label="Status: "
            onChange={onChangeSortBy}
            value={sortBy}
            options={[
              { key: 3, text: "All Orders", value: 3 },
              { key: 0, text: "In Progress / Scheduled", value: 0 },
              { key: 1, text: "Completed", value: 1 },
              { key: 2, text: "Draft", value: 2 },
              { key: 4, text: "Recommended", value: 4 },
            ]}
          />
        </div>

        <div className="app-table-wrapper">
          <Table sortable unstackable>
            <Table.Header className="main-home-table">
              {(sortBy != 2 && sortBy != 4) ? (
                <Table.Row>
                  <Table.HeaderCell
                    // icon={<Icon name="home" size="large" />}
                    sorted={
                      current_sort && current_sort.sort_by === "order_number"
                        ? current_sort.sort_by_value === "ASC"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      if (current_sort?.sort_by_value === "ASC") {
                        set_current_sorted({
                          sort_by: "order_number",
                          sort_by_value: "DESC",
                        });
                      } else {
                        set_current_sorted({
                          sort_by: "order_number",
                          sort_by_value: "ASC",
                        });
                      }
                    }}
                    title="Order #"
                  >
                    Order #
                    {current_sort?.sort_by !== "order_number" && (
                      <Icon size="20" name="sort" color="black" />
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Unit #">Unit #</Table.HeaderCell>
                  <Table.HeaderCell title="PO/MO/WO">
                  PO/MO/WO #
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Location name">
                    Location
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Pest">Pest</Table.HeaderCell>
                  <Table.HeaderCell title="Treatment">
                    Treatment
                  </Table.HeaderCell>

                  <Table.HeaderCell title="Current order status">
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={
                      current_sort && current_sort.sort_by === "work_date"
                        ? current_sort.sort_by_value === "ASC"
                          ? "ascending"
                          : "descending"
                        : null
                    }
                    onClick={() => {
                      if (current_sort?.sort_by_value === "ASC") {
                        set_current_sorted({
                          sort_by: "work_date",
                          sort_by_value: "DESC",
                        });
                      } else {
                        set_current_sorted({
                          sort_by: "work_date",
                          sort_by_value: "ASC",
                        });
                      }
                    }}
                    // icon={<Icon name="home" size="large" />}
                    title="Scheduled date"
                  >
                    {sortBy ? "Work" : "Schedule"} Date
                    {current_sort?.sort_by !== "work_date" && (
                      <Icon size="20" name="sort" color="black" />
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Order scheduler">
                    Time Window
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              ) : (
                <Table.Row>
                  <Table.HeaderCell title="No. of units">
                    Units
                  </Table.HeaderCell>
                  <Table.HeaderCell title="PO/MO/WO">
                    PO/MO/WO #
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Scheduler Name">
                    Scheduler
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Scheduler Email">
                    Email
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Date of request">
                    Requested Date
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Current request Status">
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              )}
            </Table.Header>
            <Table.Body>
              {(sortBy != 2 && sortBy != 4) &&
                orders &&
                orders.map((order, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{order.order_number}</Table.Cell>
                    <Table.Cell>{order.unit_number}</Table.Cell>
                    <Table.Cell>{order?.purchase_order_number || 'N/A' }</Table.Cell>
                    <Table.Cell>{order.building_name}</Table.Cell>
                    <Table.Cell>{order.pest_name || "N/A"}</Table.Cell>
                    <Table.Cell>{order.treatment_name || "N/A"}</Table.Cell>
                    <Table.Cell className="td-status">
                      {order.order_status === "completed" && (
                        <div style={{ color: "#05881F" }}>Completed</div>
                      )}
                      {order.order_status === "scheduled" && (
                        <div style={{ color: "#2196f3" }}>Scheduled</div>
                      )}
                      {order.order_status === "in progress" && (
                        <div style={{ color: "#FA8C16" }}>In Progress</div>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {order.schedule_date &&
                      isValid(new Date(order.schedule_date))
                        ? moment(order.schedule_date).format("l")
                        : "N/A"}
                    </Table.Cell>
                    <Table.Cell>
                      {order.time_range ? order.time_range : "N/A"}
                    </Table.Cell>

                    <Table.Cell
                      className="td-action"
                      collapsing
                      textAlign="right"
                    >
                      {order.order_status != "completed" ? (
                        <>
                          <Label
                            color="red"
                            onClick={() =>
                              deleteOrderApiCall(
                                order.unit_uuid,
                                order.order_number,
                                order.scheduler_email,
                                order.building_name
                              )
                            }
                          >
                            Delete
                          </Label>
                          <Label
                            color="blue"
                            onClick={() =>
                              goViewDetail(order.order_id, order.unit_number)
                            }
                          >
                            View
                          </Label>
                        </>
                      ) : (
                        <Label
                          color="blue"
                          onClick={() => {
                            set_selected_order(order.order_number);
                            set_pdf_url(viewPdfURL + order.order_number);
                            set_open_pdf_viewer(true);
                          }}
                        >
                          View Report
                        </Label>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              {sortBy == 2 &&
                orders.map((order, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{order?.units?.length}</Table.Cell>
                    <Table.Cell>{order?.purchase_order_number || 'N/A' }</Table.Cell>
                    <Table.Cell>{order.scheduler_first_name}</Table.Cell>
                    <Table.Cell>{order.scheduler_email}</Table.Cell>
                    <Table.Cell>{order.date}</Table.Cell>
                    <Table.Cell className="td-status">
                      {order.order_status === "draft" && (
                        <div style={{ color: "#FA8C16" }}>Draft</div>
                      )}
                    </Table.Cell>
                    <Table.Cell
                      className="td-action"
                      collapsing
                      textAlign="right"
                    >
                      <Label
                        color="blue"
                        onClick={() => goViewDetail(order.order_id, null)}
                      >
                        View
                      </Label>
                      <Label
                        color="green"
                        onClick={() => goForEditDraft(order?.order_id)}
                      >
                        Edit
                      </Label>
                      <Label
                        color="red"
                        onClick={() => acceptAndRejectRequest(order, false)}
                      >
                        Reject
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                ))}
                {sortBy == 4 &&
                orders.map((order, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{order?.units?.length}</Table.Cell>
                    <Table.Cell>{order?.purchase_order_number || 'N/A' }</Table.Cell>
                    <Table.Cell>{order.scheduler_first_name}</Table.Cell>
                    <Table.Cell>{order.scheduler_email}</Table.Cell>
                    <Table.Cell>{order.date}</Table.Cell>
                    <Table.Cell className="td-status">
                      {order.order_status === "draft" && (
                        <div style={{ color: "#FA8C16" }}>Draft</div>
                      )}
                    </Table.Cell>
                    <Table.Cell
                      className="td-action"
                      collapsing
                      textAlign="right"
                    >
                      <Label
                        color="blue"
                        onClick={() => goViewDetail(order.order_id, null)}
                      >
                        View
                      </Label>
                      <Label
                        color="green"
                        onClick={() => goForEditDraft(order?.order_id)}
                      >
                        Edit
                      </Label>
                      <Label
                        color="red"
                        onClick={() => acceptAndRejectRequest(order, false)}
                      >
                        Reject
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          {orders.length === 0 && (
            <div className="nodata-placeholder">
              There is no order to display.
            </div>
          )}
        </div>
        <div>
          <AppPagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            rowsTotal={orders.length}
            totalCount={totalOrders}
            showAll={true}
          />
        </div>
        {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
        <Modal
          id="mdl-pdf-viewer"
          dimmer={"inverted"}
          open={open_pdf_viewer}
          onClose={() => {
            set_open_pdf_viewer(false);
            set_pdf_url(null);
          }}
        >
          <CMP_PDF_VIEWER
            set_mdl_open={set_open_pdf_viewer}
            pdf_url={pdf_url}
            set_pdf_url={set_pdf_url}
            var_modaltitle={"Order #" + selected_order}
          />
        </Modal>
      </div>

      {/***** MODAL: PROGRESS **************************************************************************/}

      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
}
