import React, {useState, useEffect, useContext} from 'react'
import { Icon, Button, Form, Input,Checkbox, Dropdown } from 'semantic-ui-react';
import { getAllPests, getAllPestsTreatment, updateUnits } from '../../../../constants';
import { useToasts } from 'react-toast-notifications';
import dateFormat from 'dateformat';
import './mdl-update-unit.css'
import { AppContext } from '../../../../Context/AppContext';

export default function MDL_UPDATE_UNIT({set_mdl_open,model_data,getBuildingData}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const{userInfo}= useContext(AppContext);
    const [ var_add_unit, set_add_unit ] = useState(model_data);
    const [ var_warranty, set_warranty ] = useState(false);
    const [ var_warranty_type, set_warranty_type ] = useState('pest')
    const [ pestTreated, setPestTreated] = useState();
    const [ treatmentOption, setTreatmentOptions] = useState();
    const [selected_pest,setSelectedPest ]= useState();
    const[form_validation_error,setFormError]=useState({});
    const[isChanged,setIsChanged]=useState(false);
    const[user,setUser]=userInfo;

    const { addToast } = useToasts();



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        popinData()
	},[var_add_unit])

    useEffect(()=>{
        getPests();
    },[])

    useEffect(()=>{
        getPestTreatments();
    },[selected_pest])

    function popinData(){
        if(var_add_unit){
            if(var_add_unit.is_warranty==1){
                set_warranty(true)
            }else{
                set_warranty(false);
            }
            if(var_add_unit.is_on_pest===1){
                set_warranty_type('pest')
            }else if(var_add_unit.is_on_service===1){
                set_warranty_type('service')
            }
            setSelectedPest(var_add_unit.pest_id);
        }

    }

    async function getPestTreatments(){
        await fetch(getAllPestsTreatment+selected_pest)
        .then(response=>response.json())
        .then(res=>{
            var allTempPest=[];
            if(res.code===200){
                res.data.map((value,i)=>{
                    allTempPest=[...allTempPest,{
                        key:value.treatment_name,
                        text:value.treatment_name,
                        value:value.treatment_id
                        }
                    ]
                });
                setTreatmentOptions(allTempPest);
            }
            else{

            }
        })
    }
    async function getPests(){
        await fetch(getAllPests)
        .then(response=>response.json())
        .then(res=>{
            var allTempPest=[];
            if(res.code===200){
                res.data.map((value,i)=>{
                    allTempPest=[...allTempPest,{key:value.pestName,
                    text:value.pestName,
                    value:value.pestId}]
                });
                setPestTreated(allTempPest);
            }
            else{

            }
        })
    }
    
    
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        setIsChanged(true)
        if(name==="expire_date"){
            const current_date = new Date().toISOString().split("T")
            const warranty_date=value;
            if(current_date[0] > warranty_date || dateFormat(warranty_date,"yyyy").length > 4){
                form_validation_error.expire_date="Invalid Expiry date."
            }else{
                form_validation_error.expire_date=null
            }
        }
        if(name=="unit"){
            
            if(value<0){
                set_add_unit({...var_add_unit, [name]: ''});
            }
            else{
                set_add_unit({...var_add_unit, [name]: value});  
            }
        }
        else
        set_add_unit({...var_add_unit, [name]: value});
    }


    const onChangeWarranty = (e, {checked}) => {
        if(checked)
        {
            set_add_unit({...var_add_unit, is_warranty: 1});
            set_warranty(true)

        }
        else{
            set_add_unit({...var_add_unit, is_warranty: 0});
            set_warranty(false)
        }
        setIsChanged(true);
    }

    const onChangeWarrantyType = (e, {value}) => {
        set_warranty_type(value)
        if(value ==="pest"){
            set_add_unit({...var_add_unit, is_on_pest: 1,is_on_service: 0});
        }
        else{
            set_add_unit({...var_add_unit, is_on_service: 1,is_on_pest: 0});
        }
        setIsChanged(true)
    }

    const OnChangePest = (e,{value})=>{
        if(e.target.id===""){
            var error_validation={...form_validation_error};
            var temp={...var_add_unit};
                temp.pest_id=value;
                temp.warranty_pest=pestTreated.filter(val=>val.value===value)[0]?.text
                temp.treatment_id= null;
                temp.treatment=null
                set_add_unit(temp);
                setSelectedPest(value);
                error_validation.pest=null;
                setFormError(error_validation);
            }

            setIsChanged(true)
    }

    const OnChangeTreatment = (e,{value})=>{
        if(e.target.id===""){
        var error_validation={...form_validation_error};
        var temp={...var_add_unit};
            temp.treatment_id= value;
            temp.warranty_service=treatmentOption.filter(val=>val.value===value)[0]?.text
            set_add_unit(temp);
            setIsChanged(true)
            error_validation.treatment=null;
            setFormError(error_validation);
        }
    }

    const submitData=async()=>{
        if(validated()){
            var temp={};
            temp.unit_uuid=var_add_unit.uuid;
            temp.unit=var_add_unit.unit
            temp.bedroom_count=var_add_unit.bedroom_count;
            temp.town_house=var_add_unit.town_house;
            temp.is_warranty=var_add_unit.is_warranty;
            temp.w_pest=var_add_unit.warranty_pest;
            temp.w_treatment=var_add_unit.warranty_service;
            temp.pest_id=var_add_unit.pest_id;
            temp.treatment_id=var_add_unit.treatment_id;
            temp.is_on_service=var_add_unit.is_on_service?var_add_unit.is_on_service:var_warranty_type === 'service'?1:0;
            temp.is_on_pest=var_add_unit.is_on_pest?var_add_unit.is_on_pest:var_warranty_type === 'pest'?1:0;
            temp.w_expirey_date=var_add_unit.expire_date;
            temp.user_id = user.user_id;
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
                body: JSON.stringify(temp)
            };
            await fetch(updateUnits+var_add_unit.uuid,requestOptions)
            .then(res=>res.json())
            .then(response=>{
                if(response.code && response.code === 401){
                    addToast("Session expired. Please Login again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                    //SessionExpire();
                    localStorage.removeItem('user')
                    setUser(null)
                }
                else{
                    if(response.code===200){
                        addToast(response.success, {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                        getBuildingData(false);
                        set_mdl_open(false)
                    }else{
                        addToast(response.success, {
                            appearance: 'error',
                            autoDismiss: true,
                        }) 
                    }
                }
            }).catch(error=>{
                addToast("There are some error. Try agin later", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            })
        }
    }

    function validated(){
        var return_value=true;
        
        if(var_add_unit.is_warranty === 1){
            if(var_warranty_type === "pest"){
                if(!var_add_unit.pest_id){
                    return_value = false;
                }
            }
            if(var_warranty_type=== "service"){
                if(!var_add_unit.pest_id){
                    return_value = false;
                }
                if(!var_add_unit.treatment_id){
                    return_value = false;
                }
            }
            if(form_validation_error.expire_date){
                return_value = false;
            }
        }
        return return_value;
    }

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form onSubmit={submitData}>
            <div className="modal-header">
                <div className="modal-header-title">Update Unit</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                
                <Form.Group>
                    <Form.Field
                        id='unit'
                        name='unit'
                        label='Unit #'
                        type='text'
                        value={var_add_unit.unit}
                        required
                        control={Input}
                        onChange={onChangeInput}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='bedroom_count'
                        name='bedroom_count'
                        label='Beds'
                        type="text"
                        value={var_add_unit.bedroom_count}
                        required
                        control={Input}
                        onChange={onChangeInput}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Checkbox checked={ var_add_unit.town_house === 1 } toggle onChange={(e, {checked})=> 
                        {
                            checked?set_add_unit({...var_add_unit, town_house: 1}):set_add_unit({...var_add_unit, town_house: 0})
                            setIsChanged(true);
                        }
                        } 
                        label="Townhouse" />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox checked={ var_add_unit.is_warranty === 1 } toggle label="Warranty" onChange={onChangeWarranty} />
                    </Form.Field>
                </Form.Group>

                {var_warranty &&
                <Form.Group>
                    <Form.Field>
                        <label>Warranty type:</label>
                        <Checkbox
                            radio
                            defaultChecked
                            label='Pest'
                            name='warrantyType'
                            value='pest'
                            selectOnBlur={false}
                            checked={var_warranty_type === 'pest'}
                            onChange={onChangeWarrantyType}
                        />
                        <Checkbox
                            radio
                            label='Service code'
                            name='warrantyType'
                            value='service'
                            selectOnBlur={false}
                            checked={var_warranty_type === 'service'}
                            onChange={onChangeWarrantyType}
                        />
                    </Form.Field>
                </Form.Group>
                }
                {var_warranty && var_warranty_type === 'pest' &&
                <Form.Group>
                        <Form.Select
                            upward
                            selection
                            selectOnBlur={false}
                            label="Pest Requested"
                            placeholder='Select pest'
                            options={pestTreated}
                            value={var_add_unit.pest_id}
                            onChange={OnChangePest}
                            error={form_validation_error.pest}
                            required={var_add_unit.is_on_pest==1}
                        />
                </Form.Group>
                }
                {var_warranty && var_warranty_type === 'service' &&
                <Form.Group>
                        <Form.Select
                            upward
                            selection
                            selectOnBlur={false}
                            label="Pest Requested"
                            placeholder='Select pest'
                            options={pestTreated}
                            value={var_add_unit.pest_id}
                            onChange={OnChangePest}
                            error={form_validation_error.pest}
                            required={var_add_unit.is_on_service==1}
                        />
                        <Form.Select
                            upward
                            selection
                            selectOnBlur={false}
                            label="Treatment Requested"
                            placeholder='Select treatment'
                            options={treatmentOption}
                            value={var_add_unit.treatment_id}
                            error={form_validation_error.treatment}
                            onChange={OnChangeTreatment}
                            required={var_add_unit.is_on_service==1}
                        />
                </Form.Group>
                }
                {var_warranty &&
                <Form.Group>
                    <Form.Field
                        id='expire_date'
                        name='expire_date'
                        label='Expiry date'
                        type='date'
                        required = {var_warranty}
                        error={form_validation_error.expire_date}
                        value={var_add_unit.expire_date}
                        control={Input}
                        onChange={onChangeInput}

                    /> 
                </Form.Group>
                }
            </div>

            <div className="modal-footer">
                <Button disabled={!isChanged} type="submit" color="red">
                    Submit
                </Button>
            </div>
        </Form>
        </>
    )
}

