import React, { useEffect } from "react";
import { Route,Redirect } from "react-router-dom";

export default function AppRoute({ component: C, appProps,isAuth,redirect_url, ...rest }) {
    useEffect(()=>{
    },[])
    return (
        <Route  {...rest} render={props => 
               isAuth? <C {...props} {...appProps} />: <Redirect to={{pathname: redirect_url, state:{from:props.location}}} />
            } />
    );
}