import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Message } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { AppContext } from '../../../Context/AppContext';
import { getAllUsers, loginUser } from '../../../constants';
import logo from '../../../logo.png'

import './Login.css';



const Login=(appProps)=>{

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const { userInfo } = useContext(AppContext);
    const [ ,setUser ] = userInfo;
    const [ password,setPassword ] = useState()
    const [ email,setEmail ] = useState()
    const[validation_error,setValidationError]=useState();
    const[isError,setISError]=useState();
    const homescreen = useHistory();


    /* Async functions
	---------------------------------------------------------------------------------*/


    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
		
	}, [])



    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }

    function validate(){
        if(!email){
            setValidationError("Please write valid email");
            setISError(true);
            return false;
        }
        else if(!password){
            setValidationError("Please write password");
            setISError(true);
            return false;
        }
        else{
            setISError(false);
            return true;
        }

    }

    const onSubmit = async() => {
        if(validate()){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({email, password, is_role:"admin"})
            };
            await fetch(loginUser,requestOptions)
            .then(response=>response.json())
            .then(async (res)=>{
                if(res.code===200){
                    setISError(false);
                    const{user_id,token}=res.data;
                    await fetch(getAllUsers+`/${user_id}`)
                    .then(response=>response.json())
                    .then(res=>{
                        if(res.code===200){
                            const{fName,lName,id,number,adds,building_count,company,email,role}=res.data[0];
                        setUser({
                                    firstName:fName,
                                    lastName:lName,
                                    email:email,
                                    mobileNumber:number,
                                    role:role,
                                    uuid:id,
                                    company,
                                    building_count,
                                    address:adds,
                                    user_id,
                                    token
                                })
                                //Run route
                                homescreen.replace('/dashboard')
                                localStorage.setItem("user",JSON.stringify({
                                    firstName:fName,
                                    lastName:lName,
                                    email:email,
                                    mobileNumber:number,
                                    role:role,
                                    uuid:id,
                                    company,
                                    building_count,
                                    address:adds,
                                    user_id,
                                    token
                                }));
                            }
                            else
                            {
                                setValidationError("Error while getting user");
                                setISError(true);
                            }
                    })
                    .catch(error=>{
                        console.error("Error:",error);
                    })
                }
                else
                {
                    const{success,failed}=res;
                    if(success)
                    {
                        setValidationError(success)
                        setISError(true);
                    }
                    else if(failed)
                    {
                        setValidationError(failed)
                        setISError(true);
                    }
                }

            })
            .catch(error=>{
                console.error("Error:",error);
            })
        }
    }


    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return(
        <div className="login-wrapper">
            <div className="login-card">
                <div className="login-card-header">
                    <img src={logo} alt="PestPac API" />
                </div>
                <div className="loing-card-body">
                    <Form error={isError} className="login-form" onSubmit={()=> onSubmit()}>
                        <Form.Group>
                            <Form.Field 
                                id='email'
                                name='email'
                                label='Email'
                                control={Input}
                                onChange={onChangeEmail}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field 
                                id='password'
                                name='password'
                                label='Password'
                                control={Input}
                                type="password"
                                onChange={onChangePassword}
                            />
                        </Form.Group>
                        <Message
                            error
                            header='Error'
                            content={validation_error}
                            />
                        <Button className="button-form-submit" color="red" type="submit">Login</Button>
                    </Form>
                </div>
            </div>
        </div>
    )

}
export default Login;