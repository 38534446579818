import React, { useContext, useEffect, useState } from 'react';
import {  Modal } from 'semantic-ui-react';
import dateFormat from 'dateformat';
import MDL_APPROVE_PIN from './mdl-approve-pin/mdl-approve-pin';
import MDL_SUCCESS from '../../../components/cmp-success/cmp-success'


import './pin-management.css';
import { changeResetStatus, getAllRestPinRequests } from '../../../constants';
import { AppContext } from '../../../Context/AppContext';
import { useToasts } from 'react-toast-notifications';


function PinManagement() {


    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const{userInfo}=useContext(AppContext)
    const[user,setUser]=userInfo;
    const { addToast } = useToasts();
    const [ var_mdl_approvepin_open, set_mdl_approvepin_open ] = useState(false);
    const [ var_mdl_success, set_mdl_success ] = useState(false);
    const [ selectedInstance,  ] = useState();
    const [ pinRequest, setPinRequest ] = useState([]);
    const [ success_message, setSuccessMessage ] = useState();



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        getAllPinRequests();
	},[])



    /* ASYNC FUNCTIOS
	---------------------------------------------------------------------------------*/
    async function getAllPinRequests(){
        await fetch(getAllRestPinRequests)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200){
                setPinRequest(res.data);
            }
            else{
                setPinRequest([]);
            }
        }).catch(error=>{
            console.log("Error fetching pins:",error);
        })
    }

    

    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    // const onOpen_mdl_approvePin = (instance) => {
    //     setSelectedInstance(instance);
    //     set_mdl_approvepin_open(true)
    // }

    // const on_pin_disapprove = async (instance) => {
    //     
    //     await setSelectedInstance(instance);
    //     updatePinStatus(2,"");
    // }

    const on_approve_function = (reset_status,building_id) => {
        updatePinStatus(reset_status,building_id)
    }

    async function updatePinStatus(reset_status,building_id){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
            body: JSON.stringify({ uuid:selectedInstance.uuid,reset_status,building_id,email:selectedInstance.question_two,user_id:user.user_id})};
        await fetch(changeResetStatus,requestOptions)
        .then(response=>response.json())
        .then(res=>{
            if(res.code && res.code === 401){
                addToast("Session expired. Please Login again.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
                //SessionExpire();
                localStorage.removeItem('user')
                setUser(null)
            }
            else{
            const{success}=res;
                if(res.code===200){
                    setSuccessMessage(success);
                    set_mdl_approvepin_open(false)
                    set_mdl_success(true);
                }
                else{

                }
            }
        })
        .catch(error=>{
            console.error("Error:",error);
        })
    }

    const success_function = () => {
        set_mdl_success(false);
        getAllPinRequests();
    }



    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return(
        <>
            <div className="app-content">     
                <div className="app-card">
                    <div className="app-card-header">
                        <div className="app-card-title">Pin Management</div>
                    </div>

                    <div className="pin-management-container">
                    {pinRequest.length > 0 &&
                        pinRequest.map((value,i)=>(
                            <div className="user-profile-card app-card">
                                <div className="user-profile-row">
                                    <div className="user-profile-col">
                                        <div className="user-profile-label">What is your name?</div>
                                        <div className="user-profile-info">{value.question_one}</div>
                                    </div>
                                    <div className="user-profile-col">
                                        <div className="user-profile-label">What is your company name?</div>
                                        <div className="user-profile-info">{value.question_two}</div>
                                    </div>
                                    <div className="user-profile-col">
                                        <div className="user-profile-label">What is your building address?</div>
                                        <div className="user-profile-info">{value.question_three}</div>
                                    </div>                                    
                                    <div className="user-profile-col">
                                        <div className="user-profile-label">Date & Time</div>
                                        <div className="user-profile-info">
                                        <div className="pin-date-and-time">{dateFormat(value.updated_at, "mmmm dS, yyyy HH:mm")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
     
                        ))
                    }
                    {pinRequest.length === 0 &&
                        <div className="app-card">
                            <div className="nodata-placeholder">
                                There is no data to display
                            </div>
                        </div>
                    }
                </div>
                </div>
            </div>


            {/***** MODAL: RESET PIN **************************************************************************/}
            <Modal id="mdl-approve-pin"
                   dimmer={'inverted'}
                   open={var_mdl_approvepin_open}
                   onClose={() => set_mdl_approvepin_open(false)}>
                <MDL_APPROVE_PIN
                    set_mdl_open={set_mdl_approvepin_open}
                    on_approve_function={on_approve_function}
                />
            </Modal>

            {/***** END MODAL: RESET PIN **************************************************************************/}

            {/***** MODAL: SUCCESS **************************************************************************/}
            <Modal id="mdl-success"
                dimmer={'inverted'}
                open={var_mdl_success}
                onClose={() => set_mdl_success(false)}>
                <MDL_SUCCESS
                    set_mdl_open = {set_mdl_success}
                    var_modaltitle = "Success"
                    var_message = {success_message}
                    success_function={success_function}
                />
            </Modal>

            {/***** END MODAL: SUCCESS **************************************************************************/}


            
        </>
    )

}

export default PinManagement;