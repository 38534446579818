import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import './cmp-not-found.css';


const NotFound = () => {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const history = useHistory();


    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/




    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="not-found-container">
                <div className="not-found-holder">
                    <div className="not-found-404">404 :(</div>
                    <div className="not-found-message">
                        <div className="message-text">Sorry but the page you are looking for doesn’t exist</div>
                    </div>
                    <div className="not-found-back-button">
                        <Button color="red" onClick={history.goBack}>Go Back</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound
