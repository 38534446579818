import React, {useState, useEffect} from 'react'
import { Icon, Button, Form } from 'semantic-ui-react';
import { getAllBuildings } from '../../../../constants';
import './mdl-approve-pin.css'

export default function MDL_APPROVE_PIN({set_mdl_open, on_approve_function}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const [ buildings,setBuildings ] = useState([]);
    const[deletedUUID,setDeletedUUI]=useState();
    const[btnDisable,setButtonDisable]=useState(true);

    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        getBuildings()
	}, [])
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

    async function getBuildings(){
        await fetch(getAllBuildings)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200){
                var bData=res.data;
                if(bData){
                    bData.map((value,index)=>{
                        var temp={...value};
                        temp={...temp,key:value.uuid};
                        temp={...temp,text:value.building_name};
                        temp={...temp,value:value.uuid};
                        bData[index]=temp;
                    })
                    setBuildings(bData);
                }
            }
        })
    }
    const resetPinSelectedBuilding=(e,{value})=>{
        setDeletedUUI(value);
        setButtonDisable(false);
    }

    const submitPinResetApprove=()=>{
        if(deletedUUID){
            on_approve_function(1,deletedUUID);
        }
    }

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form method="POST" onSubmit={submitPinResetApprove}>
            <div className="modal-header">
                <div className="modal-header-title">Reset Password</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                
                <Form.Group>
                    <Form.Select
                        label="Please select your building to reset password."
                        placeholder='Select building'
                        fluid
                        required
                        options={buildings}
                        onChange={resetPinSelectedBuilding}
                    />
                </Form.Group>
                    
            </div>

            <div className="modal-footer">
                <Button disabled={btnDisable} type="submit" color="red">
                    Reset
                </Button>
            </div>
        </Form>
        </>
    )
}

