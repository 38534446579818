import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import './view-customer.css'


const ViewCustomer = () => {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const history=useHistory();
    const[data,setData]=useState();


    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        if(history.location.state){
            setData(history.location.state.data)
            
        }else{
            history.goBack();
        }
	},[])



    /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/



    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/



    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="app-content">
                <div className="app-view-customer">
                    <div className="app-backto-prev-page">
                        <div className="app-goback-button" onClick={history.goBack}><Icon name="arrow left" /> Go Back</div>
                    </div>
                    <div className="app-card">
                        <div className="app-card-header">
                            <div className="app-card-title">Customer Management</div>
                        </div>
                        <div className="view-customer-section">
                            <div className="view-customer-row">
                                <div className="view-customer-manage-label">Customer:</div>
                                <div className="view-customer-manage-field">{data && data.first_name} {data && data.last_name}</div>
                            </div>
                            <div className="view-customer-row">
                                <div className="view-customer-manage-label">Company:</div>
                                <div className="view-customer-manage-field">{data && data.company}</div>
                            </div>
                            <div className="view-customer-row">
                                <div className="view-customer-manage-label">Email:</div>
                                <div className="view-customer-manage-field">{data && data.email}</div>
                            </div>
                            <div className="view-customer-row">
                                <div className="view-customer-manage-label">Contact #:</div>
                                <div className="view-customer-manage-field">{data && data.contact_number}</div>
                            </div>
                            <div className="view-customer-row">
                                <div className="view-customer-manage-label">Buildings:</div>
                                <div className="view-customer-manage-field">{data && data.customer_building_count}</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewCustomer
