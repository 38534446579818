import React, {useState, useEffect,useContext} from 'react'
import { Icon, Button,Form,Input, Dropdown,Modal } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import {useToasts } from 'react-toast-notifications';
import { AppContext } from '../../../../../Context/AppContext';
import moment from "moment";
import MDL_PROGRESS from "../../../../../components/cmp-progress/cmp-progress";  
import {createBuildingNotes } from "../../../../../constants";


const MDL_ADD_NOTE = ({set_mdl_open,buildingID,setPage}) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const{userInfo}=useContext(AppContext);
  const[user]=userInfo;
  const [errors, set_errors] = useState({});
  const { addToast } = useToasts();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [ var_note_info, set_note_info ] = useState([])
  const [SelectedType, setSelectedTypeValue] = useState("");
  const [TypeOptions] = useState([{value:"ALERT",text:"Alert"},{value:"OFFICE",text:"Office"}]);

   /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/



  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/


function isValidated() {
    var temp_error = { ...errors };
    var isValid = true;
    
    if(var_note_info?.notes?.length > 500){
      temp_error.note = "Note Character limit exceeded.";
      isValid = false;
    }
    if (!var_note_info || !var_note_info.hasOwnProperty('notes') || var_note_info.notes === "" || var_note_info.length === 0) {
      temp_error.notes = "Please add notes.";
      isValid = false;
    }
    if(!SelectedType){
      temp_error.type_options = "Please select note type.";
      isValid = false;
    }
    if (isValid) {
      set_errors({});
    } else {
      set_errors(temp_error);
    }
    return isValid;
  }



async function handleNoteDetails(){
    if(isValidated()){
      set_mdl_progress(true)
      var_note_info.note_type = SelectedType;
      var_note_info.buildingID =buildingID;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
            body: JSON.stringify(var_note_info)
        };
        await fetch(createBuildingNotes, requestOptions)
        .then(response => response.json())
        .then(res=>{
            set_mdl_open(false)
            if(res.code && res.code === 401){
                addToast("Session expired. Please Login again.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            }
            else{
                if(res.code===200){
                    addToast(res.success, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                }
                else{
                    addToast(res.success, {
                        appearance: 'error',
                        autoDismiss: true,
                    })  
                }

            }
            setPage(1);
        }).catch(err=>{
            console.error("Error:",err);
        });
    }
    set_mdl_progress(false)
}



const onChangeInput = (event) => {
  let name = event.target.name;
  let value = event.target.value;

  // Check if the value exceeds 500 characters
  if (value.length > 500) {
    // Set error for exceeding character limit
    set_errors((prevErrors) => ({
      ...prevErrors,
      [name]: 'Character limit exceeded',
    }));
  } else {
    // Clear the error if within limit
    set_errors({});
  }

    set_note_info({ ...var_note_info, [name]: value });
};

function onChangeType(event, { value }) {
  set_errors({});
  setSelectedTypeValue(value);
}
  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
        <div className="modal-header">
            <div className="modal-header-title">Add Note Details</div>
            <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                <Icon name="times circle outline" />
            </div>
        </div>
        <div className="modal-content  fields">
              <label htmlFor="sortby_options">Type: </label>
              <Dropdown
                search
                selection
                className="type_options"
                id="type_options"
                name="type_options"
                placeholder="Select Type"
                label="Type: "
                onChange={onChangeType}
                value={SelectedType}
                options={TypeOptions}
                error={errors["type_options"]}
              />
                <label htmlFor="sortby_options">Note: </label>
                <Form.Field>
                <Form.TextArea
                    id='notes'
                    style={{width:'100%',height: '200px'}}
                    name='notes'
                    value={var_note_info?.notes}
                    onChange={onChangeInput}
                    error={errors["notes"]}
                />
                <div
                  style={{
                    textAlign: 'right',
                    color: var_note_info?.notes?.length > 500 ? 'red' : 'gray',
                  }}
                >
                  {errors["notes"] ? 0 : 500 - (var_note_info?.notes?.length || 0 )} characters left
                </div>
                {/* <label htmlFor="sortby_options">Expiry Date: </label>
                <SemanticDatepicker
                style={{width:'100%'}}
                id="expiryDate"
                name="expiryDate"
                placeholder={'Expiry Date'}
                fluid
                value={expiryDate}
                error={errors?.expiryDate}
                onChange={onChange}
                /> */}
                </Form.Field>
        </div>
        <div className="modal-footer">
            <Button type="submit" color="red" onClick={handleNoteDetails} disabled={var_mdl_progress} loading={var_mdl_progress}>
             Add
            </Button>
        </div>
        {/***** MODAL: PROGRESS **************************************************************************/}
        <Modal
            id="mdl-progress"
            dimmer={"inverted"}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}
          >
            <MDL_PROGRESS
              set_mdl_open={set_mdl_progress}
              var_modaltitle="Please wait"
              var_message={"Stay on this page until this process finish."}
            ></MDL_PROGRESS>
          </Modal>

        {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
)
};
export default MDL_ADD_NOTE;
