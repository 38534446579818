import React, { useContext, useEffect, useState } from "react";
import { Table, Modal, Search, Checkbox, Label } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";

import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import MDL_UPDATE_SETTINGS from "./update-settings/update-settings";
import { getAllUsers, getSettingsCustomersList } from "../../../constants";
import AppPagination from "../../../components/cmp-pagination/Pagination";

import "./SettingsManagement.css";
import { AppContext } from "../../../Context/AppContext";

function SettingsManagement() {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { usersData, userInfo } = useContext(AppContext);
  const [, setUsers] = usersData;
  const [searchQuery, setSearchQuery] = useState("");
  const [customers, setCustomers] = useState([]);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [TotalSettings, setTotal] = useState(0);
  const [updateAble, setUpdateAble] = useState({
    isShown: false,
    data: {},
  });

  const { addToast } = useToasts();

  useEffect(() => {
    getListOfCustomers(page, limit,searchQuery);
  }, [limit, page]);

  useEffect(() => {
    if (searchQuery) {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
     
      getListOfCustomers(0, limit, searchQuery);
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  } else {
    getListOfCustomers();
  }
  }, [searchQuery]);

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  async function getListOfCustomers(page_no, limit_range, q){
    var query_params = "";
    if (q) {
      query_params = `?q=${q}&page=${page_no ? page_no : page}&limit=${
        limit_range ? limit_range : limit
      }`;
    } else {
      query_params = `?page=${page_no ? page_no : page}&limit=${
        limit_range ? limit_range : limit
      }`;
    }
    set_mdl_progress(true);
    let api_url =
      getSettingsCustomersList + query_params+`&order=desc`;

    if (searchQuery) {
      api_url += `&search=${searchQuery}`;
    }

    await fetch(api_url)
      .then((response) => response.json())
      .then((res) => {
        var SettingsFetch = [];
          setTotal(res.totalCount);
         
          if (res.code === 200) {
            SettingsFetch = res.data;
            if (
              res.page_number > 1 &&
              res &&
              res?.length === 0
            ) {
              setPage(res.page_number - 1);
            }
          } else {
            SettingsFetch = [];
          }
          if ((page_no && page_no > 1)){
            SettingsFetch = [...customers, ...SettingsFetch];
          }else{
            setCustomers([]);
          }
          setCustomers(SettingsFetch);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    set_mdl_progress(false);
  };

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

  function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
    setPage(1);
    setSearchQuery(value);
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <div className="app-content">
        <div className="app-building-container">
          <div className="app-card">
            <div className="app-card-header building-managment-header-card">
              <div className="app-card-title">
                Settings ({customers.length})
              </div>
              <div className="app-content-search">
                <Search
                  placeholder="Search customer"
                  onSearchChange={doSearch}
                  minCharacters={20000}
                />
              </div>
            </div>

            <div className="app-table-wrapper">
              <Table unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Customer Name</Table.HeaderCell>
                    <Table.HeaderCell>Confirmation Email</Table.HeaderCell>
                    <Table.HeaderCell>Mini. Service Charges</Table.HeaderCell>
                    <Table.HeaderCell>Report Reading</Table.HeaderCell>
                    <Table.HeaderCell>NOE</Table.HeaderCell>
                    <Table.HeaderCell>NOE(Doc)</Table.HeaderCell>
                    <Table.HeaderCell>French NOE</Table.HeaderCell>
                    <Table.HeaderCell>French Sheet</Table.HeaderCell>
                    <Table.HeaderCell>2nd BB</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {customers.length > 0 &&
                    customers.map((customer, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{customer.name}</Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            key={i}
                            checked={customer.confirmation_email}
                            onChange={() => {}}
                            toggle
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            key={i}
                            checked={customer.service_charge}
                            onChange={() => {}}
                            toggle
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            key={i}
                            checked={customer.report_reading}
                            onChange={() => {}}
                            toggle
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            key={i}
                            checked={customer.notice_of_entry}
                            onChange={() => {}}
                            toggle
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            key={i}
                            checked={customer.noe_doc}
                            onChange={() => {}}
                            toggle
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            key={i}
                            checked={customer.french_noe}
                            onChange={() => {}}
                            toggle
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            key={i}
                            checked={customer.french_prep_sheet}
                            onChange={() => {}}
                            toggle
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            key={i}
                            checked={customer.second_bedbug}
                            onChange={() => {}}
                            toggle
                          />
                        </Table.Cell>
                        <Table.Cell className="td-action">
                          <Label
                            color="green"
                            onClick={() => {
                              setUpdateAble({
                                isShown: true,
                                data: customer,
                              });
                            }}
                          >
                            Edit
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
              {customers.length === 0 && (
                <div className="nodata-placeholder">
                  There is no building to display
                </div>
              )}
            </div>
          </div>
          {customers.length !== TotalSettings && 
          <AppPagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            rowsTotal={customers.length}
          />
        }
        </div>
      </div>

      {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
      <Modal
        id="mdl-confirmation"
        dimmer={"inverted"}
        open={updateAble?.isShown}
        onClose={() => {}}
      >
        <MDL_UPDATE_SETTINGS
          setUpdateAbleData={setUpdateAble}
          updateAbleData={updateAble}
          refreshList={getListOfCustomers}
        ></MDL_UPDATE_SETTINGS>
      </Modal>

      {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}

      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Stay on this page until this process finish."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
  );
}

export default SettingsManagement;
