import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { orderDetailsGet, viewTechDetailsGet } from "../../../constants";
import { AppContext } from "../../../Context/AppContext";

function ViewOrderDetailAdmin() {
  const { userInfo } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState();

  useEffect(() => {
    if (location.state && location.state.id) {
      getOrderDetails();
    } else {
      history.goBack();
    }
  }, []);

  async function getOrderDetails() {
    var id = location.state.id;
    var unit_number = location.state.unit_number;
    await fetch(
      user.role === "technician" || !unit_number
        ? viewTechDetailsGet + id
        : orderDetailsGet + "?id=" + id + "&unit_number=" + unit_number
    )
      .then((resposnse) => resposnse.json())
      .then((res) => {
        if (res.code === 200) {
          setData(res.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="app-content">
      <div className="order-review-contaienr">
        <div className="order-review-header">
          <div className="order-review-title">Service Request Details</div>
          <div className="order-review-subtitle">
            <span>Location:</span>
            <span>{data ? data.building_name : "No name"}</span>
          </div>
          <div className="order-review-subtitle">
            <span>Submission Date &amp; Time: </span>
            <span>{data ? data.order_date_time : "No date"}</span>
          </div>
          {data && data.building_po_mo_wo && (
            <div className="order-review-subtitle">
              <span>
                {data.po_mo_wo.toString().toLowerCase() === "po"
                  ? "Purchase Order Number"
                  : data.po_mo_wo.toString().toLowerCase() === "mo"
                  ? "Maintenance Order Number"
                  : "Work Order Number"}
              </span>
              <span>
                {data.purchase_order_number ? data.purchase_order_number : 0}
              </span>
            </div>
          )}
          <div className="order-review-subtitle">
            <span>Status: </span>
            <span>
              {data
                ? toTitleCase(data.order_status)
                : "No Status"}
            </span>
          </div>
          <div className="order-review-subtitle">
            <span>Scheduled Date: </span>
            <span>
              {data && data.schedule_date ? data.schedule_date : "N/A"}
            </span>
          </div>
          {/* <div className="order-review-subtitle">
                        <span>Scheduled Time: </span>
                        <span>{data && data.time_range ? data.time_range :"N/A"}</span>
                    </div> */}
        </div>
        {data &&
          data.units.map((vals) => (
            <div className="order-review-unit">
              <div className="app-card">
                <div className="unit-detail-row">
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Unit #</div>
                    <div className="unit-detail-value">{vals.unit_number}</div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Pest Requested</div>
                    <div className="unit-detail-value">
                      {vals.pest_name ? vals.pest_name : "No name"}
                    </div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Treatment Requested</div>
                    <div className="unit-detail-value">
                      {vals.treatment_name ? vals.treatment_name : "No name"}
                    </div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Type of Request</div>
                    <div className="unit-detail-value">
                    {vals.follow_up === 1
                        ? "Follow-Up"
                        : vals.isFollowupMonthly
                        ? "Follow-Up (Service In Month)"
                        : vals.isImmediate
                        ? "Follow-Up (Immediate)"
                        : vals.is_reschedule
                        ? "Reschedule"
                        : "New"}
                    </div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Notes</div>
                    <div className="unit-detail-value">
                      {vals.note ? vals.note : "No notes"}
                    </div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Time Window</div>
                    <div className="unit-detail-value">
                      {vals.time_range ? vals.time_range : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="page-form-submit">
          <div className="page-form-submit-button">
            <Button color="red" onClick={history.goBack}>
              Go Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewOrderDetailAdmin;
