import React, {useEffect} from 'react'
import { Icon, Button, Table } from 'semantic-ui-react';
import './mdl-upload-bulk-building-result.css'

export default function MDL_UPLOAD_BUILDING({set_mdl_open, onChangeEvent, set_mdl_upload_bulk, data}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
	}, [])
    
    
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/


    const upload_again=()=>{
        set_mdl_open(false);
        set_mdl_upload_bulk(true);
    }

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">Bulk Upload Results</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                <div className="bulk-upload-result-card">
                    <ul>
                        <li className="result-totla">
                            <div className="result-card-label">Total Buildings: </div>
                            <div className="result-card-result">{data.buildings}</div>
                        </li>
                        <li className="result-totla">
                            <div className="result-card-label">Total Units: </div>
                            <div className="result-card-result">{data.units}</div>
                        </li>
                        <li className="result-uploaded">
                            <div className="result-card-label">Uploaded Buildings: </div>
                            <div className="result-card-result">{data.valid_buildings}</div>
                        </li>
                        <li className="result-uploaded">
                            <div className="result-card-label">Uploaded Units: </div>
                            <div className="result-card-result">{data.valid_units}</div>
                        </li>
                        {
                        data.building_error >0 && 
                        <li className="result-error">
                            <div className="result-card-label">Building Errors: </div>
                            <div className="result-card-result">{data.building_error}</div>
                        </li>
                        }
                        {data.error_units >0 && 
                        <li className="result-error">
                            <div className="result-card-label">Unit(s) Errors: </div>
                            <div className="result-card-result">{data.error_units}</div>
                        </li> }
                    </ul>
                </div>
                {data.error_units > 0 || data.building_error > 0 ?
                <div className="app-table-wrapper">
                    <Table unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Sheet</Table.HeaderCell>
                                <Table.HeaderCell>Row</Table.HeaderCell>
                                <Table.HeaderCell>Column</Table.HeaderCell>
                                <Table.HeaderCell>Error</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                data.front_error_logs.map((val,ind)=>(
                                    <Table.Row key={ind}>
                                    <Table.Cell>{val.sheet}</Table.Cell>
                                    <Table.Cell>{val.row}</Table.Cell>
                                    <Table.Cell>{val.column_name}</Table.Cell>
                                    <Table.Cell>{val.reason}</Table.Cell>
                            </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table>
                </div>:null}

            </div>
            {
            data.error_units > 0 || data.building_error >0 ?
            <div className="modal-footer">
                <Button type="button" color="red" onClick={upload_again}>
                    Upload again
                </Button>
            </div>
            :null
            }
        </>
    )
}

