import React, { useState, useEffect, useContext } from "react";
import {
  Icon,
  Button,
  Form,
  Input,
  Message,
} from "semantic-ui-react";
import { portalDocuments } from "../../../../constants";
import { AppContext } from "../../../../Context/AppContext";
import { useToasts } from "react-toast-notifications";
import dateFormat from "dateformat";
import "./mdl-create-folder.css";

export default function MDL_CREATE_FOLDER({
  set_mdl_open,
  update_folder_list,
}) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/

  const { userInfo } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [var_create_folder, set_create_folder] = useState({});
  const { addToast } = useToasts();

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

  //async function -------------------------------------------------------------------

  const submitCreateFolder = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify({
        title: var_create_folder.title.trim(),
        description: var_create_folder.description,
      }),
    };
    await fetch(`${portalDocuments}/folder`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          localStorage.removeItem("user");
          setUser(null);
        } else {
          if (res.code === 200) {
            set_mdl_open(false);
            update_folder_list(true);
            addToast(res.success, {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(res.success, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    const { name, value } = event.target;
    set_create_folder({ ...var_create_folder, [name]: value });
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <Form method="POST" onSubmit={submitCreateFolder}>
        <div className="modal-header">
          <div className="modal-header-title">
            <span>
              <img
                src="/folder-icon.png"
                alt="pdf"
                style={{ width: "32px", height: "32px" }}
              />
              <span> Create Folder</span>
            </span>
          </div>
          <div
            className="modal-header-close"
            onClick={() => set_mdl_open(false)}
          >
            <Icon name="times circle outline" />
          </div>
        </div>
        <div className="modal-content">
          <Form.Group>
            <Form.Field
              id="title"
              name="title"
              label="Folder Title"
              type="text"
              value={var_create_folder.title}
              required
              control={Input}
              onChange={onChangeInput}
            />
          </Form.Group>
        </div>
        <Message
          error
          header="Error"
          // content={form_validation_error.error}
        />
        <div className="modal-footer">
          <Button type="submit" color="red" disabled={var_create_folder?.title?.length < 3 }>
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}
