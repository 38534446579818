import React, {useState, useEffect, useContext} from 'react'
import { Icon, Button, Form, Input, Message } from 'semantic-ui-react';
import { updatePassword } from '../../constants';
import { AppContext } from '../../Context/AppContext';
import './mdl-scheduler-update-password.css'
import { useToasts } from 'react-toast-notifications';
const { passwordStrength } = require('check-password-strength')

export default function MDL_SCHEDULER_UPDATE_PASSWORD({set_mdl_open}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const{userInfo}=useContext(AppContext);
    const[submitForm,setSubmitForm]=useState(false);
    const[user,setUser]=userInfo;
    const[isError,setIsError]=useState();
    const[validation_error,setValidationError]=useState()
    const { addToast } = useToasts();
   
    const [ var_update_password, set_update_password ] = useState()

    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(()=>{
        if(var_update_password){
            const{old_password,new_password}=var_update_password;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json','Authorization': "Token "+user.token },
                body: JSON.stringify({old_password,new_password,user_id:user.user_id})};
            fetch(updatePassword+`/${user.uuid}`, requestOptions)
            .then(response=>response.json())
            .then(res=>{
                if(res.code && res.code === 401){
                    set_mdl_open(false);
                    addToast("Session expired. Please Login again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                    //SessionExpire();
                    localStorage.removeItem('user')
                    setUser(null)
                }else{
                    const{success}=res.body;
                    if(res.statusCode===200){
                        set_mdl_open(false);
                        addToast(success, {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                    }
                    else{
                        setIsError(true);
                        setValidationError(success);
                    }
                }
            }).catch(error=>{
                console.log(error);

            });
        }
    },[submitForm])
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setIsError(false);
        set_update_password({...var_update_password, [name]: value});
    }
    
    const postCustomerData = async (e) => {
        e.preventDefault();
        if(var_update_password){
            const{new_password,old_password,confirm_new_password}=var_update_password;
            if(!new_password){
                setError(true,"Please enter your new password");
            }
            else if(!old_password){
                setError(true,"Please enter your old password");
            }
            else if(!confirm_new_password){
                setError(true,"Please retype new password");
            }
            else if(passwordStrength(new_password).id<1){
                setError(true,"Please use strong password.");
            }
            else if(new_password!==confirm_new_password){
                setError(true,"Please use same password in retype field.");
            }
            else{
                setSubmitForm(!submitForm);
            }
        }
    }
    function setError(show,message){
        setIsError(show);
        setValidationError(message);
    }
    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form method="POST" error={isError}>
            <div className="modal-header">
                <div className="modal-header-title">Update Password</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                
                    <Form.Group>
                        <Form.Field
                            id='old_password'
                            name='old_password'
                            label='Old Password'
                            control={Input}
                            type="password"
                            required
                            onChange={onChangeInput}
                            placeholder='Write old password'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            id='new_password'
                            name='new_password'
                            label='New Pasword'
                            type="password"
                            control={Input}
                            required
                            onChange={onChangeInput}
                            placeholder='Write new password'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            id='confirm_new_password'
                            name='confirm_new_password'
                            label='Confirm Password'
                            type="password"
                            control={Input}
                            required
                            onChange={onChangeInput}
                            placeholder='Write confirm password'
                        />
                    </Form.Group>
            </div>

            <Message
                    error
                    header='Error'
                    content={validation_error}
                    />

            <div className="modal-footer">
                <Button type="submit" color="red" onClick={postCustomerData}>
                    Update Password
                </Button>
            </div>
        </Form>
        </>
    )
}

