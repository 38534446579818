import React, {useState, useEffect, useContext} from 'react'
import { Icon, Button, Dropdown, Modal } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from "moment";
import './UpdateWarrantyDetails.css'
import MDL_CONFIRMATION from "../../../../components/cmp-confirmation/cmp-confirmation";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "../../../../Context/AppContext";
import {getPestLookup,getTreatmentDetailLookup,putWarranty,deleteWarranty } from '../../../../constants';

export default function MDL_UPDDATE_WARRANTY_DETAILS({WarrantyItemData,set_mdl_open,units_array}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const {userInfo } = useContext(AppContext);
    const [user, ] = userInfo;
    const [ pestData, setpestData ] = useState([]);
    const [treatmentData, setTreatmentData] = useState([]);
    const [SelectedpestData, setSelectedpestData] = useState([]);
    const [SelectedtreatmentData, setSelectedtreatmentData] = useState([]);
    const [ServiceArr, setServiceArr] = useState([]);
    const [currentDate, setNewDate] = useState(
      WarrantyItemData.is_on_pest === 1 && WarrantyItemData.is_on_service === 0 ? moment(WarrantyItemData.warranty_date).toDate() : WarrantyItemData.is_on_pest === 0 && WarrantyItemData.is_on_service === 1 ? moment(WarrantyItemData.warranty_date_service).toDate() : moment(WarrantyItemData.warranty_date).toDate()
    );
    const [error_pest_message, set_error_pest_message] = useState("");
    const [error_service_message, set_error_service_message] = useState("");
    const [errors, set_errors] = useState({});
    const { addToast } = useToasts();
    const [WarrantyIndex, setWarrantyIndex] = useState();
    const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);
    const [selectedUnits, setSelectedUnits] = useState(null);
    

    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        pestLookup()
        treatmentLookup()
	}, [])
  
  useEffect(() => {
    const unit = units_array.find(item => item.text === WarrantyItemData.unit.toString());
    if (unit) {
      setSelectedUnits(unit.value); // Set the default value
    }
    const initialPests = [WarrantyItemData.warranty_pest, WarrantyItemData.warranty_pest_2 ,WarrantyItemData.warranty_pest_3,WarrantyItemData.warranty_pest_4]
    .filter(pest => pest)
    .map(pest => {
      const pestItem = pestData.find(p => p.text === pest.trim());
      return pestItem ? pestItem.value : null;
    })
    .filter(pestValue => pestValue);
    
    setSelectedpestData(initialPests);

    const initialService = [WarrantyItemData.warranty_service, WarrantyItemData.warranty_service_2 ,WarrantyItemData.warranty_service_3,WarrantyItemData.warranty_service_4]
    .filter(service => service)
    .map(service => {
      const serviceItem = ServiceArr.find(p => p.text === service.trim());
      return serviceItem ? serviceItem.value : null;
    })
    .filter(serviceValue => serviceValue);
    setSelectedtreatmentData(initialService);

  }, [WarrantyItemData,pestData,treatmentData])

  useEffect(() => {
 
}, [])

    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const putWarrantyData = async (e) => {
        // set_mdl_progress(true);
  
         const requestWarranty = {
           method: "PUT",
           headers: {
             "Content-Type": "application/json",
             Authorization: "Token " + user.token,
           },
           body: JSON.stringify({
             warranty_pest:SelectedpestData,
             warranty_service: SelectedtreatmentData,
             warranty_date: moment(currentDate).format("YYYY-MM-DD"),
           }),
         };
         await fetch(putWarranty+WarrantyItemData.uuid, requestWarranty)
           .then((response) => response.json())
           .then((res) => {
             if (res.code == 200) {
               addToast(res.success, {
                 appearance: "success",
                 autoDismiss: true,
               });
               set_mdl_open(false);
             }else {
               addToast(res.success, {
                 appearance: "error",
                 autoDismiss: true,
               });
             }
           }).catch((error) => {
             console.log("Errors:", error);
           });
       };
    async function pestLookup(){
        await fetch(getPestLookup)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200){
                setpestData(res?.data);
            }
            else{
                setpestData(null)
            }
        })
        .catch(error=>{
            setpestData(null);
            console.error("Error:",error);
        })
    }

    async function treatmentLookup(){
        await fetch(getTreatmentDetailLookup)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200){
              const formattedServices = res.data.map(service => ({
                value: service.value,
                text: `${service.text} (${service.pest_name} ${service.treatment_name})`
              }));
              setServiceArr(res?.data)
                setTreatmentData(formattedServices);
            }
            else{
                setTreatmentData(null)
            }
        })
        .catch(error=>{
            setTreatmentData(null);
            console.error("Error:",error);
        })
    }
    const convertDateFormat = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString();
    };

    function isValidated() {
        var temp_error = { ...errors };
        var isValid = true;
        if (!currentDate) {
          temp_error.currentDate = "Please select date.";
          isValid = false;
        }
        if (selectedUnits.length == 0 && selectedUnits.length == 0) {
          temp_error.buildingunits = "Please select units.";
          isValid = false;
        }
        if (SelectedpestData.length == 0 &&  SelectedtreatmentData.length == 0) {
          //temp_error.warrantypest = "Please select one option .";
          set_error_pest_message("Please select one option from pest or treatment.")
            isValid = false;
        }else if(SelectedpestData.length > 4){
          temp_error.warrantypest = "Please select only four pest.";
          set_error_pest_message("Please select only four pest.")
          isValid = false;
        }

        if(SelectedtreatmentData){
          if(SelectedtreatmentData.length > 4){
            temp_error.warrantyservice = "Please select only four service.";
            set_error_service_message("Please select only four service.")
            isValid = false;
          }

        }
    
        if (isValid) {
          set_errors({});
        } else {
          set_errors(temp_error);
        }
        return isValid;
      }

    // function onChangeUnits(event, { value }) {
    //     var temp_error = { ...errors };
    //     temp_error.buildingunits = null;
    //     set_errors(temp_error);
    //     const filteredArray = units_array.filter(unit => value.includes(unit.value));
    //     setBuildingUnits(filteredArray);
    // }

    const onChangeUnits = (e, { value }) => {
      setSelectedUnits(value);
    };

    function onChangePest(event, { value }) {
        var temp_error = { ...errors };
        temp_error.warrantypest = null;
        set_errors(temp_error);
        set_error_pest_message("")
       // const filteredPest = pestData.filter((f_v) => value.includes(f_v.value));
        setSelectedpestData(value);
    }
    function onChangeTreatment(event, { value }) {
        set_error_service_message("")
        //const filteredTreatment = treatmentData.filter((f_v) => value.includes(f_v.value));
        setSelectedtreatmentData(value);
    }
    
    function handleUpdateWarrantyDetails(){
        if(isValidated()){
          putWarrantyData()
        }
    }

    const onChange = (event, data) => {
    if (data?.value) {
        var temp_error = { ...errors };
        temp_error.currentDate = null;
        set_errors(temp_error);
        setNewDate(moment(data?.value).toDate());
    }
    };

    async function delete_warranty() {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      var apiUrl = deleteWarranty + `${WarrantyIndex}`;
      await fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          const { success, code } = res;
          if (code === 200) {
            addToast(success, {
              appearance: "success",
              autoDismiss: true,
            });
            
          } else {
            addToast(success, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
        set_mdl_open(false);
    }

    const onOpen_mdl_confirmation = (index) => {
      setWarrantyIndex(index);
      set_mdl_confirmation(true);
    };
    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">Update Warranty Details</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content  fields">
                    <Dropdown
                      search
                      style={{ width: '100%' }}
                      selection
                      className="sortby_options"
                      id="sortby_options"
                      name="sortby_options"
                      placeholder="Select Units"
                      label="Units: "
                      onChange={onChangeUnits}
                      value={selectedUnits}
                      options={units_array}
                      disabled={true}
                    />
                    <SemanticDatepicker
                        style={{width:'100%'}}
                        id="date"
                        name="date"
                        placeholder={'Expiration Date'}
                        required={true}
                        fluid
                        value={currentDate}
                        error={errors?.currentDate}
                        onChange={onChange}
                        />
                     {pestData?.length > 0 && (
                    <Dropdown
                        search
                        style={{width:'100%'}}
                        selection
                        className="pest_options"
                        id="pest_options"
                        name="pest_options"
                        placeholder="Select Pest"
                        label="Pests: "
                        onChange={onChangePest}
                        error={errors.warrantypest}
                        value={SelectedpestData}
                        options={pestData}
                        multiple={true}
                    />
                    )}
                    {error_pest_message !== '' &&
                    <div>
                      <div className="ui error message">
                        {error_pest_message}
                      </div>
                    </div>
                    }
                     {treatmentData?.length > 0 && (
                    <Dropdown
                        search
                        style={{width:'100%'}}
                        selection
                        className="treatment_options"
                        id="treatment_options"
                        name="treatment_options"
                        placeholder="Select Treatment"
                        label="Treatments: "
                        onChange={onChangeTreatment}
                        value={SelectedtreatmentData}
                        options={treatmentData}
                        multiple={true}
                    />
                    )}
                    {error_service_message !== '' &&
                    <div>
                      <div className="ui error message">
                        {error_service_message}
                      </div>
                    </div>
                    }
                
            </div>
            <div className="modal-footer">
                <Button type="submit" color="red" onClick={() => onOpen_mdl_confirmation(WarrantyItemData.uuid)}>
                 Delete
                </Button>
                <Button type="submit" color="blue" onClick={handleUpdateWarrantyDetails}>
                 Update
                </Button>
            </div>
             {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
            <Modal
              id="mdl-confirmation"
              dimmer={"inverted"}
              open={var_mdl_confirmation}
              onClose={() => set_mdl_confirmation(false)}
            >
              <MDL_CONFIRMATION
                set_mdl_open={set_mdl_confirmation}
                var_modaltitle="Delete Warranty"
                var_message="Are you sure want to delete warranty?"
                confirmation_function={delete_warranty}
              ></MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}
        </>
    )
}

