import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Dropdown, Button, Radio, Modal, Message, Icon } from 'semantic-ui-react';
import MDL_ADD_UNIT from './mdl-add-unit/mdl-add-unit';
import MDL_BULK_UNIT from './mdl-upload-bulk-units/mdl-upload-bulk-units';
import MDL_SUCCESS from '../../../components/cmp-success/cmp-success'
import { useToasts } from 'react-toast-notifications';
import * as XLSX from "xlsx";
import { AppContext } from '../../../Context/AppContext';
import { addBulkUnit, deleteBuilding, registerBuilding } from '../../../constants';

import './add-building.css'

const AddBuilding = () => {


    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const {schedulersData,userInfo} = useContext(AppContext);

    const[user,setUser]=userInfo;
    const [ var_building_order, set_building_order ] = useState({});
    const [ var_mdl_addunit_open, set_mdl_addunit_open ] = useState();
    const [ var_mdl_bulkunit_open, set_mdl_bulkunit_open ] = useState();
    const [ var_mdl_success, set_mdl_success ] = useState(false);
    const [ units,setUnits ] = useState([]);
    const [ validation_error,setValidationError ] = useState();
    const [ isError,setISError] = useState();
    const [ var_add_building,set_var_add_building ]= useState({});
    const [ success_message,setSuccessMessage ] = useState();
    const [ schedulers, ] = schedulersData
    const [ createdBuildingId,setCreatedBuildingId ] = useState();
    const [ createdBuildingPin,setCreatedBuildingPin ] = useState();
    const history = useHistory();
    const { addToast } = useToasts();


    
    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

    useEffect(()=>{
        if(createdBuildingId)
        createBuildingUnits();
    },[createdBuildingId])

    async function createBuildingUnits(){
        const requestOptionsUnit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
            body: JSON.stringify({unit:units,user_id:user.user_id})
        };
        await fetch(addBulkUnit+`${createdBuildingId}`,requestOptionsUnit)
                    .then(response=>response.json())
                    .then(res=>{
                        if(res.code && res.code === 401){
                            addToast("Session expired. Please Login again.", {
                                appearance: 'error',
                                autoDismiss: true,
                            });
                            //SessionExpire();
                            localStorage.removeItem('user')
                            setUser(null)
                        }
                        else{
                            if(res.code===200){
                                setSuccessMessage("Building created successfully. Building pin is "+createdBuildingPin);
                                set_mdl_success(true);
                            }
                            else{
                                delete_building(createdBuildingId);
                                setValidationError("Some error aoccured while adding units. Try again.");
                                setISError(true);
                            }
                        }
                    })
                    .catch(error=>{
                        console.error("Error unit:",error);
                    });
    }

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);
    
          fileReader.onload = (e) => {
            const bufferArray = e.target.result;
    
            const wb = XLSX.read(bufferArray, { type: "buffer" });
    
            const wsname = wb.SheetNames[0];
    
            const ws = wb.Sheets[wsname];
    
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    
        promise.then((d) => {
            if(d && d[0]){
                if(d[0].unit && d[0].bedroom_count && d[0].town_house){
                    setUnits(d);
                    setISError(false);
                }
            }
            //setItems(d);
        });
      };

    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeBuildingOrder = (e, { value }) => {
        set_building_order({ value })
        var_add_building.building_po_mo_wo=value
    }

    const onOpen_mdl_addunit = () => {
        set_mdl_addunit_open(true);
    }

    // const onOpen_mdl_bulkunits = () => {
    //     set_mdl_bulkunit_open(true);
    // }

    const removeValue=(index)=>{
        var unit_array_copy = [...units];
        if(index>-1){
            unit_array_copy.splice(index,1);
            setUnits(unit_array_copy)
        }
    }

    const add_single_unit=(unit,beds,town_house,is_warranty,warranty_type,pest,treatment,expiry_date)=>{
        var unit_array_copy = [];
        if(unit && beds){
            unit_array_copy=[...units,{unit:unit,bedroom_count:beds,town_house:town_house?1:0,is_warranty,is_on_service:warranty_type==="service"?1:0,
            is_on_pest:warranty_type==="pest"?1:0,warranty_type,w_pest:pest,w_treatment:treatment,w_expirey_date:expiry_date}]
        }
        setUnits(unit_array_copy);
    }
    const onChangeScheduler=(e, { value })=>{
        var temp= {...var_add_building}
        temp.scheduler_id=value;
        set_var_add_building(temp);
        setISError(false)
    }

    const onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setISError(false);
        set_var_add_building({...var_add_building, [name]: value});
    }

    const success_function = () => {
        set_mdl_success(false);
        history.replace('/building-management');
    }

    function validate(){
        if(units.length===0){
            setValidationError("Units are missing");
            setISError(true);
            return false;
        }

        else if(!var_add_building.scheduler_id){
            setValidationError("Please select scheduler.");
            setISError(true);
            return false; 
        }
        else if(!var_add_building.TimeWindow1){
            setValidationError("Please write time window 1.");
            setISError(true);
            return false; 
        }
        else if(!var_add_building.TimeWindow2){
            setValidationError("Please write time window 2.");
            setISError(true);
            return false; 
        }
        else if(!var_add_building.location_id){
            setValidationError("Please write location code.");
            setISError(true);
            return false; 
        }
        else if(!var_add_building.building_name){
            setValidationError("Please write building name.");
            setISError(true);
            return false; 
        }
        else if(!var_add_building.customer_email){
            setValidationError("Please write building email.");
            setISError(true);
            return false; 
        }
        else if(!var_add_building.customer_name){
            setValidationError("Please write customer name.");
            setISError(true);
            return false; 
        }
        setISError(false);
        return true;
    }

    async function delete_building(deletedUUId){
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        };
        var apiUrl=deleteBuilding+`/${deletedUUId}`;
        await fetch(apiUrl,requestOptions)
        .then(response=>response.json())
        .then(res=>{
            const{success,code}=res;
            if(code===200){
                addToast(success, {
                    appearance: 'success',
                    autoDismiss: true,
                })
            }
            else{
                addToast(success, {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
            
        })
        .catch(error=>{
            console.error("Error:",error);
        })
    }

    const on_add_submit=async()=>{
        if(validate()){
            var temp={...var_add_building};
            temp.user_id=user.user_id;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
                body: JSON.stringify(temp)
            };
            //if(!createdBuildingId){
            await fetch(registerBuilding,requestOptions)
            .then(response=>response.json())
            .then(async (res)=>{
                if(res.code && res.code === 401){
                    addToast("Session expired. Please Login again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                    //SessionExpire();
                    localStorage.removeItem('user')
                    setUser(null)
                }
                else{
                    if(res.code===200){
                        const {building_id,pin} =res;
                        setCreatedBuildingPin(pin);
                        setCreatedBuildingId(building_id);
                    }
                    else{
                        addToast(res.success, { 
                            appearance: 'error',
                            autoDismiss: true,
                        })  
                    }
                }
            }).catch(error=>{
                console.error("Error:",error)
                addToast("Some error while adding building. Please try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            });
        }
    }
    /* RENDER APP
	---------------------------------------------------------------------------------*/

    return (
        <>
            <div className="app-content">
                <div className="add-building-container">
                    <div className="app-backto-prev-page">
                        <div className="app-goback-button" onClick={history.goBack}><Icon name="arrow left" /> Go Back</div>
                    </div>
                    <Form error={isError}>
                        <div className="app-card">
                            <div className="app-card-header">
                                <div className="app-card-title">Add Building</div>
                            </div>

                            <div className="app-card-body">
                                <Form.Group>
                                    <Form.Field
                                        label="Building Name"
                                        control={Input}
                                        name='building_name'
                                        onChange={onChangeInput}
                                        required
                                        placeholder='Write buidling name'
                                        
                                    />
                                    <Form.Field
                                        label="City"
                                        control={Input}
                                        placeholder='Write city'
                                        name='building_city'
                                        onChange={onChangeInput}
                                    />
                                    
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        label="Address"
                                        control={Input}
                                        placeholder='Write address'
                                        name='building_address'
                                        onChange={onChangeInput}
                                    />
                                    <Form.Field
                                        label="Building Code"
                                        required
                                        control={Input}
                                        placeholder='Write location code'
                                        name='location_id'
                                        onChange={onChangeInput}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Field
                                        label="Time Window One"
                                        control={Input}
                                        name='TimeWindow1'
                                        onChange={onChangeInput}
                                        required
                                        placeholder='Write time eg. ( 8am - 12pm )'
                                        
                                    />
                                    <Form.Field
                                        label="Time Window Second"
                                        control={Input}
                                        name='TimeWindow2'
                                        onChange={onChangeInput}
                                        required
                                        placeholder='Write time eg. ( 12pm - 5pm )'
                                        
                                    />
                                    
                                </Form.Group>
                                
                                <Form.Group>
                                <Form.Field
                                        label="Customer Name"
                                        required
                                        control={Input}
                                        placeholder='Write customer name'
                                        name='customer_name'
                                        onChange={onChangeInput}
                                    />
                                    <Form.Field
                                        label="Building Email"
                                        required
                                        control={Input}
                                        placeholder='Write building email (seprate by , )'
                                        name='customer_email'
                                        onChange={onChangeInput}
                                    />
                                    {/* <Form.Select
                                        label="Customer"
                                        placeholder='Select customer'
                                        options={users}
                                        required
                                        onChange={onChangeCustomer}

                                    /> */}
                                </Form.Group>
                                <Form.Group className="add-building-checkbox-group">
                                <Form.Select
                                        label="Scheduler"
                                        placeholder='Select scheduler'
                                        required
                                        onChange={onChangeScheduler}
                                        options={schedulers}
                                    />
                                    <Form.Field>
                                        <label>Building Order</label>
                                        <Radio
                                            label='Purchase Order'
                                            name='buildingOrder'
                                            value='PO'
                                            checked={var_building_order.value === 'PO'}
                                            onChange={onChangeBuildingOrder}
                                        />
                                        <Radio
                                            label='Work Order'
                                            name='buildingOrder'
                                            value='WO'
                                            checked={var_building_order.value === 'WO'}
                                            onChange={onChangeBuildingOrder}
                                        />
                                        <Radio
                                            label='Maintenance Order'
                                            name='buildingOrder'
                                            value='MO'
                                            checked={var_building_order.value === 'MO'}
                                            onChange={onChangeBuildingOrder}
                                        />
                                         <Radio
                                            label='None'
                                            name='buildingOrder'
                                            value={null}
                                            defaultChecked
                                            checked={var_building_order.value === null}
                                            onChange={onChangeBuildingOrder}
                                        />
                                    </Form.Field>
                                    
                                </Form.Group>
                            </div>
                        </div>
                        <div className="building-add-unit-container">
                            <div className="app-card">
                                <div className="buildding-add-unit-header">
                                    <div className="building-add-unit-title">
                                        Units ({(units.length)})
                                    </div>
                                    <div className="building-add-unit-actions">
                                        <Button type="button" color="grey" onClick={() => onOpen_mdl_addunit()}>
                                            Add Unit
                                        </Button>
                                        {/* <Button type="button" color="orange" onClick={() => onOpen_mdl_bulkunits()}>
                                            Upload Bulk Unit
                                        </Button> */}
                                    </div>
                                </div>
                                <div className="building-added-unit-list">
                                    {units.map((val,index)=>(
                                        <div key={index} className={`added-unit-card ${val.is_warranty ===1 && "warranty-card"}`}>
                                            <div className="added-unit-card-header">
                                                <div className="warranty-badge">
                                                    <div className="warranty-text">Warranty</div>
                                                </div>
                                                <Button.Group className="add-unit-menu">
                                                    <Dropdown icon="ellipsis vertical">
                                                        <Dropdown.Menu>
                                                            {/* <Dropdown.Item onClick={() => onOpen_mdl_updateunit(val)}>Edit</Dropdown.Item> */}
                                                            <Dropdown.Item onClick={removeValue.bind(this,index)}>Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Button.Group>
                                            </div>
                                            <div className="added-unit-card-item">
                                                <div className="unit-number">Unit {val.unit}</div>
                                                <div className="number-beds">Beds: {val.bedroom_count}</div>
                                            </div>
                                            <div className="added-unit-card-item">Townhouse: {val.town_house===1 ?"Yes":"No"}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="add-building-submit">
                            <Button color="red" onClick={on_add_submit}>Submit</Button>
                        </div>
                        <Message
                            error
                            header='Missing Information'
                            content={validation_error}
                        />
                    </Form>
                </div>
            </div>

            {/***** MODAL: ADD UNIT **************************************************************************/}
            <Modal id="build-mdl-add-unit"
                   dimmer={'inverted'}
                   open={var_mdl_addunit_open}
                   onClose={() => set_mdl_addunit_open(false)}>
                <MDL_ADD_UNIT
                    set_mdl_open={set_mdl_addunit_open}
                    add_single_unit={add_single_unit}
                />
            </Modal>

            {/***** END MODAL: ADD UNIT **************************************************************************/}

            {/***** MODAL: UPLOAD BULK UNITS **************************************************************************/}
            <Modal id="mdl-upload-bulkunit"
                   dimmer={'inverted'}
                   open={var_mdl_bulkunit_open}
                   onClose={() => set_mdl_bulkunit_open(false)}>
                <MDL_BULK_UNIT
                    set_mdl_open={set_mdl_bulkunit_open}
                    onChangeEvent={readExcel}
                />
            </Modal>

            {/***** END MODAL: UPLOAD BULK UNITS **************************************************************************/}

            {/***** MODAL: SUCCESS **************************************************************************/}
            <Modal id="mdl-success"
                dimmer={'inverted'}
                open={var_mdl_success}
                onClose={() => set_mdl_success(false)}>
                <MDL_SUCCESS
                    set_mdl_open = {set_mdl_success}
                    var_modaltitle = "Success"
                    var_message = {success_message}
                    success_function={success_function}
                >
                </MDL_SUCCESS>
            </Modal>

            {/***** END MODAL: SUCCESS **************************************************************************/}
        </>
    )
}

export default AddBuilding
