import React, {useState, useEffect, useContext} from 'react'
import { Icon, Button, Form, Input } from 'semantic-ui-react';
import {updateUser } from '../../../../constants';
import {useToasts } from 'react-toast-notifications';
import './mdl-update-technician.css'
import { AppContext } from '../../../../Context/AppContext';

export default function MDL_UPDATE_CUSTOMER({set_mdl_open, scheduler}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const{userInfo}=useContext(AppContext)
    const[user,setUser]=userInfo;
    const [ var_update_scheduler, set_update_scheduler ] = useState(scheduler);
    const { addToast } = useToasts();
    const [ errors, set_errors] = useState([])


    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
	}, [])


    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if(name==="contact_number"){
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(value)) {
            errors["contact_number"] = "Please enter only number.";
            }else if(value.length < 11){
            errors["contact_number"] = "Please enter valid phone number.";
            }
            else{
                errors["contact_number"]=null
            }
        }
        set_update_scheduler({...var_update_scheduler, [name]: value});
    }

    const onSubmit_updated_data = async (e) => {
        e.preventDefault();
        if(errors["contact_number"]){
            errors["contact_number"]="Please add valid number."
        }
        else{
        const { first_name,last_name,email,address,contact_number } = var_update_scheduler;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
            body: JSON.stringify({ first_name,last_name,email,address,contact_number,user_id:user.user_id})};
        var apiUrl=updateUser+`${var_update_scheduler.uid}`;
        await fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(res=>{
            if(res.code && res.code === 401){
                addToast("Session expired. Please Login again.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
                //SessionExpire();
                localStorage.removeItem('user')
                setUser(null)
            }
            else{
                addToast(res.body.success, {
                    appearance: 'success',
                    autoDismiss: true,
                })
            }
            set_mdl_open(false);
        }).catch(err=>{
            console.error("Error:",err);
        });
        }
    }   
    
    

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form method="POST" onSubmit={onSubmit_updated_data}>
            <div className="modal-header">
                <div className="modal-header-title">Update Technician</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
            <Form.Group>
                    <Form.Field
                        id='first_name'
                        name='first_name'
                        label='First Name'
                        control={Input}
                        required
                        onChange={onChangeInput}
                        value={var_update_scheduler.first_name}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='last_name'
                        name='last_name'
                        label='Last Name'
                        control={Input}
                        required
                        onChange={onChangeInput}
                        value={var_update_scheduler.last_name}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='email'
                        name='email'
                        label='Email'
                        disabled={true}
                        control={Input}
                        required
                        onChange={onChangeInput}
                        value={var_update_scheduler.email}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='contact_number'
                        name='contact_number'
                        label='Contact no.'
                        type="tel"
                        required
                        error={errors["contact_number"]}
                        control={Input}
                        onChange={onChangeInput}
                        value={var_update_scheduler.contact_number}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='address'
                        name='address'
                        label='Address'
                        control={Input}
                        required
                        onChange={onChangeInput}
                        value={var_update_scheduler.address}
                    />
                </Form.Group>
            </div>

            <div className="modal-footer">
                <Button type="submit" color="red">
                    Update Technician
                </Button>
            </div>
        </Form>
        </>
    )
}
