import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Icon, Radio } from "semantic-ui-react";
import { getAllPests, getAllPestsTreatment } from "../../../../constants";
import { AppContext } from "../../../../Context/AppContext";
import "./cmp-add-unit.css";

const ADD_UNIT = ({ index, doDelete, isTech }) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { selected_units, error_validation, bInfo, userInfo, buildingUnits } =
    useContext(AppContext);
  const [user, setUser] = userInfo;
  const [building_units] = buildingUnits;
  const [var_add_unit, set_add_unit] = selected_units;
  const [form_validation_error, setFormError] = error_validation;
  const [building_info, setBuildingInfo] = bInfo;
  const [order_types, set_order_types] = useState([]);
  const [pestTreated, setPestTreated] = useState();
  const [treatmentOption, setTreatmentOptions] = useState();

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  useEffect(() => {
    const { role } = user;
    //if(var_add_unit[index].followup)
    if (role === "technician") {
      if (
        var_add_unit[index].followup === false ||
        var_add_unit[index].followup
      ) {
        var_add_unit[index].followup = var_add_unit[index].followup;
      } else var_add_unit[index].followup = "followup";
      set_order_types([
        {
          key: "followup",
          text: "Follow-up (2 weeks)",
          value: "followup",
        },
        {
          key: "reschedule",
          text: "Reschedule",
          value: "reschedule",
        },
      ]);
    } else {
      set_order_types([
        {
          key: "neworder",
          text: "New",
          value: "neworder",
        },
        {
          key: "followup",
          text: "Follow Up (Service In 2 Weeks)",
          value: "followup",
        },
        {
          key: "followup_month",
          text: "Follow Up (Service In Month)",
          value: "followup_month",
        },
        {
          key: "followup_immediately",
          text: "Follow Up (Service Immediately)",
          value: "followup_immediately",
        },
        {
          key: "reschedule",
          text: "Reschedule",
          value: "reschedule",
        },
      ]);
    }
    getPests();
  }, []);

  useEffect(() => {
    getPestTreatments();
  }, [var_add_unit[index].pest_id]);

  async function getPestTreatments() {
    await fetch(getAllPestsTreatment + var_add_unit[index].pest_id)
      .then((response) => response.json())
      .then((res) => {
        var allTempPest = [];
        if (res.code === 200) {
          res.data.map((value, i) => {
            allTempPest = [
              ...allTempPest,
              {
                key: value.treatment_name,
                text: value.treatment_name,
                value: value.treatment_id,
              },
            ];
          });
          setTreatmentOptions(allTempPest);
        } else {
        }
      });
  }
  async function getPests() {
    await fetch(getAllPests)
      .then((response) => response.json())
      .then((res) => {
        var allTempPest = [];
        if (res.code === 200) {
          res.data.map((value, i) => {
            allTempPest = [
              ...allTempPest,
              {
                key: value.pestName,
                text: value.pestName,
                value: value.pestId,
              },
            ];
          });
          setPestTreated(allTempPest);
        } else {
        }
      });
  }

  function updateStaeValues(name, value, isFound) {
    var temp = [...var_add_unit];
    if (name === "unit_number") {
      temp[index] = { ...temp[index], exist: isFound };
    }
    temp[index] = { ...temp[index], [name]: value };
    set_add_unit(temp);
  }

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    const { role } = user;
    const units = building_units;
    let name = event.target.name;
    let value = event.target.value;
    var isFound = false;
    if (name === "unit_number") {
      units.map((val) => {
        if (
          value.replace(/^[.\s]+|[.\s]+$/g, "").toLowerCase() ==
          val.unit.toLowerCase()
        ) {
          isFound = true;
        }
      });
      if (role === "technician" || "scheduler") {
        if (!isFound) {
          form_validation_error[index].unit_number =
            value + " unit not registered.";
        } else {
          form_validation_error[index].unit_number = null;
        }
      }
    }
    updateStaeValues(name, value, isFound);
    //set_unit_data({...temp, [name]: value});
  };

  const OnChangePest = (e, { value }) => {
    if (e.target.id === "") {
      var temp = [...var_add_unit];
      temp[index] = { ...temp[index], pest_id: value };
      temp[index] = {
        ...temp[index],
        pest: pestTreated.filter((val) => val.value === value)[0].text,
      };
      temp[index] = { ...temp[index], treatment_id: null };
      temp[index] = { ...temp[index], treatment: null };
      set_add_unit(temp);
    }
  };

  const OnChangeTreatment = (e, { value }) => {
    if (e.target.id === "") {
      var temp = [...var_add_unit];
      temp[index] = { ...temp[index], treatment_id: value };
      temp[index] = {
        ...temp[index],
        treatment: treatmentOption.filter((val) => val.value === value)[0].text,
      };
      set_add_unit(temp);
    }
  };

  const onChangeOrderType = (e, { value }) => {
    if (e.target.id === "") {
      updateStaeValues("followup", value);
    }
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <div className="app-card add-unit-card">
      <Form.Group>
        <Form.Field
          fluid
          id={"unit_number" + index}
          name="unit_number"
          label="Unit #"
          required
          error={form_validation_error[index].unit_number}
          value={
            var_add_unit[index].unit_number &&
            var_add_unit[index].unit_number != ""
              ? var_add_unit[index].unit_number
              : ""
          }
          placeholder="Write unit #"
          control={Input}
          onChange={onChangeInput}
        />
        <Form.Select
          fluid
          label="Pest Requested"
          name="pest_id"
          id="pest_id"
          placeholder="Select pest"
          selectOnBlur={false}
          value={
            var_add_unit[index].pest_id ? var_add_unit[index].pest_id : null
          }
          options={pestTreated}
          onChange={OnChangePest}
          required
        />
        <Form.Select
          fluid
          label="Treatment Requested"
          name="treatment_id"
          id="treatment_id"
          placeholder="Select treatment"
          options={treatmentOption}
          selectOnBlur={false}
          value={
            var_add_unit[index].treatment_id
              ? var_add_unit[index].treatment_id
              : null
          }
          onChange={OnChangeTreatment}
          required
        />
      </Form.Group>
      <Form.Group className="addunit-textarea-field" key={index}>
        <Form.Select
          fluid
          label="Type of Request"
          name="order_type"
          id="order_type"
          placeholder="Select type of request"
          options={order_types}
          selectOnBlur={false}
          value={
            var_add_unit[index].followup ? var_add_unit[index].followup : null
          }
          onChange={onChangeOrderType}
          required
        />
        <Form.TextArea
          id="order_description"
          name="order_description"
          label="Notes"
          value={
            var_add_unit[index].order_description
              ? var_add_unit[index].order_description
              : ""
          }
          required={
            var_add_unit[index].pest === "Unknown" ||
            var_add_unit[index].pest === "Other Pest"
          }
          placeholder="Please add any additional info for this unit."
          fluid
          onChange={onChangeInput}
        />
        <Form.Field></Form.Field>
      </Form.Group>
      {var_add_unit.length > 1 && (
        <div
          className="icon-remove-unit"
          onClick={() => doDelete(var_add_unit[index].key)}
        >
          <Icon name="times circle outline" />
        </div>
      )}
    </div>
  );
};
export default ADD_UNIT;
