import React, {useState, useEffect} from 'react'
import { Icon, Button, Form, Input,Message, Modal, TextArea } from 'semantic-ui-react';
import './mdl-forgot-pin-customer.css'
import {getAllUsers, resetPin} from '../../../../constants'
import { useToasts } from 'react-toast-notifications';
import MDL_SUCCESS from '../../../../components/cmp-success/cmp-success'

export default function MDL_FORGOT_PIN_CUSTOMER({set_mdl_open}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const [ var_forget_pin, set_forget_pin ] = useState()
    const { addToast } = useToasts();
    const[message,setMessage]=useState();
    const [ var_mdl_success, set_mdl_success ] = useState(false);
    const[isError,setIsError]=useState(false);
    const[validation_error,setValidationError]=useState()
    const[submitForm,setSubmitForm]=useState(false);

    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
    useEffect(()=>{
        if(var_forget_pin){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(var_forget_pin)};
            fetch(resetPin, requestOptions)
            .then(response=>response.json())
            .then(res=>{
                const{success,failed}=res;
                if(res.code===200){
                    setMessage(success);
                    set_mdl_success(true);
                }
                else{
                    setIsError(true);
                    setValidationError("Location address not found. Please make sure you have entered the full location address without any short forms, eg. “123 Main Street South” and try again. If this does not work, please email service@homepropestcontrol.ca for support.");
                }
            }).catch(error=>{
                console.error("Error:",error);
            });
        }
    },[submitForm])
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setIsError(false);
        set_forget_pin({...var_forget_pin, [name]: value});
    }
    
     const postCustomerData=async ()=>{
        
        if(var_forget_pin){
            const{question_one}=var_forget_pin;
            if(question_one.length > 3){
                setSubmitForm(!submitForm);
            }
            else{
                setIsError(true)
                setValidationError("Your name is not valid");
            }
        }
    }
    const success_function = () => {
        set_mdl_open(false);
        set_mdl_success(false);

    }
    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form method="POST" error={isError} onSubmit={postCustomerData}>
            <div className="modal-header">
                <div className="modal-header-title">PIN # Retrieval Questions</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                
                    <Form.Group>
                        <Form.Field
                            id='question_one'
                            name='question_one'
                            label='What is your name?'
                            control={Input}
                            required
                            onChange={onChangeInput}
                            placeholder='Write first & last name here'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            id='question_two'
                            name='question_two'
                            label='What is your company name?'
                            control={Input}
                            required
                            onChange={onChangeInput}
                            placeholder='Write company name here'
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea
                            id='question_three'
                            name='question_three'
                            label='What is your building address?'
                            control={TextArea}
                            required
                            rows = {3}
                            autoHeight
                            onChange={onChangeInput}
                            placeholder='Write full building address eg. “123 Main Street South” or “100 Maple Avenue West'
                        />
                    </Form.Group>
            </div>
            <Message
                    error
                    header='Error'
                    content={validation_error}
                    />
            <div className="modal-footer" >
                <Button type="submit" color="red">
                    Submit
                </Button>
            </div>
        </Form>
        {/***** MODAL: SUCCESS **************************************************************************/}
        <Modal id="mdl-success"
            dimmer={'inverted'}
            open={var_mdl_success}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={success_function}>
            <MDL_SUCCESS
                set_mdl_open = {set_mdl_success}
                var_modaltitle = "Success"
                var_message = {message}
                success_function={success_function}
            >
            </MDL_SUCCESS>
        </Modal>
        </>
    )
}

