import React, { useState, useContext } from 'react';
import { Icon, Button, Form, Input, Message } from 'semantic-ui-react';
import { portalDocuments } from '../../../../constants';
import { AppContext } from '../../../../Context/AppContext';
import { useToasts } from 'react-toast-notifications';
import './mdl-upload-documents.css';

export default function MDL_UPLOAD_DOCUMENT({
  folder_id,
  set_mdl_open,
  update_folder_list,
}) {
  const { userInfo } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [var_create_folder, set_create_folder] = useState({});
  const [var_loading, set_loading] = useState(false);
  const { addToast } = useToasts();

  //////////////Upload File
  const [selectedFile, setSelectedFile] = useState();
  const [var_values, set_values] = useState({});
  const [var_errors, set_errors] = useState({
    document: '',
  });

  const validateFile = (file) => {
    const allowedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (!allowedFileTypes.includes(file.type)) {
      set_errors({ ...var_errors, document: 'Only PDF and Excel files are allowed.' });
      return false;
    }
    set_errors({ ...var_errors, document: '' });
    return true;
  };

  const submitUploadDocument = async () => {
    if (!selectedFile || !validateFile(selectedFile)) {
      return;
    }

    const formData = new FormData();
    formData.append('document', selectedFile);
    formData.append('docIndex', 1);

    let requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Token ' + user.token,
      },
      body: formData,
    };

    requestOptions.body = formData;

    await fetch(`${portalDocuments}/upload-document/${folder_id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.code === 200) {
          set_mdl_open(false);
          update_folder_list(true);
          addToast(res.message, {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          addToast(res.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  function handleChangeFile(event) {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      validateFile(file);
    }
  }

  return (
    <>
      <Form method="POST" onSubmit={submitUploadDocument}>
        <div className="modal-header">
          <div className="modal-header-title">Upload Document</div>
          <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
            <Icon name="times circle outline" />
          </div>
        </div>
        <div className="modal-content">
          <Form.Group>
            <Form.Field
              id="document"
              name="document"
              label="Upload Document"
              type="file"
              chosenFile={selectedFile ? selectedFile.name : 'No file chosen'}
              value={var_create_folder.title}
              required
              control={Input}
              onChange={handleChangeFile}
            />
          </Form.Group>
        </div>
        <Message error header="Error" content={var_errors.document} />
        <div className="modal-footer">
          <Button type="submit" color="red" disabled={!selectedFile || var_errors.document}>
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}