import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button, Label, Icon, Modal } from 'semantic-ui-react';
import { portalDocuments} from '../../../constants';
import { AppContext } from "../../../Context/AppContext";
import MDL_CREATE_FOLDER from './mdl-create-folder/mdl-create-folder';
import MDL_UPDATE_FOLDER from './mdl-update-folder/mdl-update-folder';
import MDL_CONFIRMATION from '../../../components/cmp-confirmation/cmp-confirmation'
import { useToasts } from 'react-toast-notifications';
import './PortalDocuments.css';

function PortalDocuments(){


    /* VARIABLE DECLARATIONS
	=================================================================================*/
    const[ var_folders,set_folders ]=useState([]);
    const [ var_mdl_create_folder_open, set_mdl_create_folder_open  ] = useState(false);
    const [ var_mdl_update_folder_open, set_mdl_update_folder_open] = useState(false);
    const [ var_folder_data, set_folder_data] = useState();
    const [ var_mdl_confirmation, set_mdl_confirmation] = useState(false);
    const [ deleteIndex, setDeleteIndex ] = useState();
    const {  userInfo } = useContext(AppContext);
    const [user,] = userInfo;
    const { addToast } = useToasts();
    const history = useHistory();



    /* EVENT LISTENERS
	=================================================================================*/
	useEffect(() => {
        getFolderList();
	}, [])

    const updateModel=(value)=>{
        set_mdl_update_folder_open(value);
    }

    /* Async functions
	---------------------------------------------------------------------------------*/
    const getFolderList=async()=>{
        await fetch(`${portalDocuments}`)
        .then(response => response.json())
        .then(res => {
            const{data}=res
            set_folders(data);
        })
        .catch(error=>{console.error("Error:",error)});
    }

    async function delete_folder(){
        var folder = var_folders[deleteIndex];
        if(folder){
            const{uuid}=folder;

            const requestOptions = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Token " + user.token,
                },
                method: "DELETE",
              };
            await fetch(`${portalDocuments}/folder/${uuid}`,requestOptions)
            .then(res=>res.json())
            .then(response=>{
                const{failed,success}=response;
                if(failed){
                    addToast(failed, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
                if(success){
                    getFolderList();
                    addToast(success, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                }
            })
            .catch(error=>{
                console.error("Error",error);
            })
        }
    }

    /* EVENT FUNCTIONS
	=================================================================================*/
    function onOpen_mdl_create_folder() {
        set_mdl_create_folder_open(true)
    }

    function onOpen_mdl_update_folder(folder) {
        set_folder_data(folder);
        set_mdl_update_folder_open(true);
    }

    function onOpen_mdl_confirmation(pos) {
        set_mdl_confirmation(true);
        setDeleteIndex(pos);
    }

    const goToFolderDocumentsView = (folder) => {
        history.push({pathname:`/portal-documents/${folder.uuid}`,state:{data:folder}});
    }


    /* RENDER APP
	=================================================================================*/
    return(
        <>
        <div className="app-content">
            <div className="app-scheduler-container">

                <div className="app-content-action">
                    <div className="app-content-action-right">
                        <Button color="red" onClick={()=> onOpen_mdl_create_folder()}>
                            <img src="/create-folder.png" alt="icon" />Create Folder
                        </Button>
                    </div>
                </div>

                <div className="app-card">
                    <div className="app-card-header">
                        <div className="app-card-title">Folders ({var_folders.length})</div>
                    </div>

                    <div className="app-table-wrapper">
                        <Table unstackable>
                            <Table.Header>
                                <Table.Row>

                                    <Table.HeaderCell>Folder Name</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                            {var_folders.map((folder, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell>
                                    <span>
                                        <img src="/folder-icon.png" alt="pdf" style={{width:"32px", height:'32px'}}/>
                                        <span> {folder.title}</span>
                                    </span>
                                        </Table.Cell>
                                    <Table.Cell className="td-action" collapsing textAlign='right'>
										<Label color="blue" onClick={() =>  goToFolderDocumentsView(folder)}>View</Label>
										<Label onClick={()=> onOpen_mdl_update_folder(folder)} color="green">Edit</Label>
										<Label color="red"  onClick={() => onOpen_mdl_confirmation(i)}>Delete</Label>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            </Table.Body>
                        </Table>
                        {var_folders.length === 0 &&
                            <div className="nodata-placeholder">
                                There is no folder to display.
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>


        {/***** MODAL: CREATE FOLDER **************************************************************************/}
        <Modal id="mdl-add-scheduler"
                   dimmer={'inverted'}
                   open={var_mdl_create_folder_open}
                   onClose={() => set_mdl_create_folder_open(false)}>
                <MDL_CREATE_FOLDER
                    set_mdl_open={set_mdl_create_folder_open}
                    update_folder_list={getFolderList}
                >
                </MDL_CREATE_FOLDER>
            </Modal>

            {/***** END MODAL: CREATE FOLDER **************************************************************************/}


            {/***** MODAL: UPDATE SCHEDULER **************************************************************************/}
            <Modal id="mdl-update-scheduler"
                   dimmer={'inverted'}
                   open={var_mdl_update_folder_open}
                   onClose={() => set_mdl_update_folder_open(false)}>
                <MDL_UPDATE_FOLDER
                    set_mdl_open = {updateModel}
                    folder = {var_folder_data}
                    update_folder_list={getFolderList}
                >
                </MDL_UPDATE_FOLDER>
            </Modal>

            {/***** END MODAL: UPDATE SCHEDULER **************************************************************************/}

            {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
            <Modal id="mdl-confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation}
                   onClose={() => set_mdl_confirmation(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open = {set_mdl_confirmation}
                    var_modaltitle = "Delete Folder"
                    var_message = "Are you sure want to delete this folder?"
                    confirmation_function = {delete_folder}
                >
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}
        </>
    )

}

export default PortalDocuments;