import React, { useState, useEffect } from "react";
import {
  Accordion,
  Icon,
  Segment,
  Label,
  Header,
  Modal,
} from "semantic-ui-react";
import { portalDocuments } from "../../../constants";
import CMP_PDF_VIEWER from "../../../components/cmp-pdf-viewer/cmp-pdf-viewer";
import "./customer-portal-documents.css";
import { useHistory } from "react-router-dom";

const EXCEL_DOCUMENT_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

function CustomerPortalDocuments() {
  const [var_folders, set_folders] = useState([]);
  const [var_documents, set_documents] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [var_selected_folder, set_selected_folder] = useState(null);
  const history = useHistory();
  const [pdf_url, set_pdf_url] = useState();
  const [selected_document, set_selected_document] = useState();
  const [open_pdf_viewer, set_open_pdf_viewer] = useState(false);

  const [var_loading_documents, set_loading_documents] = useState(false);

  useEffect(() => {
    getFolderList();
  }, []);

  useEffect(() => {
    if (var_selected_folder && var_selected_folder?.uuid) {
      getDocumentList();
    }
  }, [var_selected_folder]);

  useEffect(() => {
    if (pdf_url) {
      if (
        selected_document &&
        selected_document.mimeType === EXCEL_DOCUMENT_TYPE
      ) {
        selected_document && downloadDocument();
      } else {
        set_open_pdf_viewer(true);
      }
    }
  }, [pdf_url]);

  const getFolderList = async () => {
    try {
      const response = await fetch(`${portalDocuments}`);
      const { data } = await response.json();
      set_folders(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getDocumentList = async () => {
    set_loading_documents(true);
    await fetch(
      `${portalDocuments}/folder-documents/${var_selected_folder.uuid}`
    )
      .then((response) => response.json())
      .then((res) => {
        set_loading_documents(false);
        const { data } = res;
        set_documents(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    set_selected_folder(var_folders[newIndex]);
    setActiveIndex(newIndex);
  };

  async function downloadDocument() {
    const url = `${pdf_url}?download=true`;
    console.log("Url", url);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${selected_document.name}`; // Set the desired filename
      downloadLink.click();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  /* RENDER APP
	=================================================================================*/

  return (
    <div className="app-content">
      <div className="app-backto-prev-page">
        <div
          className="app-goback-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon name="arrow left" /> Home
        </div>
      </div>
      <div className="app-scheduler-container">
        <div className="app-card">
          <div className="app-card-header">
            <div className="app-card-title">Folders ({var_folders.length})</div>
          </div>

          <div className="app-accordion-wrapper">
            <Accordion fluid styled>
              {var_folders.map((folder, i) => (
                <React.Fragment key={i}>
                  <Accordion.Title
                    active={activeIndex === i}
                    index={i}
                    onClick={handleAccordionClick}
                  >
                    <Icon name="dropdown" />
                    <img
                      src="/folder-icon.png"
                      alt="pdf"
                      style={{ width: "32px", height: "32px" }}
                    />
                    <span>{`${folder.title} ${
                      var_documents.length > 0 && activeIndex === i
                        ? `(${var_documents.length})`
                        : ""
                    }`}</span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === i}>
                    <Segment className="app-documents-list">
                      {var_loading_documents && <span>loading......</span>}
                      {!var_loading_documents &&
                        !!var_documents.length &&
                        var_documents.map((document, i) => (
                          <div
                            key={i}
                            className="document-item"
                            onClick={() => {
                              set_selected_document(document);
                              set_pdf_url(
                                `${portalDocuments}/${document.uuid}`
                              );
                            }}
                          >
                            <span className="">
                              <img
                                width="28"
                                height="25"
                                src={
                                  document.mimeType === EXCEL_DOCUMENT_TYPE
                                    ? "/excel.png"
                                    : "/pdf.png"
                                }
                                alt="document"
                              />
                              <span> {document.name}</span>
                            </span>
                            {/* <span className="document-actions">
                              <Label
                                style={{paddingLeft:'12px'}}
                                color="blue"
                                image
                                onClick={() => {
                                  set_selected_document(document);
                                  set_pdf_url(
                                    `${portalDocuments}/${document.uuid}`
                                  );
                                }}
                              >
                                <img src="/eye-icon.svg" alt="eye"   style={{width: '10px', height: '10px' }} />
                                View
                              </Label>
                            </span> */}
                          </div>
                        ))}
                      {!var_loading_documents &&
                        var_documents?.length === 0 && (
                          <div className="nodata-placeholder">
                            There is no documents to display.
                          </div>
                        )}
                    </Segment>
                  </Accordion.Content>
                </React.Fragment>
              ))}
            </Accordion>

            {var_folders.length === 0 && (
              <div className="nodata-placeholder">
                There is no folder to display.
              </div>
            )}
          </div>
        </div>
      </div>
      {/***** MODAL: PDF  VIEWER **************************************************************************/}
      <Modal
        id="mdl-pdf-viewer"
        dimmer={"inverted"}
        open={open_pdf_viewer}
        onClose={() => {
          set_open_pdf_viewer(false);
          set_pdf_url(null);
        }}
      >
        <CMP_PDF_VIEWER
          set_mdl_open={set_open_pdf_viewer}
          pdf_url={pdf_url}
          set_pdf_url={set_pdf_url}
          var_modaltitle={selected_document?.name}
        />
      </Modal>
      {/***** MODAL: PDF  VIEWER **************************************************************************/}
    </div>
  );
}

export default CustomerPortalDocuments;
