import React, { useContext, useState, useEffect } from 'react';
import { Table, Label, Icon, Modal } from 'semantic-ui-react';
import { AppContext } from '../../../Context/AppContext';
import { useHistory } from 'react-router-dom';
import MDL_PROGRESS from '../../../components/cmp-progress/cmp-progress';
import { acceptOrRejectOrder, buildingStat, getAllOrdersOfScheduler, getAllOrdersOfUser, getBuildingRequetsOrder } from '../../../constants';
import AppPagination from '../../../components/cmp-pagination/Pagination';
import { useToasts } from 'react-toast-notifications';

const FollowUpRequests=()=>{


    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const {userInfo,bInfo,orderCunt } = useContext(AppContext);
    const [ ,setOrderCount] = orderCunt;
    const[user,setUser]=userInfo;
    const { addToast } = useToasts();
    const [ building_info, setBuildingInfo ] = bInfo;
    const [orders,setOrders] = useState([]);
    const history = useHistory();
    const [ page,setPage ] = useState(1);
    const [ limit,setLimit ] = useState(50);
    const[,setTotalOrders]=useState(0)
    const[var_mdl_progress,set_mdl_progress]=useState(false);



    /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
    useEffect(() => {
		getFollowupRequests();
	}, [page,limit])

    /**
     * Get the stats of building
     */
     async function getBuildingStat(){
        if(building_info && !!building_info.length && building_info[0]?.value){
        await fetch(buildingStat+`${building_info[0]?.value}`)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200)
            setOrderCount(res);

        })
        .catch(error=>{
            console.error("Error:",error);
        })}
        else{
                setBuildingInfo(null);
        }
    }

    async function getFollowupRequests(page_no,limit_range){
        await fetch(getBuildingRequetsOrder+`?page=${page_no?page_no: page}&limit=${limit_range?limit_range: limit}&building_id=${building_info && !!building_info.length ? building_info[0]?.value  : null}`)
        .then(response=>response.json())
        .then(res=>{
            var ordersFetch=[];
            setTotalOrders(res.body.total_order);
            if(res.statusCode===200){
                ordersFetch=res.body.data
                if(page && page > 1 && res.body && res.body.data && res.body.data.length === 0){
                    setPage(page-1)
                }
            }
            else{
                ordersFetch=[]
            }
            if( (page && page >1) || (page_no && page_no >1) )
            ordersFetch=[...orders,...ordersFetch];
            setOrders(ordersFetch);

        })
        .catch(error=>{
            console.error("Error:",error);
        })
    }

    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

    async function acceptAndRejectRequest(request,is_accepted){
        // history.push({pathname:"/complete-order",state:{id}})
        var temp={...request};
        temp={...temp,is_accepted}
        temp.user_id=user.user_id
        set_mdl_progress(true);
        const order_options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
            body: JSON.stringify(temp)
        };
        await fetch(acceptOrRejectOrder,order_options)
        .then(res=>res.json())
        .then(res=>{
            if(res.code && res.code === 401){
                addToast("Session expired. Please Login again.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
                //SessionExpire();
                localStorage.removeItem('user')
                localStorage.removeItem('building')
                setUser(null)
                setBuildingInfo(null);
            }
            else{
                if(res.code===200){
                    getBuildingStat()
                    getFollowupRequests();
                    addToast(res.message, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                }else{
                    addToast(res.message, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            }
        }).catch(error=>{
            console.log("error:",error);
        })
        set_mdl_progress(false);
    }

    function goViewDetail(request){
        history.push({pathname:"/order-detail",state:{id:request.id}});
        // history.push({pathname:"/complete-order",state:{id}})
    }

    /* RENDER APP
	---------------------------------------------------------------------------------*/

    return(
    <>
        <div className="app-content">
            <div className="app-card">
                <div className="app-card-header">
                    <div className="app-card-title">Follow Up Request List ({orders.length})</div>
                </div>

                <div className="app-table-wrapper">
                    <Table unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell title="No. of units">Units</Table.HeaderCell>
                                <Table.HeaderCell title="Technician Name">Technician</Table.HeaderCell>
                                <Table.HeaderCell title="Technician Email">Email</Table.HeaderCell>
                                <Table.HeaderCell title="Date of request">Requested Date</Table.HeaderCell>
                                <Table.HeaderCell title="Current request Status">Status</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>

                                {orders && orders.map((order, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell>{order.units.length}</Table.Cell>
                                        <Table.Cell>{order.tech_name}</Table.Cell>
                                        <Table.Cell>{order.tech_email}</Table.Cell>
                                        <Table.Cell>{order.date}</Table.Cell>
                                        <Table.Cell className="td-status">
                                            {order.status === 'pending' &&
                                                <div style={{color: '#FA8C16'}}>Pending</div>
                                            }
                                            </Table.Cell>
                                        <Table.Cell className="td-action" collapsing textAlign='right'>
                                            <Label color="blue" onClick={()=>goViewDetail(order)}>View</Label>
                                            <Label color="green" onClick={()=>acceptAndRejectRequest(order,true)}>Accept</Label>
                                            <Label color="red" onClick={()=>acceptAndRejectRequest(order,false)}>Reject</Label>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                    </Table>
                </div>
                { orders.length===0 &&
                            <div className="nodata-placeholder">
                                There is no request to display.
                            </div>}
            </div>
            <AppPagination limit={limit} setLimit={setLimit} page={page} setPage={setPage} rowsTotal={orders.length}/>
        </div>
                {/***** MODAL: PROGRESS **************************************************************************/}
                <Modal id="mdl-progress"
            dimmer={'inverted'}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}>
            <MDL_PROGRESS
                set_mdl_open = {set_mdl_progress}
                var_modaltitle = "Please wait"
                var_message = {"Please wait..."}
            >
            </MDL_PROGRESS>
        </Modal>

        {/***** END MODAL: PROGRESS **************************************************************************/}

</>
    )
}

export default FollowUpRequests;