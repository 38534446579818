import React, {useContext, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Label, Table, Search } from 'semantic-ui-react';
import AppPagination from '../../../../components/cmp-pagination/Pagination';
import { getAllOrdersOfUser, getSingleBuilding } from '../../../../constants';
import './view-building.css'
import dateFormat from 'dateformat';
import { AppContext } from '../../../../Context/AppContext';

const ViewBuilding = () => {
    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const { userInfo } = useContext(AppContext);
    const [ user, ] = userInfo
    const history=useHistory();
    const[data,setData]=useState();
    const [ orders,setOrders ] = useState([]);
    const [ page,setPage ] = useState(1);
    const [ limit,setLimit ] = useState(50);
    const [ searchResult,setSearchResult ] = useState([]);
    const[,setTotalOrders]=useState(0)

    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        if(history.location.state)
        getBuildingData();
        else
        history.goBack();
	},[])
    useEffect(()=>{
        if(data)
        getBuildingOrders();
    },[limit,page,data])
    /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/
    async function getBuildingData(){
        await fetch(getSingleBuilding+`${history.location.state.data}`)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200){
                setData(res.data[0]);
                //getBuildingOrders();
            }
            else{
                setData(null)
            }
        })
        .catch(error=>{
            setData(null);
            console.error("Error:",error);
        })
    }

    async function getBuildingOrders(page_no,limit_range){
        await fetch(getAllOrdersOfUser+`?page=${page_no?page_no: page}&limit=${limit_range?limit_range: limit}&is_admin=true&id=`+data.uuid)
        .then(response=>response.json())
        .then(res=>{
            var ordersFetch=[];
            setTotalOrders(res.body.total_order);
            if(res.statusCode===200){
                ordersFetch=res.body.data
                if(res.body && res.body.data && res.body.data.length === 0){
                    setPage(page-1)
                }
            }
            else{
                ordersFetch=[]
            }
            if( (page && page >1) || (page_no && page_no >1) )
            ordersFetch=[...orders,...ordersFetch];
            setOrders(ordersFetch);
        })
        .catch(error=>{
            console.error("Error:",error);
        })
    }


    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

    function goViewDetail(id,unit_number){
        if(user.role==="technician"){
            history.push({pathname:"/technician-home/order-detail",state:{id,unit_number}})
        }else{
            history.push({pathname:"/admin/order-detail",state:{id,unit_number}})
        }

    }

    function doSearch(event){
        const value=event.target.value.toString().toLowerCase();
        if ( value === "" ) {
            setSearchResult([]);
        } else {
            var dummySearchData=[];
            orders.map(val=>{
                if(value.includes(val.order_number) ||
                value.includes(val.unit_number)
                ){
                    dummySearchData=[...dummySearchData,val];
                }
            })
            setSearchResult(dummySearchData);
        }

    }

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="app-content">
                <div className="app-view-building">
                    <div className="app-backto-prev-page">
                        <div className="app-goback-button" onClick={history.goBack}><Icon name="arrow left" /> Go Back</div>
                    </div>
                    <div className="app-card">
                        <div className="app-card-header">
                            <div className="app-card-title">{data? data.building_name:"No Name"}</div>
                            <div className="app-card-building-units"><span>Units:</span> {data?data.units.length:0}</div>
                        </div>
                        <div className="view-building-section">
                            <div className="view-building-row">
                                <div className="view-building-label">Customer Name:</div>
                                <div className="view-building-field">{data?data.customer_name:"N/A"}</div>
                            </div>
                            <div className="view-building-row">
                                <div className="view-building-label">Building Email:</div>
                                <div className="view-building-field">{data?data.customer_email:"N/A"}</div>
                            </div>
                            <div className="view-building-row">
                                <div className="view-building-label">Building Code:</div>
                                <div className="view-building-field">{data?data.location_id:"N/A"}</div>
                            </div>
                        </div>
                        <div className="view-building-section">
                            <div className="view-building-row">
                                <div className="view-building-label">Scheduler Name:</div>
                                <div className="view-building-field">{data?data.scheduler_name:"N/A"}</div>
                            </div>
                            <div className="view-building-row">
                                <div className="view-building-label">Email:</div>
                                <div className="view-building-field">{data?data.scheduler_email:"N/A"}</div>
                            </div>
                            <div className="view-building-row">
                                <div className="view-building-label">Address:</div>
                                <div className="view-building-field">{data?data.scheduler_address:"N/A"}</div>
                            </div>
                            <div className="view-building-row">
                                <div className="view-building-label">Contact No:</div>
                                <div className="view-building-field">{data?data.scheduler_number:"N/A"}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="app-content-action app-content-action-search">
                    <div className="app-content-search">
                        <Search placeholder="Search Order Number or Unit Number" onSearchChange={value=>doSearch(value)} minCharacters={20000} />
                    </div>
                </div>
                <div className="app-card">
                    <div className="app-card-header">
                        <div className="app-card-title">{searchResult && searchResult.length>0 ? "Orders Found ("+ searchResult.length +")" : "Orders List ("+ orders.length +")"}</div>
                    </div>
                    <div className="app-table-wrapper">
                        <Table unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell title="Order #">Order #</Table.HeaderCell>
                                    <Table.HeaderCell title="Unit #">Unit #</Table.HeaderCell>
                                    <Table.HeaderCell title="PO/MO/WO">PO/MO/WO #</Table.HeaderCell>
                                    <Table.HeaderCell title="Building name">Building</Table.HeaderCell>
                                    <Table.HeaderCell title="Order scheduler">Scheduler</Table.HeaderCell>
                                    <Table.HeaderCell title="Scheduled date">Schedule Date</Table.HeaderCell>
                                    <Table.HeaderCell title="Current order status">Status</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {searchResult.length>0 && searchResult.map((order, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell>{order.order_number}</Table.Cell>
                                        <Table.Cell>{order.unit_number}</Table.Cell>
                                        <Table.Cell>{order?.purchase_order_number || 'N/A' }</Table.Cell>
                                        <Table.Cell>{order.building_name}</Table.Cell>
                                        <Table.Cell>{order.scheduler_first_name}</Table.Cell>
                                        <Table.Cell>{order.schedule_date ?
                                        dateFormat(new Date(order.schedule_date), "mm-dd-yyyy"):
                                        "N/A"}</Table.Cell>
                                        <Table.Cell className="td-status">
                                            {order.order_status === 'completed' &&
                                                <div style={{color: '#05881F'}}>Complete</div>
                                            }
                                            {order.order_status === 'scheduled' &&
                                                <div style={{color: '#05881F'}}>Scheduled</div>
                                            }
                                            {order.order_status === 'in progress' &&
                                                <div style={{color: '#FA8C16'}}>In Progess</div>
                                            }
                                        </Table.Cell>
                                        <Table.Cell className="td-action" collapsing textAlign='right'>
                                            <Label color="blue" onClick={()=>goViewDetail(order.order_id)}>View</Label>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                {searchResult.length ===0 && orders && orders.map((order, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell>{order.order_number}</Table.Cell>
                                        <Table.Cell>{order.unit_number}</Table.Cell>
                                        <Table.Cell>{order?.purchase_order_number || 'N/A' }</Table.Cell>
                                        <Table.Cell>{order.building_name}</Table.Cell>
                                        <Table.Cell>{order.scheduler_first_name}</Table.Cell>
                                        <Table.Cell>{order.schedule_date ?dateFormat(new Date(order.schedule_date), "mm-dd-yyyy"): "N/A"}</Table.Cell>
                                        <Table.Cell className="td-status">
                                            {order.order_status === 'completed' &&
                                                <div style={{color: '#05881F'}}>Complete</div>
                                            }
                                            {order.order_status === 'scheduled' &&
                                                <div style={{color: '#05881F'}}>Scheduled</div>
                                            }
                                            {order.order_status === 'in progress' &&
                                                <div style={{color: '#FA8C16'}}>In Progess</div>
                                            }
                                        </Table.Cell>
                                        <Table.Cell className="td-action" collapsing textAlign='right'>
                                            <Label color="blue" onClick={()=>goViewDetail(order.order_id,order.unit_number)}>View</Label>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                        {searchResult.length === 0 && orders.length === 0 &&
                            <div className="nodata-placeholder">
                                There is no order to display.
                            </div>
                        }
                    </div>
                </div>
                <AppPagination limit={limit} setLimit={setLimit} page={page} setPage={setPage} rowsTotal={orders.length}/>
            </div>
        </>
    )
}

export default ViewBuilding
