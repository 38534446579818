import React, {useEffect, useState} from 'react'
import { Icon, Button } from 'semantic-ui-react';
import './cmp-pdf-viewer.css'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export default function CMP_PDF_VIEWER({ set_mdl_open, var_modaltitle, pdf_url, set_pdf_url }) {

    const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
    const [var_opacity, set_opacity] = useState('0');

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        
	}, [])
    
    
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{var_modaltitle}</div>
                <div className="modal-header-close" onClick={() => {
                    set_mdl_open(false)
                    set_pdf_url(null)
                    }}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                {/* <Document
                    file={process.env.PUBLIC_URL + "/sample_service_inspection_report.pdf"}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document> */}
                {/* <div style={{position: 'absolute', top: '0', right: '0', bottom: '0', left: '0', zIndex: '11' }}>
                    
                </div> */}
                    {var_opacity === '0' && <div style={{display:'contents'}} class="ui segment">
                        <div class="ui indeterminate text loader">Preparing Files</div>
                    </div>}
                <iframe
                    className='ifram-pdf' 
                    onLoad={() => set_opacity('1')}
                    frameborder="0"
                    src={`${pdf_url}#view=fitH;headers=filename%3D`}
                    style={{opacity:var_opacity}}
                    name="U"
                    // sx={{height: '100%', width: '100%', border: 'none', position: 'relative', zIndex: '1001', opacity: var_opacity}}
                />
            </div>
        </>
    )
}

