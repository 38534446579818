import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Table, Button, Label, Icon, Modal } from "semantic-ui-react";
import { portalDocuments } from "../../../../constants";
import { AppContext } from "../../../../Context/AppContext";
import MDL_UPLOAD_DOCUMENT from "../mdl-upload-documents/mdl-upload-documents";
import CMP_PDF_VIEWER from "../../../../components/cmp-pdf-viewer/cmp-pdf-viewer";
import MDL_CONFIRMATION from "../../../../components/cmp-confirmation/cmp-confirmation";
import { useToasts } from "react-toast-notifications";
import "./view-folder-documents.css";

const EXCEL_DOCUMENT_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

function FolderDocuments({}) {
  /* VARIABLE DECLARATIONS
	=================================================================================*/
  const { userInfo } = useContext(AppContext);
  const [user] = userInfo;

  const [var_documents, set_documents] = useState([]);
  const [var_folder, set_folder] = useState({});

  const [var_mdl_upload_document_open, set_mdl_upload_document_open] =
    useState(false);
  const [open_pdf_viewer, set_open_pdf_viewer] = useState(false);
  const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);

  const [pdf_url, set_pdf_url] = useState();
  const [selected_document, set_selected_document] = useState();
  const [deleteIndex, setDeleteIndex] = useState();

  const { addToast } = useToasts();
  const history = useHistory();
  const params = useParams();

  /* EVENT LISTENERS
	=================================================================================*/
  useEffect(() => {
    set_selected_document(null)
    getFolderDetail();
    getDocumentList();
  }, []);

  useEffect(() => {
    if (pdf_url) {
      if (
        selected_document &&
        selected_document.mimeType === EXCEL_DOCUMENT_TYPE
      ) {
        selected_document && downloadDocument();
      } else {
        set_open_pdf_viewer(true);
      }
    }
  }, [pdf_url]);

  /* Async functions
	---------------------------------------------------------------------------------*/
  const getFolderDetail = async () => {
    await fetch(`${portalDocuments}/folder/${params.folderID}`)
      .then((response) => response.json())
      .then((res) => {
        const { data } = res;
        set_folder(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getDocumentList = async () => {
    await fetch(`${portalDocuments}/folder-documents/${params.folderID}`)
      .then((response) => response.json())
      .then((res) => {
        const { data } = res;
        set_documents(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  async function delete_document() {
    var document = var_documents[deleteIndex];
    if (document) {
      const { uuid } = document;

      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + user.token,
        },
        method: "DELETE",
      };
      await fetch(`${portalDocuments}/${uuid}`, requestOptions)
        .then((res) => res.json())
        .then((response) => {
          const { failed, success } = response;
          if (failed) {
            addToast(failed, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          if (success) {
            getDocumentList();
            addToast(success, {
              appearance: "success",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  }


    async function downloadDocument() {
      const url = `${pdf_url}?download=true`;
      console.log("Url", url);
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = `${selected_document.name}`; // Set the desired filename
        downloadLink.click();

      } catch (error) {
        console.error("Error:", error);
      }
  }


  /* EVENT FUNCTIONS
	=================================================================================*/
  function onOpen_mdl_create_folder() {
    set_mdl_upload_document_open(true);
  }

  function onOpen_mdl_confirmation(pos) {
    set_mdl_confirmation(true);
    setDeleteIndex(pos);
  }

  const goToFolderDocumentsView = (folder) => {
    history.push({
      pathname: `/portal-documents/${folder.uuid}`,
      state: { data: folder },
    });
  };

  /* RENDER APP
	=================================================================================*/
  return (
    <>
      <div className="app-content">
        <div className="app-scheduler-container">
          <div className="app-content-action">
            <div className="app-backto-prev-page">
              <div className="app-goback-button" onClick={history.goBack}>
                <Icon name="arrow left" /> Go Back
              </div>
            </div>
            <div className="app-content-action-right">
              <Button color="red" onClick={() => onOpen_mdl_create_folder()}>
                <img src="/upload-file.png" alt="icon" />
                Upload Document
              </Button>
            </div>
          </div>

          <div className="app-card">
            <div className="app-card-header">
              <div className="app-card-title">
                {`${var_folder.title || ""} `}({var_documents.length})
              </div>
            </div>

            <div className="app-table-wrapper">
              <Table unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Document Name</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Actions
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {var_documents.map((document, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <span>
                          <img
                           width="28"
                           height="25"
                            src={
                              document.mimeType === EXCEL_DOCUMENT_TYPE
                                ? "/excel.png"
                                : "/pdf.png"
                            }
                            alt="document"
                          />
                          <span> {document.name}</span>
                        </span>
                      </Table.Cell>
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        <Label
                          color="blue"
                          onClick={() => {
                            set_selected_document(document);
                            set_pdf_url(`${portalDocuments}/${document.uuid}`);
                          }}
                        >{
                          document.mimeType === EXCEL_DOCUMENT_TYPE ? "Download" : "View"
                        }
                        </Label>
                        <Label
                          color="red"
                          onClick={() => onOpen_mdl_confirmation(i)}
                        >
                          Delete
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {var_documents.length === 0 && (
                <div className="nodata-placeholder">
                  There is no documents to display.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/***** MODAL: CREATE FOLDER **************************************************************************/}
      <Modal
        id="mdl-add-scheduler"
        dimmer={"inverted"}
        open={var_mdl_upload_document_open}
        onClose={() => set_mdl_upload_document_open(false)}
      >
        <MDL_UPLOAD_DOCUMENT
          set_mdl_open={set_mdl_upload_document_open}
          update_folder_list={getDocumentList}
          folder_id={params.folderID}
        ></MDL_UPLOAD_DOCUMENT>
      </Modal>

      {/***** END MODAL: CREATE FOLDER **************************************************************************/}

      {/***** MODAL: PDF  VIEWER **************************************************************************/}
      <Modal
        id="mdl-pdf-viewer"
        dimmer={"inverted"}
        open={open_pdf_viewer}
        onClose={() => {
          set_open_pdf_viewer(false);
          set_pdf_url(null);
        }}
      >
        <CMP_PDF_VIEWER
          set_mdl_open={set_open_pdf_viewer}
          pdf_url={pdf_url}
          set_pdf_url={set_pdf_url}
          var_modaltitle={selected_document?.name}
        />
      </Modal>
      {/***** MODAL: PDF  VIEWER **************************************************************************/}
      <Modal
        id="mdl-confirmation"
        dimmer={"inverted"}
        open={var_mdl_confirmation}
        onClose={() => set_mdl_confirmation(false)}
      >
        <MDL_CONFIRMATION
          set_mdl_open={set_mdl_confirmation}
          var_modaltitle="Delete Document"
          var_message="Are you sure want to delete this document?"
          confirmation_function={delete_document}
        ></MDL_CONFIRMATION>
      </Modal>

      {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}
    </>
  );
}

export default FolderDocuments;
