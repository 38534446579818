import React, {useEffect} from 'react'
import { Icon, Button } from 'semantic-ui-react';
import './cmp-confirmation.css'

export default function CMP_CONFIRMATION({ set_mdl_open, var_modaltitle, var_message, confirmation_function }) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        
	}, [])
    
    
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    function onClick_yes(){
        confirmation_function();
        set_mdl_open(false);
    }
    
    

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{var_modaltitle}</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                <p className="confirmation_message">{var_message}</p>
            </div>

            <div className="modal-footer">
                <Button type="button" color="grey" onClick={()=> set_mdl_open(false)}>
                    No
                </Button>
                <Button type="button" color="red" onClick={() => onClick_yes()}>
                    Yes
                </Button>
            </div>
        </>
    )
}

