import React, { useEffect} from 'react'
import { Loader } from 'semantic-ui-react';
import './cmp-progress.css'

export default function CMP_PROGRESS({ set_mdl_open, var_modaltitle, var_message, success_function }) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        
	}, [])
    
    
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    
    

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="modal-content mdl-loader">
                <div className="modal-success-title">
                    {var_modaltitle}
                </div>
                <div className="modal-success-message">{var_message}</div>
                <Loader className="mdl-loader" active />
            </div>
        </>
    )
}

