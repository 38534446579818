import React, {useState, useEffect, useContext} from 'react'
import { Icon, Button, Dropdown } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from "moment";
import './WarrantyDetails.css'
import { useToasts } from "react-toast-notifications";
import { AppContext } from "../../../../Context/AppContext";
import {getPestLookup,getTreatmentDetailLookup,postWarranty } from '../../../../constants';

export default function MDL_WARRANTY_DETAILS({building_id,set_mdl_open,units_array}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const {userInfo } = useContext(AppContext);
    const [user, ] = userInfo;
    const [building_units,setBuildingUnits] = useState([]);
    const [ pestData, setpestData ] = useState([]);
    const [treatmentData, setTreatmentData] = useState([]);
    const [SelectedpestData, setSelectedpestData] = useState([]);
    const [SelectedtreatmentData, setSelectedtreatmentData] = useState([]);
    const [currentDate, setNewDate] = useState(null);
    const [error_pest_message, set_error_pest_message] = useState("");
    const [error_service_message, set_error_service_message] = useState("");
    const [errors, set_errors] = useState({});
    const { addToast } = useToasts();
    

    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        pestLookup()
        treatmentLookup()
	}, [])
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const postWarrantyData = async (e) => {
        // set_mdl_progress(true);
         var units_arr = [];
         var pest_arr = [];
         var service_arr = [];
         building_units?.map(unit => {
           units_arr.push(unit?.value)
         })
         SelectedpestData?.map(pest => {
          pest_arr.push(pest?.value)
         })
         SelectedtreatmentData?.map(service => {
          service_arr.push(service?.value)
         })
         const requestWarranty = {
           method: "POST",
           headers: {
             "Content-Type": "application/json",
             Authorization: "Token " + user.token,
           },
           body: JSON.stringify({
             building_id: building_id,
             building_units: units_arr,
             warranty_pest:pest_arr,
             warranty_service: service_arr,
             warranty_date:convertDateFormat(currentDate).replace('Z',''),
           }),
         };
        // convertDateFormat(currentDate).replace('Z','')
         await fetch(postWarranty, requestWarranty)
           .then((response) => response.json())
           .then((res) => {
             if (res.code == 200) {
               addToast(res.message, {
                 appearance: "success",
                 autoDismiss: true,
               });
               set_mdl_open(false);
             }else {
               addToast(res.success, {
                 appearance: "error",
                 autoDismiss: true,
               });
             }
           }).catch((error) => {
             console.log("Errors:", error);
           });
       };
    async function pestLookup(){
        await fetch(getPestLookup)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200){
                setpestData(res?.data);
            }
            else{
                setpestData(null)
            }
        })
        .catch(error=>{
            setpestData(null);
            console.error("Error:",error);
        })
    }

    async function treatmentLookup(){
        await fetch(getTreatmentDetailLookup)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200){
              const formattedServices = res.data.map(service => ({
                value: service.value,
                text: `${service.text} (${service.pest_name} ${service.treatment_name})`
              }));
                setTreatmentData(formattedServices);
            }
            else{
                setTreatmentData(null)
            }
        })
        .catch(error=>{
            setTreatmentData(null);
            console.error("Error:",error);
        })
    }
    const convertDateFormat = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString();
    };

    function isValidated() {
        var temp_error = { ...errors };
        var isValid = true;
        if (!currentDate) {
          temp_error.currentDate = "Please select date.";
          isValid = false;
        }
        if (building_units.length == 0 && building_units.length == 0) {
          temp_error.buildingunits = "Please select units.";
          isValid = false;
        }
        if (SelectedpestData.length == 0 &&  SelectedtreatmentData.length == 0) {
            //temp_error.warrantypest = "Please select one option .";
            set_error_pest_message("Please select one option from pest or treatment.")
            isValid = false;
        }else if(SelectedpestData.length > 4){
          temp_error.warrantypest = "Please select only four pest.";
          set_error_pest_message("Please select only four pest.")
          isValid = false;
        }

        if(SelectedtreatmentData){
          if(SelectedtreatmentData.length > 4){
            temp_error.warrantyservice = "Please select only four service.";
            set_error_service_message("Please select only four service.")
            isValid = false;
          }

        }
    
        if (isValid) {
          set_errors({});
        } else {
          set_errors(temp_error);
        }
        return isValid;
      }

    function onChangeUnits(event, { value }) {
        var temp_error = { ...errors };
        temp_error.buildingunits = null;
        set_errors(temp_error);
        const filteredArray = units_array.filter(unit => value.includes(unit.value));
        setBuildingUnits(filteredArray);
    }

    function onChangePest(event, { value }) {
        var temp_error = { ...errors };
        temp_error.warrantypest = null;
        set_errors(temp_error);
        set_error_pest_message("")
        const filteredPest = pestData.filter((f_v) => value.includes(f_v.value));
        setSelectedpestData(filteredPest);
    }
    function onChangeTreatment(event, { value }) {
        set_error_service_message("")
        const filteredTreatment = treatmentData.filter((f_v) => value.includes(f_v.value));
        setSelectedtreatmentData(filteredTreatment);
    }
    
    function handleAddWarrantyDetails(){
        if(isValidated()){
            postWarrantyData()
        }
    }

    const onChange = (event, data) => {
    if (data?.value) {
        var temp_error = { ...errors };
        temp_error.currentDate = null;
        set_errors(temp_error);
        setNewDate(moment(data?.value).format("YYYY-MM-DD"));
    }
    };

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">Add Warranty Details</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content  fields">
                 <Dropdown
                    search
                    style={{width:'100%'}}
                    selection
                    className="sortby_options"
                    id="sortby_options"
                    name="sortby_options"
                    placeholder="Select Units"
                    label="Units: "
                    onChange={onChangeUnits}
                    value={setBuildingUnits?.value}
                    error={errors.buildingunits}
                    options={units_array}
                    multiple={true}
                    /> 
                    <SemanticDatepicker
                        style={{width:'100%'}}
                        id="date"
                        name="date"
                        placeholder={'Expiration Date'}
                        required={true}
                        fluid
                        error={errors?.currentDate}
                        onChange={onChange}
                        />
                     {pestData?.length > 0 && (
                    <Dropdown
                        search
                        style={{width:'100%'}}
                        selection
                        className="pest_options"
                        id="pest_options"
                        name="pest_options"
                        placeholder="Select Pest"
                        label="Pests: "
                        onChange={onChangePest}
                        error={errors.warrantypest}
                        value={SelectedpestData?.value}
                        options={pestData}
                        multiple={true}
                    />
                    )}
                    {error_pest_message !== '' &&
                    <div>
                      <div className="ui error message">
                        {error_pest_message}
                      </div>
                    </div>
                    }
                     {treatmentData?.length > 0 && (
                    <Dropdown
                    search
                    style={{ width: '100%' }}
                    selection
                    className="treatment_options"
                    id="treatment_options"
                    name="treatment_options"
                    placeholder="Select Treatment"
                    label="Treatments: "
                    onChange={onChangeTreatment}
                    value={SelectedtreatmentData?.value}
                    options={treatmentData}
                    multiple
                  />
                    )}
                    {error_service_message !== '' &&
                    <div>
                      <div className="ui error message">
                        {error_service_message}
                      </div>
                    </div>
                    }
                
            </div>
            <div className="modal-footer">
                <Button type="submit" color="red" onClick={handleAddWarrantyDetails}>
                 Add
                </Button>
            </div>
        </>
    )
}

