import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Statistics.css'

function Statistics({title, count, icon, progress, prevCount, thisMonth,clickEvent}) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
    return (
        <div className="dashboard-stats" onClick={clickEvent}>
            <div className="dashboard-stats-holder">
                <div className="stats-content">
                    <div className="stats-icon">
                        <img src={icon} alt={title} />
                    </div>
                    <div className="stats-detail">
                        <div className="stats-title">{title}</div>
                        <div className="stats-count">{count}</div>
                    </div>
                </div>
                <div className="stats-progress">
                    <CircularProgressbar
                        value={progress}
                        text={`${progress}%`}
                    />
                </div>
            </div>
            <div className="dashboard-stats-footer">
                <div className="progress-help-text">
                Total {title}: {count}
                </div>
                <div className="progress-help-text">
                Previous Month: {prevCount} 
                </div>
                <div className="progress-help-text">
                {monthNames[new Date().getMonth()]}: {thisMonth} 
                </div>
                
            </div>
        </div>
    )
}

export default Statistics
