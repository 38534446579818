import React, {useState, useEffect} from 'react'
import { Icon, Button, Form, Input, Checkbox, Message } from 'semantic-ui-react';
import { getAllPests, getAllPestsTreatment } from '../../../../constants';
import dateFormat from 'dateformat';
import './mdl-add-unit.css'

export default function MDL_ADD_UNIT({set_mdl_open,add_single_unit}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const [ var_add_unit, set_add_unit ] = useState({})
    const [ var_warranty, set_warranty ] = useState(false);
    const [ var_warranty_type, set_warranty_type ] = useState('pest')
    const [ pestTreated, setPestTreated] = useState();
    const [ treatmentOption, setTreatmentOptions] = useState();
    const [selected_pest,setSelectedPest ]= useState();
    const[form_validation_error,setFormError]=useState({});

    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
		getPests();
	},[])

    useEffect(()=>{
        getPestTreatments();
    },[selected_pest])

    async function getPestTreatments(){
        await fetch(getAllPestsTreatment+selected_pest)
        .then(response=>response.json())
        .then(res=>{
            var allTempPest=[];
            if(res.code===200){
                res.data.map((value,i)=>{
                    allTempPest=[...allTempPest,{key:value.treatment_name,
                    text:value.treatment_name,
                    value:value.treatment_id}]
                });
                setTreatmentOptions(allTempPest);
            }
            else{

            }
        })
    }
    async function getPests(){
        await fetch(getAllPests)
        .then(response=>response.json())
        .then(res=>{
            var allTempPest=[];
            if(res.code===200){
                res.data.map((value,i)=>{
                    allTempPest=[...allTempPest,{key:value.pestName,
                    text:value.pestName,
                    value:value.pestId}]
                });
                setPestTreated(allTempPest);
            }
            else{

            }
        })
    }

    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value
        if(name==="expiry_date"){
            const current_date = new Date().toISOString().split("T")
            const warranty_date=value;
            if(current_date[0] > warranty_date || dateFormat(warranty_date,"yyyy").length > 4 ){
                form_validation_error.expiry_date="Invalid Expiry date."
            }else{
                form_validation_error.expiry_date=null
            }
        }
        if(name==="unit"){
            
            if(value<0){
                set_add_unit({...var_add_unit, [name]: ''});
            }
            else{
                set_add_unit({...var_add_unit, [name]: value.trim()});  
            }
        }
        else
        set_add_unit({...var_add_unit, [name]: value});
        form_validation_error.error=null
    }
    const add_unit=()=>{
        if(var_add_unit){
            const{unit,bedroom_count,town_house,is_warranty,pest,treatment,expiry_date}=var_add_unit;
            if(is_warranty===1){
                if(validated()){
                    add_single_unit(unit,bedroom_count,town_house?town_house:0,is_warranty?is_warranty:0,var_warranty_type,pest,treatment,expiry_date);
                    set_mdl_open(false); 
                }else{
                    form_validation_error.error="Please fill all required fields."
                }
            }else{
            add_single_unit(unit,bedroom_count,town_house);
            set_mdl_open(false);
            }
            
        }
    }
    function validated(){
        var return_value=true;
        var error_validation={...form_validation_error};
        if(var_add_unit.is_warranty === 1){
            if(var_warranty_type === "pest"){
                if(!var_add_unit.pest_id){
                    error_validation.pest="Please select pest."
                    setFormError(error_validation)
                    return_value = false;
                }
            }
            if(var_warranty_type=== "service"){
                
                if(!var_add_unit.pest_id){
                    error_validation.pest="Please select pest."
                    setFormError(error_validation)
                    return_value = false;
                }
                else if(!var_add_unit.treatment_id){
                    error_validation.treatment="Please select treatment."
                    setFormError(error_validation);
                    return_value = false;
                }
            }
            if(form_validation_error.expiry_date){
                error_validation.expiry_date="Please select valid date."
                setFormError(error_validation);
                return_value = false;
            }
        }
        return return_value;
    }
    const onChangeWarranty = (e, {checked}) => {
        if(checked)
        {
            set_add_unit({...var_add_unit, is_warranty: 1});
            set_warranty(true)

        }
        else{
            set_add_unit({...var_add_unit, is_warranty: 0});
            set_warranty(false)
        }
    }

    const onChangeWarrantyType = (e, {value}) => {
        set_warranty_type(value)
        set_add_unit({...var_add_unit, warranty_type: value});
    }

    const OnChangePest = (e,{value})=>{
        if(e.target.id===""){
            var error_validation={...form_validation_error};
            var temp={...var_add_unit};
                temp.pest_id=value;
                temp.pest=pestTreated.filter(val=>val.value===value)[0]?.text
                temp.treatment_id= null;
                temp.treatment=null
                set_add_unit(temp);
                setSelectedPest(value)
                error_validation.pest=null;
                setFormError(error_validation);
            }
    }

    const OnChangeTreatment = (e,{value})=>{
        if(e.target.id===""){
            var temp={...var_add_unit};
            var error_validation={...form_validation_error};
                temp.treatment_id= value;
                temp.treatment=treatmentOption.filter(val=>val.value===value)[0]?.text
                set_add_unit(temp);
                error_validation.treatment=null;
                setFormError(error_validation);
        }
       
    }


    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form method="POST" onSubmit={add_unit}>
            <div className="modal-header">
                <div className="modal-header-title">Add Unit</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                
                <Form.Group>
                    <Form.Field
                        id='unit'
                        name='unit'
                        label='Unit #'
                        type='text'
                        value={var_add_unit.unit}
                        required
                        control={Input}
                        onChange={onChangeInput}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='bedroom_count'
                        name='bedroom_count'
                        label='Beds'
                        type="text"
                        value={var_add_unit.bedroom_count}
                        required
                        control={Input}
                        onChange={onChangeInput}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                    <Checkbox checked={ var_add_unit.town_house === 1 } toggle onChange={(e, {checked})=> 
                        {
                            checked?set_add_unit({...var_add_unit, town_house: 1}):set_add_unit({...var_add_unit, town_house: 0})
                        }
                        } 
                        label="Townhouse" />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox checked={ var_add_unit.is_warranty === 1 } toggle label="Warranty" onChange={onChangeWarranty} />
                    </Form.Field>
                </Form.Group>
                {var_warranty &&
                <Form.Group>
                    <Form.Field>
                        <label>Warranty type:</label>
                        <Checkbox
                            radio
                            defaultChecked
                            label='Pest'
                            name='warrantyType'
                            value='pest'
                            checked={var_warranty_type === 'pest'}
                            onChange={onChangeWarrantyType}
                        />
                        <Checkbox
                            radio
                            label='Service code'
                            name='warrantyType'
                            value='service'
                            checked={var_warranty_type === 'service'}
                            onChange={onChangeWarrantyType}
                        />
                    </Form.Field>
                </Form.Group>
                }
                {var_warranty && var_warranty_type === 'pest' &&
                <Form.Group>
                        <Form.Select
                            upward
                            selection
                            label="Pest Requested"
                            selectOnBlur={false}
                            placeholder='Select pest'
                            options={pestTreated}
                            value={var_add_unit.pest_id}
                            error={form_validation_error.pest}
                            onChange={OnChangePest}
                            onBlur={OnChangePest}
                            required={var_add_unit.is_on_pest===1}
                        />
                </Form.Group>
                }
                {var_warranty && var_warranty_type === 'service' &&
                <Form.Group>
                        <Form.Select
                            upward
                            selection
                            selectOnBlur={false}
                            label="Pest Requested"
                            placeholder='Select pest'
                            options={pestTreated}
                            value={var_add_unit.pest_id}
                            error={form_validation_error.pest}
                            onChange={OnChangePest}
                            onBlur={OnChangePest}
                            required={var_add_unit.is_on_service===1}
                        />
                        <Form.Select
                            upward
                            selection
                            selectOnBlur={false}
                            label="Treatment Requested"
                            placeholder='Select treatment'
                            options={treatmentOption}
                            error={form_validation_error.treatment}
                            value={var_add_unit.treatment_id}
                            onChange={OnChangeTreatment}
                            onBlur={OnChangeTreatment}
                            required={var_add_unit.is_on_service===1}
                        />
                </Form.Group>
                }
                {var_warranty &&
                <Form.Group>
                    <Form.Field
                        id='expiry_date'
                        name='expiry_date'
                        label='Expiry date'
                        type='date'
                        required
                        control={Input}
                        onChange={onChangeInput}
                        error={form_validation_error.expiry_date}
                    />  
                </Form.Group>
                }
            </div>
            <Message
                error
                header='Error'
                content={"Please fill all required (*) fields."}
            />
            <div className="modal-footer">
                <Button type="submit" color="red">
                    Submit
                </Button>
            </div>
        </Form>
        </>
    )
}

